import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ME } from "src/lib/queries";
import { EXIT_ONE_WORKSPACE } from "src/lib/mutations";

export function useExitOneWorkspace() {
    const [accessToken] = useLocalStorage("accessToken", undefined);

    const options = accessToken ? {
        context: {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    } : {}

    return useMutation(EXIT_ONE_WORKSPACE, { ...options, refetchQueries: [{ query: ME, ...options }], });
}
