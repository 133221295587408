import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import {UPDATE_ONE_WORKSPACE_INVITATION} from "src/lib/mutations";

export function useUpdateOneWorkspaceInvitation() {
    const [accessToken] = useLocalStorage("accessToken", undefined);

    const options = accessToken ? {
        context: {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    } : {}

    return useMutation(UPDATE_ONE_WORKSPACE_INVITATION, { ...options });
}
