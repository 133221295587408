import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import {DEACTIVATE_ONE_WORKSPACE_MEMBER} from "src/lib/mutations";

export function useDeactivateOneWorkspaceMember() {
    const [accessToken] = useLocalStorage("accessToken", undefined);

    const options = accessToken ? {
        context: {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    } : {}

    return useMutation(DEACTIVATE_ONE_WORKSPACE_MEMBER, { ...options });
}
