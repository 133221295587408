export default function SystemMode() {
    return (
        <svg width="145" height="106" viewBox="0 0 145 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.391892" y="0.391892" width="144.216" height="102.216" rx="5.87838" fill="#F9F9F9" stroke="#E0E0E0" strokeWidth="0.783784" />
            <g filter="url(#filter0_d_5410_106066)">
                <rect x="33.1431" y="4.12" width="71.4643" height="26.78" rx="3.13514" fill="white" />
            </g>
            <g filter="url(#filter1_d_5410_106066)">
                <rect x="33.1431" y="35.02" width="71.4643" height="63.86" rx="3.13514" fill="white" />
            </g>
            <g filter="url(#filter2_d_5410_106066)">
                <rect x="108.75" y="4.12" width="32.1071" height="94.76" rx="3.13514" fill="url(#paint0_radial_5410_106066)" />
            </g>
            <rect x="14.5" y="8.23999" width="14.5" height="3.09" rx="1.545" fill="#E6E6E6" />
            <rect x="14.5" y="13.39" width="9.32143" height="3.09" rx="1.545" fill="#E6E6E6" />
            <rect x="10.3569" y="24.72" width="18.6429" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="10.3569" y="30.9" width="18.6429" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="4.14307" y="24.72" width="4.14286" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="4.14307" y="30.9" width="4.14286" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="4.14307" y="43.26" width="21.75" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="4.14307" y="49.44" width="21.75" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="4.14307" y="55.62" width="21.75" height="4.12" rx="2.06" fill="#E6E6E6" />
            <rect x="4.14307" y="8.23999" width="8.28571" height="8.24" rx="1.56757" fill="#E6E6E6" />
            <g clipPath="url(#clip0_5410_106066)">
                <rect x="0.411932" y="0.411932" width="144.176" height="102.748" rx="6.17898" fill="#090909" stroke="#646464" strokeWidth="0.823864" />
                <rect x="33.1431" y="4.14288" width="71.4643" height="26.9286" rx="3.29545" fill="#2F2F2F" />
                <rect x="33.1431" y="35.2143" width="71.4643" height="64.2143" rx="3.29545" fill="#2F2F2F" />
                <rect x="108.75" y="4.14288" width="32.1071" height="95.2857" rx="3.29545" fill="#2F2F2F" />
            </g>
            <defs>
                <filter id="filter0_d_5410_106066" x="30.0079" y="4.12" width="77.7346" height="33.0503" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.13514" />
                    <feGaussianBlur stdDeviation="1.56757" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5410_106066" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5410_106066" result="shape" />
                </filter>
                <filter id="filter1_d_5410_106066" x="30.0079" y="35.02" width="77.7346" height="70.1303" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.13514" />
                    <feGaussianBlur stdDeviation="1.56757" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5410_106066" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5410_106066" result="shape" />
                </filter>
                <filter id="filter2_d_5410_106066" x="105.615" y="4.12" width="38.3772" height="101.03" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.13514" />
                    <feGaussianBlur stdDeviation="1.56757" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5410_106066" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5410_106066" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_5410_106066" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(124.286 46.35) rotate(90) scale(43.26 15.2085)">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" />
                </radialGradient>
                <clipPath id="clip0_5410_106066">
                    <rect width="75" height="104" fill="white" transform="translate(70)" />
                </clipPath>
            </defs>
        </svg>

    )
}