import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import Button from 'src/components/UI/Button/Button';
import '../settings.css'
import HeaderText from "src/components/UI/HeaderTexts";
import HorizontalDivider from "src/components/UI/HorizontalDivider";
import Input from 'src/components/UI/Inputs/Default';
import Logo from 'src/components/UI/Logo';
import Modal from 'src/components/UI/Modals/Modal';
import CheckBox from 'src/components/UI/CheckBox';
import {useMe} from "src/api/auth/useMe";
import UrlInput from 'src/components/UrlInput';
import {useDebounce, useLocalStorage} from "@uidotdev/usehooks";
import usePrevious from 'src/hooks/usePrevious';
import {useLazyWorkspace, useWorkspace} from 'src/api/workspace/useWorkspace';
import ValidationErrorMessages from 'src/components/ValidationErrorMessages';
import Validator from "validatorjs";
import {useNavigate, useParams} from "react-router-dom";
import {useUpdateOneWorkspace} from "../../../api/workspace/useUpdateOneWorkspace";
import {useDeleteOneWorkspace} from "../../../api/workspace/useDeleteOneWorkspace";
import {useVerifyDeleteOneWorkspace} from "../../../api/workspace/useVerifyDeleteOneWorkspace";
import toast from 'react-hot-toast';
import ToastMessage from 'src/components/ToastMessage';

type FormData = {
  logo?: File
  name: string
  slug: string
  website: string
}

export default function General() {
  const navigate = useNavigate();

  // GraphQL definition
  const {loading: loadingMe, data} = useMe();
  const {workspaceSlug} = useParams<{ projectId: string; workspaceSlug: string }>();
  const prevWorkspaceSlug = usePrevious<string | undefined>(workspaceSlug);
  const {loading: workspaceLoading, data: workspaceData} = useWorkspace({
    variables: {
      where: {slug: workspaceSlug}
    }
  });

  const [updateOneWorkspace, {
    loading: updateOneWorkspaceLoading,
    error: updateOneWorkspaceError,
  }] = useUpdateOneWorkspace();
  const [deleteOneWorkspace, {
    loading: deleteOneWorkspaceLoading,
    error: deleteOneWorkspaceError,
  }] = useDeleteOneWorkspace();
  const [verifyDeleteOneWorkspace, {
    loading: verifyDeleteOneWorkspaceLoading,
    error: verifyDeleteOneWorkspaceError,
  }] = useVerifyDeleteOneWorkspace();
  const errorMessage = updateOneWorkspaceError && `Submission error! ${updateOneWorkspaceError.message}`;

  // state definition
  const [verifyToken, setVerifyToken] = useState('');

  // modal states
  const [deleteModal, showDeleteModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteConfirmationModal, showDeleteConfirmationModal] = useState(false);

  // formData
  const [formData, setFormData] = useState<FormData>({
    name: workspaceData?.workspace.name,
    slug: workspaceData?.workspace.slug,
    website: workspaceData?.workspace.website,
  });
  const debouncedFormData = useDebounce(formData, 300);
  const prevFormData = usePrevious<FormData>(formData);
  const [formErrors, setFormErrors] = useState<Validator.ValidationErrors | null>(null);

  useEffect(() => {
    if (!workspaceLoading && workspaceData) {
      const workspace = workspaceData.workspace;
      setFormData({
        name: workspace.name,
        slug: workspace.slug,
        website: workspace.website
      })
    }
  }, [workspaceData, workspaceLoading]);

  const [existingWorkspace, {
    data: existingWorkspaceData,
    loading: existingWorkspaceLoading,
    error: existingWorkspaceError,
  }] = useLazyWorkspace();

  useEffect(() => {
    if (debouncedFormData.slug && debouncedFormData.slug !== workspaceSlug) {
      existingWorkspace({
        variables: {where: {slug: debouncedFormData.slug}},
        fetchPolicy: 'network-only'
      });
    }
  }, [debouncedFormData.slug, existingWorkspace, prevWorkspaceSlug, workspaceSlug]);

  useEffect(() => {
    if (formData.name !== prevFormData?.name) {
      setFormData({
        ...formData,
        slug: formData.name.replaceAll(' ', '-').toLowerCase()
      });
    }

    if (formData.slug !== prevFormData?.slug) {
      setFormData({
        ...formData,
        slug: formData.slug.replaceAll(' ', '-').toLowerCase()
      });
    }
  }, [formData, prevFormData]);

  if (workspaceLoading || loadingMe) return <div className="dark:text-white">Loading general page...</div>;

  const handleInputChange = (field: string) =>
    (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({...formData, [field]: value});
      setFormErrors(null);
    }

  const handleSubmit = async () => {
    const validation = new Validator(formData, {
      name: 'required',
      slug: 'required',
      website: 'required|url',
    });

    if (validation.passes()) {
      setFormErrors(null);
      updateOneWorkspace({
        variables: {
          data: {
            name: {set: formData.name},
            slug: {set: formData.slug},
            website: {set: formData.website},
          },
          where: {
            slug: workspaceSlug
          }
        },
        onCompleted: ({updateOneWorkspace}) => {
          toast(t => (
            <ToastMessage
              id={t.id}
              // icon={<TrashDeleteBin size={16} weight={3} />}
              title={`Workspace updated succesfully`}
              // buttonText={type === 'archive' ? 'Undo' : 'Restore'}
              handleUndo={() => {
                toast.dismiss(t.id);
              }}
            >
              {/* You can go to the “project” part of workspace settings to access this project */}
            </ToastMessage>
          ));
          navigate(`/workspace/${updateOneWorkspace.slug}/settings/general`, {replace: true});
        }
      })
    } else {
      setFormErrors(validation.errors.all());
    }
  }

  return (
    <div className="workspace-page flex flex-col gap-10">
      <div className="header_div">
        <HeaderText title="General"/>
        <HorizontalDivider/>
      </div>
      <div className="flex flex-col gap-3 items-start">
        <HeaderText subTitle="Logo"/>
        <Logo logo={workspaceData?.workspace.logo} userId={workspaceData?.workspace.id} name={workspaceData.workspace ? workspaceData.workspace.name : ''} size={40} fontSize={24}/>
        <p className='general-logo-p text-body-micro'>Upload your workspace logo, you can always change or add it
          later.</p>
      </div>
      <HorizontalDivider/>
      <div className="profile_details flex flex-col gap-6 items-start w-full">
        <HeaderText subTitle="Workspace details" snippet="Update your workspace name and URL."/>
        <div className="w-full space-y-2">
          <label className='text-body-small regular text-[var(--text-default)]' htmlFor="workspace-name">
            Workspace name
          </label>
          <input
            id="workspace-name"
            placeholder="Example Inc."
            className="w-full input"
            value={formData.name}
            onChange={handleInputChange('name')}
          />
          <ValidationErrorMessages name='name' errors={formErrors}/>
        </div>

        <div className="w-full space-y-2">
          <label className='text-body-small regular text-[var(--text-default)]' htmlFor="workspace-url">Workspace
            URL</label>
          <UrlInput
            id="workspace-url"
            inputTypeW_url
            value={formData.slug}
            onChange={handleInputChange('slug')}
            domain="overlap.work"
            formatted={false}
            loading={existingWorkspaceLoading}
            success={Boolean(existingWorkspaceError)}
            error={Boolean(existingWorkspaceData)}
          />
          <ValidationErrorMessages name='slug' errors={formErrors}/>
        </div>

        <div className="w-full space-y-2">
          <label className='text-body-small regular text-[var(--text-default)]'
                 htmlFor="workspace-website">Website</label>
          <UrlInput
            id="workspace-website"
            value={formData.website}
            onChange={handleInputChange('website')}
            placeholder="www.example.com"
          />
          <ValidationErrorMessages name='website' errors={formErrors}/>
        </div>

        {errorMessage && (
          <p className="text-xs text-red-500 text-center">
            {errorMessage}
          </p>
        )}

        <div className="self-end">
          <Button
            btnType={'primary'}
            value={'Update'}
            size={'medium'}
            className={'w-auto'}
            onClick={handleSubmit}
            disabled={updateOneWorkspaceLoading}
          />
        </div>
      </div>
      <HorizontalDivider/>
      <div className="flex flex-col items-start gap-6 w-full">
        <HeaderText subTitle="Danger zone" snippet="You cannot undo this action if you continue."/>
        <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
          <div className="flex flex-col items-start gap-0.5">
            <p className="title text-body-large medium">Delete this workspace</p>
            <p className="snippet text-body-small medium">Please be very sure about this action before you proceed.</p>
          </div>
          <Button
            btnType={'destructive'}
            value={'Delete workspace'}
            size={'medium'}
            className={'w-auto'}
            onClick={() => showDeleteModal(true)}
          />
        </div>
      </div>

      {/* Delete Modal */}
      <Modal
        open={deleteModal}
        data={{
          subtitle: 'Delete this workspace?',
          snippet: 'Before proceeding, please note that deleting your workspace is irreversible and will permanently remove all data associated with it. <br /><br /> This action cannot be undone, all files, documents, and information stored within the workspace will be permanently deleted. <br /><br /> This includes any content that has not been exported or backed up.',
          btn1: 'Cancel',
          btn2: 'Delete workspace',
          btn1Type: 'neutral',
          btn2Type: 'destructive'
        }}
        onBtn1Click={() => {
          showDeleteModal(false)
          setDeleteConfirm(false)
        }}
        onBtn2Click={() => {
          deleteOneWorkspace({
            variables: {
              where: { slug: workspaceSlug }
            },
            onCompleted: () => {
              showDeleteModal(false)
              setDeleteConfirm(false)
              showDeleteConfirmationModal(true)
            }
          })
        }}
        btn2Disabled={!deleteConfirm || deleteOneWorkspaceLoading}
      >
        <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
          <div className="flex items-start gap-2">
            <div className=" py-[3px]">
              <CheckBox
                type='default'
                isChecked={deleteConfirm}
                onCheckboxChange={() => setDeleteConfirm(!deleteConfirm)}
              />
            </div>
            <p className="snippet text-body-small medium">
              By confirming your decision, you acknowledge and accept the risks involved.
            </p>
          </div>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteConfirmationModal}
        data={{
          subtitle: 'Verify this action',
          snippet: `Enter the OTP we just sent to <span>${data?.me?.email}</span> to confirm this action.`,
          btn1: 'Cancel',
          btn2: 'Leave',
          btn1Type: 'neutral',
          btn2Type: 'destructive'
        }}
        onBtn1Click={() => {
          showDeleteConfirmationModal(false)
        }}
        onBtn2Click={() => {
          verifyDeleteOneWorkspace({
            variables: {
              where: { slug: workspaceSlug },
              data: {
                email: data.me.email,
                token: verifyToken,
              }
            },
            onCompleted: () => {
              navigate('/');
            }
          })
        }}
        btn2Disabled={verifyDeleteOneWorkspaceLoading}
      >
        <Input
          type="text"
          value={verifyToken}
          feedback={verifyDeleteOneWorkspaceError ? verifyDeleteOneWorkspaceError.message : ''}
          feedbackType={verifyDeleteOneWorkspaceError ? 'error' : 'success'}
          label="Enter OTP"
          placeholder="Enter your OTP"
          onChange={(e) => setVerifyToken(e.target.value)}
        />
      </Modal>
    </div>
  )
}
