
import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ME } from "src/lib/queries";
import { JOIN_ONE_WORKSPACE } from "src/lib/mutations";

export function useJoinOneWorkspace() {
    const [accessToken] = useLocalStorage("accessToken", undefined);

    const options = accessToken ? {
        context: {
            headers: {
                'Authorization': accessToken ? `Bearer ${accessToken}` : undefined,
            },
        }
    } : {}

    return useMutation(JOIN_ONE_WORKSPACE, { ...options, refetchQueries: [{ query: ME, ...options }], });
}
