import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Inbox, LogoNeutral, MegaPhone } from "src/constants/icons";
import SideNavMenuBtn from "src/components/sideNav/SideNavMenu";
import { useMe } from "src/api/auth/useMe";
import {IInbox, IUserWorkspace} from "src/lib/types";
import EditProject from "../EditProject";
import { transformToTitleCase } from "src/lib/helpers";
import InboxPage from "../Features/Inbox/InboxPage";
import NavigationHeader from "./NavigationHeader";
import Badge from "../UI/Badge";
import {useInbox} from "../../api/inbox/useInbox";

export default function HomeSideNav() {
    // route definitions
    const { workspaceSlug } = useParams();

    // graphQL definitions
    // const { data } = useMe();
    const { data: inboxData } = useInbox();
    // const userWorkspaces = data?.me?.userWorkspaces;
    // const userWorkspace = userWorkspaces?.find(({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug);
    const [createProject, setCreateProject] = useState(false);

    const [showInbox, setShowInbox] = useState<boolean>(false)

    const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

    const unreadInboxCount = inboxData?.inbox.filter((each: IInbox) => !each.isRead).length ?? 0;

    return (
        <>
            {/* <div>
                <SideNavMenuBtn type='home' label={'Back'} icon={<ArrowBack />} />
            </div> */}
            <div className="side_nav_group h-full justify-between">
                <div className="flex flex-col gap-6 w-full">
                    <div className="menu_btn_group">
                        <div className={`w-full rounded-lg ${showInbox && 'bg-[var(--backgrounds-hover-clicked)]'}`} onClick={() => setShowInbox(!showInbox)}>
                            <SideNavMenuBtn
                                type='settings'
                                label={'Inbox'}
                                icon={<Inbox className="w-4 h-4" />}
                                showBadge={unreadInboxCount > 0}
                                badge={unreadInboxCount}
                            />
                        </div>
                        <Link to={`${WORKSPACE_URL_BASE}/feed`}>
                            <SideNavMenuBtn
                                type='settings'
                                label={'Feed'}
                                icon={<MegaPhone className='w-4 h-4'/>}
                                link={`${WORKSPACE_URL_BASE}/feed`}
                            />
                        </Link>
                    </div>
                    <NavigationHeader
                        label='Projects'
                        openInbox={createProject}
                        baseLink={`${WORKSPACE_URL_BASE}`}
                        // projects={userWorkspace?.workspace?.projects || []}
                        secNavFunc={() => setCreateProject(true)}
                    />
                </div>
                <EditProject
                    show={createProject}
                    handleClose={() => setCreateProject(false)}
                />
                <InboxPage showInbox={showInbox} handleClick={() => setShowInbox(false)} />
                <div className="w-fit">
                    <div className="px-3 gap-2 py-0.5 h-8 flex items-center rounded-lg">
                        <div className="">
                            <LogoNeutral />
                        </div>
                        <Badge value={'EARLY ACCESS'} />
                    </div>
                </div>
            </div>
        </>
    );
}
