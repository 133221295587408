import React from 'react';
import Button from './UI/Button/Button';

function SocialAuthButtonGroup({login}:{login?: boolean}) {
  return (
    <div className="grid gap-4 w-full">
      <a href={process.env.REACT_APP_API_URL + '/auth/google'} className="">
        <Button
          btnType='neutral'
          value={login ? 'Continue with Google' : 'Google'}
          icon='left'
          size='large'
        >
          <img src="/images/Google.svg" className="w-6 aspect-square" alt="Google" />
        </Button>
      </a>
      {/* <a href={process.env.REACT_APP_API_URL + '/auth/slack'} className="">
        <Button
          btnType='neutral'
          value={'Slack'}
          icon='left'
          size='large'
        >
          <img src="/images/Slack.svg" className="w-6 aspect-square" alt="Google" />
        </Button>
      </a>
      <a href={process.env.REACT_APP_API_URL + '/auth/microsoft'} className="">
        <Button
          btnType='neutral'
          value={'Microsoft'}
          icon='left'
          size='large'
        >
          <img src="/images/Microsoft.svg" className="w-6 aspect-square" alt="Google" />
        </Button>
      </a> */}
    </div>
  );
}

export default SocialAuthButtonGroup;
