import React from 'react';

function Spinner() {
  return (
    <div className="absolute inset-0 grid place-content-center bg-inherit !m-0">
      <img src="/images/CircleNotch.svg" className="animate-spin" alt="busy"/>
    </div>
  );
}

export default Spinner;
