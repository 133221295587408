import {gql} from "@apollo/client";

export const WORKSPACE_FIELDS = gql`
    fragment WorkspaceFields on Workspace {
        id
        logo {
            filename
            mimetype
            url
        }
        name
        slug
        website
        enableInviteToken
        enableVerifiedDomains
        projects {
            id
            name
        }
        members {
            id
            isOwner
            role
            deactivatedAt
            user {
                id
                role
                status
                email
                detail {
                    id
                    avatar {
                        filename
                        mimetype
                        url
                    }
                    fullName
                    position
                }
            }
        }
        invitations {
            id
            inviteeEmail
            role
        }
        inviteToken
        domains {
            id
            domain
            enabled
            createdAt
            verifiedAt
            verificationEmail
        }
    }
`

export const USER_DETAIL_FIELDS = gql`
    fragment UserDetailFields on UserDetail {
        id
        fullName
        position
        avatar {
            filename
            mimetype
            url
        }
    }
`

export const INBOX_FIELDS = gql`
    fragment InboxFields on Inbox {
        id
        category
        createdAt
        isRead
        sender {
            id
            detail {
                ...UserDetailFields
            }
        }
        type
        context
    }
    ${USER_DETAIL_FIELDS}
`
