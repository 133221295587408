// MenuItem.tsx
import React from 'react';

interface MenuItemProps {
  user: { username: string };
  setItem: () => void;
  selected: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ user, setItem, selected }) => (
  <div
    role="option"
    className={`cursor-default p-4 ${selected ? 'bg-gray-400 text-white' : 'hover:bg-gray-200'}`}
    aria-selected={selected}
    onClick={setItem}
  >
    {user.username}
  </div>
);

export default MenuItem;
