import { AnimatePresence, motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import IconButton from "src/components/UI/Button/IconButton";
import HeaderText from "src/components/UI/HeaderTexts";
import HorizontalDivider from "src/components/UI/HorizontalDivider";
import Tabs from "src/components/UI/Tables/Tab";
import { Check, Close, Plus, ThreeDots } from "src/constants/icons";
import DropGroup from "src/components/UI/DropGroup";
import InboxComment from "src/components/Features/Inbox/InboxComment";
import MobileWorkSpaceMenu from "src/components/UI/MobileWorkSpaceMenu";
import { useMe } from "src/api/auth/useMe";
import { IUserWorkspace } from "src/lib/types";
import { useParams } from "react-router-dom";

type Tab = {
  id: string;
  value: string;
};

const tabs: Tab[] = [
  { id: "All", value: "All" },
  { id: "Projects", value: "Projects" },
  { id: "Mentions", value: "Mentions" },
];

type DataItem = {
  name: string;
  value: "readAll";
  icon: JSX.Element;
};

const readAllData: DataItem[] = [
  {
    name: "Mark all as read.",
    value: "readAll",
    icon: <Check />,
  },
];

export default function InboxPage() {
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const [showReadAll, setShowReadAll] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const { loading, data } = useMe();
  const userWorkspaces = data?.me?.userWorkspaces;
  const userWorkspace = userWorkspaces?.find(
    ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
  );

  // console.log({
  //   userWorkspace,
  //   userWorkspaces,
  // });

  function handleReadAll() {}

  useEffect(() => {
    if (!showReadAll) {
      document.addEventListener("mousedown", () => setShowReadAll(false));
    } else {
      document.removeEventListener("mousedown", () => setShowReadAll(false));
    }

    return () => {
      document.removeEventListener("mousedown", () => setShowReadAll(false));
    };
  }, [showReadAll]);

  return (
    <motion.div className="w-full md:w-auto">
      <div className="page-title z-1 flex items-center justify-between md:hidden">
        {userWorkspace && (
          <MobileWorkSpaceMenu
            userWorkspace={userWorkspace}
            userWorkspaces={userWorkspaces}
            user={data?.me}
          />
        )}
        <p className="tet-body-small bold text-[var(--text-neutral)]">Inbox</p>
        <div className="relative">
          <IconButton
            icon={<ThreeDots />}
            size={"micro"}
            type={"ghost"}
            onClick={() => setShowReadAll(true)}
          />
          <AnimatePresence>
            <div className="absolute top-8 right-0">
              <DropGroup
                open={showReadAll}
                data={readAllData}
                style={{ width: "200px" }}
                onClick={handleReadAll}
                clickOutside={() => {
                  setShowReadAll(false);
                }}
              />
            </div>
          </AnimatePresence>
        </div>
      </div>
      <div className=" mt-[65px]">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          //   className="group-container border rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]"
          ref={modalRef}
        >
          <div className="flex flex-col p-5 gap-2.5">
            <Tabs
              tabs={tabs}
              onTabChange={(tab) => console.log(tab)}
              initialSelectedTab={currentTab}
            />
          </div>
          {/* <HorizontalDivider /> */}
          <ul className="w-full">
            <>
              {/* <li> */}
                {/* <HorizontalDivider /> */}
                {/* <InboxComment />
              </li> */}
              {/* <li>
                <HorizontalDivider />
                <InboxComment />
              </li>
              <li>
                <HorizontalDivider />
                <InboxComment />
              </li>
              <li>
                <HorizontalDivider />
                <InboxComment />
              </li>
              <li>
                <HorizontalDivider />
                <InboxComment />
              </li>
              <li>
                <HorizontalDivider />
                <InboxComment />
              </li>
              <li>
                <HorizontalDivider />
                <InboxComment />
              </li> */}
            </>
          </ul>
        </motion.div>
      </div>
    </motion.div>
  );
}
