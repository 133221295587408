import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from "@headlessui/react";
import { CheckGoodYes, ChevronDownArrow, LockOpen, LockPrivacy } from "react-basicons";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import classNames from "classnames";
import { theme } from "src/lib/helpers";
import ColorTag from './UI/Tags/ColorTags';
import { Check, Lock, Unlock } from 'src/constants/icons';
import StatusTag from './UI/Tags/StatusTags';

interface ProjectAccessSelectProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  readOnly: boolean;
  projectId?: string;
}

const ProjectPublicAccessSelect: React.FC<ProjectAccessSelectProps> = props => {
  const { checked, onChange, readOnly = true, projectId } = props;

  return (
    <Listbox value={checked} onChange={onChange}>
      <div className="relative">
        {readOnly && <StatusTag
          value={checked ? 'Public' : 'Private'}
          color={'default'} size={'medium'}
          leftIcon={checked
            ? <Unlock className='w-3 h-3' />
            : <Lock className='w-3 h-3' />}
        />}
        {!readOnly && <>
          <Listbox.Button className={classNames(`relative cursor-pointer justify-start`)}>
            <StatusTag
              value={checked ? 'Public' : 'Private'}
              color={'default'} size={'medium'}
              leftIcon={checked
                ? <Unlock className='w-3 h-3' />
                : <Lock className='w-3 h-3' />}
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Listbox.Options className="absolute mt-3 z-10 w-48 overflow-auto p-1 drop-group">
              {[true, false].map((value, index) => (
                <Listbox.Option
                  key={index}
                  disabled={readOnly}
                  className={classNames(
                    `drop-item flex px-2 items-center w-full cursor-pointer rounded gap-2.5 h-8 no-snippet`,
                    { 'cursor-pointer': !readOnly }
                  )}
                  value={value}
                >
                  {({ selected }) => (
                    <>
                      {value
                        ? <Unlock className='w-3 h-3' />
                        : <Lock className='w-3 h-3' />
                      }
                      <span className={`flex-1 text-body-small medium text-[var(--text-neutral)] truncate`}>
                        {value ? 'Public' : 'Private'}
                      </span>
                      {selected ? (
                        <span className="">
                          <Check />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>}
      </div>
    </Listbox>
  );
}

export default ProjectPublicAccessSelect;
