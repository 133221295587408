import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Validator from "validatorjs";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../lib/mutations";
import ValidationErrorMessages from "../../components/ValidationErrorMessages";
import Spinner from "../../components/Spinner";
import Button from 'src/components/UI/Button/Button';
import { ChevronDown } from 'src/constants/icons';
import Input from 'src/components/UI/Inputs/Default';
import { validateEmail } from 'src/utils/Index';
import BackButton from 'src/components/UI/Button/BackButton';

type FormData = {
  email: string;
}

function ResetPassword() {
  // graphQL definitions
  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);
  const errorMessage = error && `${error.message}`;

  // react state and router
  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')



  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (validateEmail(email)) {
      setEmailError("")

      resetPassword({
        variables: { email },
        onCompleted: ({ resetPassword }) => {
          navigate('/auth/verify-otp?email=' + email + '&ui=basic');
        }
      });
    }
    else {
      setEmailError('Invalid email format.');
    }
  };

  return (
    <>
      <div className="w-full flex flex-col gap-4 items-start">
        <BackButton />
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3 bold text-[var(--text-default)]">Reset password</h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">Enter the email address that you used to create your account.</p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-full flex flex-col gap-6'
      >
        <Input
          autoFocus
          type="email"
          value={email}
          feedback={emailError}
          feedbackType={emailError.length ? 'error' : 'success'}
          label="Email address"
          placeholder="Enter your email address"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          btnType="primary"
          size="large"
          value='Continue'
          loading={loading}
          disabled={loading}
          className="w-full"
        />
      </form>
    </>
  );
}

export default ResetPassword;
