import React, { useEffect, useRef } from 'react';

import './ui.css';
import { AnimatePresence, motion } from "framer-motion"
import { Search } from "src/constants/icons";
import HorizontalDivider from './HorizontalDivider';
import { transformToTitleCase } from 'src/lib/helpers';

interface DropGroupProps {
    open: boolean;
    data: {
        name: string;
        value?: string;
        type?: string;
        icon?: React.ReactNode;
        snippet?: string;
    }[];
    search?: boolean;
    title?: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: (item: any) => void;
    clickOutside?: (item: any) => void;
}

const DropGroup: React.FC<DropGroupProps> = ({ open, data, search, title, className, style, onClick, clickOutside }) => {
    const dropDownGroupRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropDownGroupRef.current && !dropDownGroupRef.current.contains(event.target as Node)) {
                clickOutside && clickOutside(null);
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, clickOutside]);

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    ref={dropDownGroupRef}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`drop-group p-1 overflow-auto relative z-10 flex flex-col gap-1
                        ${className || ''} 
                        ${open ? "content-open" : ''}`}
                    style={style}
                >
                    {search && <SearchFilter />}
                    {title &&
                        <p className='drop-title text-body-micro bold py-1 font-semibold px-2 capitalize' >
                            {title}
                        </p>
                    }
                    <div className='flex flex-col gap-1'>
                        {data.map((item, index) => (
                            <DropItem key={index} item={item} onItemClick={onClick} />
                        ))}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export function DropItem({
    item,
    onItemClick,
}: {
    item: {
        name: string;
        value?: string;
        icon?: React.ReactNode;
        snippet?: string;
    };
    onItemClick?: (item: any) => void;
}) {

    const handleClick = () => {
        onItemClick && onItemClick(item);
    };

    return (
        <div onClick={handleClick}
            className={`drop-item flex px-2 items-center w-full cursor-pointer rounded ${item.snippet ? 'gap-2 snippet h-12' : 'gap-2.5 h-8 no-snippet'
                }`}
        >
            {item.icon}
            <div className='item flex flex-col items-start'>
                <p className='title text-body-small medium'>{transformToTitleCase(item.name)}</p>
                {item.snippet && <p className='snippet text-body-micro regular'>{item.snippet}</p>}
            </div>
        </div>
    );
}

function SearchFilter() {
    return (
        <div className='flex flex-col items-start justify-center pt-1 gap-2'>
            <div className='flex gap-2 items-center px-2'>
                <Search />
                <input className='dropdown-input bg-transparent border-0' type="" placeholder='Search' />
            </div>
            <HorizontalDivider />
        </div>
    )
}

export default DropGroup;
