import { AnimatePresence, motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import IconButton from "src/components/UI/Button/IconButton";
import HeaderText from "src/components/UI/HeaderTexts";
import HorizontalDivider from "src/components/UI/HorizontalDivider";
import Tabs from "src/components/UI/Tables/Tab";
import { Check, Close, Inbox, ThreeDots } from "src/constants/icons";
import InboxComment from "./InboxComment";
import DropGroup from "src/components/UI/DropGroup";
import { useInbox } from "../../../api/inbox/useInbox";
import { IInbox } from "../../../lib/types";
import { useReadAllInbox } from "../../../api/inbox/useReadAllInbox";

enum TabType {
  ALL = "ALL",
  PROJECTS = "PROJECTS",
  MENTIONS = "MENTIONS",
}

type Tab = {
  id: string;
  value: string;
}

const tabs: Tab[] = [
  { id: 'All', value: 'All' },
  { id: 'Projects', value: 'Projects' },
  { id: 'Mentions', value: 'Mentions' }
];

type DataItem = {
  name: string;
  value: 'readAll';
  icon: JSX.Element;
}

const data: DataItem[] = [
  {
    name: 'Mark all as read.',
    value: 'readAll',
    icon: <Check />
  },
];

export default function InboxPage({ showInbox, handleClick }: {
  showInbox: boolean;
  handleClick: () => void;
}) {
  const tabs: Tab[] = Object.keys(TabType).map(key => ({
    id: key,
    value: TabType[key as keyof typeof TabType].toLowerCase()
  }));
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].id);

  const [readAllInbox, { loading: readAllInboxLoading }] = useReadAllInbox();
  // const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const [showReadAll, setShowReadAll] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { data: inboxData } = useInbox({
    variables: {
      orderBy: [
        { createdAt: 'desc' }
      ]
    },
  });

  let tabData = inboxData?.inbox ?? [];

  if (currentTab !== TabType.ALL) {
    tabData = tabData.filter((each: IInbox) => each.category === currentTab);
  }

  function handleReadAll() {
    if (readAllInboxLoading) return;
    readAllInbox({
      onCompleted: () => setShowReadAll(false)
    });
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleClick();
      }
    };

    if (showInbox && !showReadAll) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showInbox, showReadAll, handleClick]);

  return (
    <AnimatePresence>
      {showInbox && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-10 inset-0"
        >
          <div className='fixed inset-0 flex w-screen items-start justify-center p-2'>
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className='group-container border ml-[-40%] rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]'
              ref={modalRef}
            >
              <div className="flex flex-col p-5 gap-2.5">
                <div className="flex items-center justify-between w-full">
                  <HeaderText subTitle={'Inbox'} />
                  <div className="relative">
                    <IconButton icon={<ThreeDots />} size={'micro'} type={'ghost'} onClick={() => setShowReadAll(true)} />
                    <AnimatePresence>
                      <div className="absolute top-8 left-0">
                        <DropGroup
                          open={showReadAll}
                          data={data}
                          style={{ width: '200px' }}
                          onClick={handleReadAll}
                          clickOutside={() => {
                            setShowReadAll(false)
                          }}
                        />
                      </div>
                    </AnimatePresence>
                  </div>
                </div>
                <Tabs tabs={tabs} onTabChange={setCurrentTab} initialSelectedTab={currentTab} />
              </div>
              {<HorizontalDivider />}
              <ul className="max-h-[80vh] h-full overflow-y-auto rounded-bl-xl rounded-br-xl">
                {Boolean(tabData.length === 0) && <li className="h-[60vh] flex flex-col items-center justify-center">
                  <div className="flex flex-col gap-1 items-center">
                    <Inbox className="h-8 w-8" />
                    <p className="text-heading-6 medium text-[var(--text-default)]">Nothing yet</p>
                    <p className="text-body-small regular text-[var(--text-neutral)]">Nothing to see here.</p>
                  </div>
                </li>
                }
                {tabData.map((each: IInbox) => (
                  <li key={each.id}>
                    <HorizontalDivider />
                    <InboxComment inbox={each} />
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
