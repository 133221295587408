import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
// Import types and icons
import { IUser, IUserWorkspace } from "src/lib/types";
import { AddPlus, Search } from "react-basicons";
import { Popover, Transition } from "@headlessui/react";
// Import hooks and helpers
import { useWorkspace } from "src/api/workspace/useWorkspace";
import { fuzzySearchRegex, theme, transformToTitleCase } from "src/lib/helpers";
// import Avatar from "src/components/Avatar";
import usePrevious from "src/hooks/usePrevious";
import { useDebounce } from "@uidotdev/usehooks";
import classNames from "classnames";
// Import Avatar and other UI components
import Avatar from './UI/Avatar/Avatar';
import { Plus } from 'src/constants/icons';
import HorizontalDivider from './UI/HorizontalDivider';

// Define the component props interface
interface ProjectLeadSelectProps {
    onChange: Function;
    projectId?: string;
    userId?: string | null;
    value: string[];
    workspaceSlug: string;
    readOnly?: boolean;
}

// Define the main component
const ProjectLeadSelect: React.FC<ProjectLeadSelectProps> = props => {
    const { projectId, value, onChange, workspaceSlug, userId, readOnly = true } = props;
    // State for the search term
    const [searchTerm, setSearchTerm] = React.useState("");
    // Debounce the search term to improve performance
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    // Create a regex for fuzzy search
    const regex = fuzzySearchRegex(debouncedSearchTerm);
    // Handle search term change
    const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };
    // Filter function for search term
    const searchTermFilter = (user: IUser) => {
        if (debouncedSearchTerm.length) {
            return regex.test(user.detail.fullName);
        }
        return true;
    };

    // Fetch workspace data
    const { loading, data } = useWorkspace({
        variables: {
            where: { slug: workspaceSlug }
        }
    });

    // Extract project leads from workspace members and filter based on search term
    const projectLeads = data?.workspace.members
        .map((member: IUserWorkspace) => member.user)
        .filter(searchTermFilter)
        ?? [];

    // Get the previous state of project leads
    const prevprojectLeads = usePrevious(projectLeads);
    // State for selected options
    const [selectedOption, setSelectedOption] = useState<IUser | null>(null);

    // Effect to update selected option when project leads change
    useEffect(() => {
        if (prevprojectLeads?.length !== projectLeads.length && projectLeads.length) {
            const initialSelection = projectLeads.find((each: IUser) => value?.includes(each.id) || (userId && each.id === userId));
            setSelectedOption(initialSelection || null);
        }
    }, [prevprojectLeads, projectLeads]);

    // Handle checkbox change for selecting options
    const handleCheckboxChange = (option: IUser) => {
        const newSelection = selectedOption?.id === option.id ? null : option;
        setSelectedOption(newSelection);
        onChange(newSelection ? [newSelection.id] : []);
    };

    // Show loading state
    if (loading) return null;

    // Button to show selected options
    const button = selectedOption ? (
        <ul className="flex items-center -space-x-2 rounded-lg w-fit hover:bg-[var(--backgrounds-default)] p-1">
            <li key={selectedOption.id} className='flex gap-1.5 items-center'>
                <Avatar
                    name={selectedOption.detail.fullName}
                    src={selectedOption.detail?.avatar?.url}
                    alt={selectedOption.detail.fullName}
                    size={20}
                />
                <p className="text-body-micro medium text-[var(--text-default)]">{selectedOption.detail.fullName}</p>
            </li>
        </ul>
    ) : (
        <div className='button ghost small justify-start w-fit transition !pl-1.5'>
            <Plus />
            <span className="text-[var(--text-on-bg)]  text-body-micro font-medium">Select project lead</span>
        </div>
    );

    // Check if there are no search results
    const noSearchResults = projectLeads.length === 0 && debouncedSearchTerm.length > 0;

    // Main render of the component
    return (
        <Popover className={`relative`}>
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`${open ? '' : ''} justify-start`}
                    >
                        {button}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-0 z-10 mt-3 max-w-[250px] w-full space-y-4">
                            <div
                                className="drop-group p-1  w-full max-h-[470px] overflow-y-auto ">
                                <div className="sticky top-0 z-10">
                                    {readOnly || (
                                        <div className="relative bg-[var(--backgrounds-dropdowns)] rounded-lg">
                                            {/* Search Term Input */}
                                            <input
                                                autoFocus
                                                value={searchTerm}
                                                onChange={handleSearchTermChange}
                                                id="collaborator-search"
                                                type=""
                                                className="input-clear h-[30px] !px-2 w-full text-[var(--text-default)] text-body-small regular placeholder:text-[var(--text-neutral)]"
                                                placeholder="Select project lead"
                                            />
                                        </div>
                                    )}
                                </div>

                                { readOnly || <HorizontalDivider />}
                                <div className="">
                                    {noSearchResults && (
                                        <p className="text-center p-4 text-body-micro regular text-[var(--text-neutral)] whitespace-break-spaces">
                                            No search found for “<span className="font-semibold">{debouncedSearchTerm}</span>” in your
                                            workspace.
                                        </p>
                                    )}
                                    <ul>
                                        {!readOnly && projectLeads.map((user: IUser) => (
                                            <li key={user.id} className='px-2'>
                                                {/* <li key={user.id} className={Boolean(userId) && user.id === userId ? 'hidden' : ''}> */}
                                                <label className={
                                                    classNames("flex items-center gap-2 h-11", { 'cursor-pointer': !readOnly })
                                                }>
                                                    <Avatar
                                                        name={user.detail.fullName}
                                                        src={user.detail?.avatar?.url}
                                                        alt={user.detail.fullName}
                                                        size={28}
                                                    />
                                                    <div className="flex-1">
                                                        <div className="text-body-small medium text-[var(--text-default)]">
                                                            {user.detail.fullName}
                                                        </div>
                                                        <div className="text-body-micro regular text-[var(--text-neutral)]">
                                                            {transformToTitleCase(user.detail.position)}
                                                        </div>
                                                    </div>
                                                    {readOnly || (
                                                        <div className="">
                                                            {selectedOption?.id === user.id ?
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.793 1.33398H5.20634C2.77967 1.33398 1.33301 2.78065 1.33301 5.20732V10.7873C1.33301 13.2207 2.77967 14.6673 5.20634 14.6673H10.7863C13.213 14.6673 14.6597 13.2207 14.6597 10.794V5.20732C14.6663 2.78065 13.2197 1.33398 10.793 1.33398ZM11.1863 6.46732L7.40634 10.2473C7.31301 10.3407 7.18634 10.394 7.05301 10.394C6.91967 10.394 6.79301 10.3407 6.69967 10.2473L4.81301 8.36065C4.61967 8.16732 4.61967 7.84732 4.81301 7.65398C5.00634 7.46065 5.32634 7.46065 5.51967 7.65398L7.05301 9.18732L10.4797 5.76065C10.673 5.56732 10.993 5.56732 11.1863 5.76065C11.3797 5.95398 11.3797 6.26732 11.1863 6.46732Z" fill="#8178EF" />
                                                                </svg>
                                                                :
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="1.83301" y="1.83398" width="12.3333" height="12.3333" rx="2.16667" fill="none" stroke="var(--border-default)" />
                                                                </svg>
                                                            }
                                                            <input
                                                                // disabled={Boolean(userId) && user.id === userId || readOnly}
                                                                type="checkbox"
                                                                className="sr-only input-clear border border-[var(--border-default)] !rounded bg-[var(--backgrounds-on-canvas)] checked:bg-[rgba(129, 120, 239, 1)]"
                                                                value={user as any}
                                                                checked={selectedOption?.id === user.id}
                                                                onChange={() => handleCheckboxChange(user)}
                                                            />
                                                        </div>
                                                    )}
                                                </label>
                                            </li>
                                        ))}
                                        {readOnly && selectedOption && <li key={selectedOption.id} className='flex gap-1.5 items-center'>
                                            <Avatar
                                                name={selectedOption.detail.fullName}
                                                src={selectedOption.detail?.avatar?.url}
                                                alt={selectedOption.detail.fullName}
                                                size={20}
                                            />
                                            <p className="text-body-micro medium text-[var(--text-default)]">{selectedOption.detail.fullName}</p>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}

export default ProjectLeadSelect;
