import 'src/components/sideNav/sidenav.css'

interface BadgeProps {
  value: string | number | undefined;
}

const Badge: React.FC<BadgeProps> = ({ value }) => {
  return (
    <div className="flex items-center justify-center p-1 rounded bg-[var(--text-default)] h-3.5">
      <span className='text-caption bold text-[var(--backgrounds-on-canvas)]'>{value}</span>
    </div>
  );
};

export default Badge;
