import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ME } from "src/lib/queries";
import { UPDATE_ONE_USER_DETAIL } from "src/lib/mutations";

export function useUpdateOneUserDetail() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  } : {}

  return useMutation(UPDATE_ONE_USER_DETAIL, { ...options, refetchQueries: [{ query: ME, ...options }], });
}
