import HomeSideNav from "src/components/sideNav/HomeSideNav"
import SideNav from "src/components/sideNav/SideNav"
import React, { useState } from 'react';
import { useMe } from "src/api/auth/useMe";
import { IUserWorkspace, IWorkspace } from "src/lib/types";
import { useParams, Outlet, Navigate } from 'react-router-dom';
import WorkspaceMenu from "src/components/WorkspaceMenu";
import SideNavMobile from "src/components/sideNav/SideNavMobile";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ProjectContextProvider } from "src/context/ProjectContext";
import { PostsContextProvider } from "src/context/PostContext";


export default function DashboardLayout() {
    const { data } = useMe();
    const { workspaceSlug } = useParams();
    const [accessToken] = useLocalStorage("accessToken", undefined);
    const userWorkspaces = data?.me?.userWorkspaces;
    const userWorkspace = userWorkspaces?.find(({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug);
    const [createProject, setCreateProject] = useState(false);

    return (
        !accessToken ? <Navigate to="/auth/login" /> :
            <ProjectContextProvider>
                <PostsContextProvider>
                    <div className="flex fixed min-h-screen w-full">
                        <span className="min-h-screen hidden md:block">
                            <SideNav>
                                {data?.me && (
                                    <WorkspaceMenu
                                        userWorkspace={userWorkspace}
                                        userWorkspaces={userWorkspaces}
                                        user={data.me}
                                    // onChange={workspace => redirect(`/workspace/${workspace.slug}`)}
                                    />
                                )}
                                <HomeSideNav />
                            </SideNav>
                        </span>
                        <div className="w-full h-[calc(100vh-16px)] m-0 overflow-auto flex gap-2 md:m-2">
                            <Outlet />
                        </div>
                    </div>
                </PostsContextProvider>
            </ProjectContextProvider>
    )
}