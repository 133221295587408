import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {INVITE_TEAM_TO_ONE_WORKSPACE} from "src/lib/mutations";

export function useInviteTeamToOneWorkspace() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  } : {}


  return useMutation(INVITE_TEAM_TO_ONE_WORKSPACE, options);
}
