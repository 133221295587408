import React from "react";
import Media from "src/components/Media";
import {
  ArrowDropdownCaretSortSelectArrow,
  CheckGoodYes,
} from "react-basicons";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useLogout } from "src/hooks/useLogout";
import { Link } from "react-router-dom";
import { IUser, IUserWorkspace } from "src/lib/types";
import { theme } from "../lib/helpers";
import { Check, ChevronDown } from "src/constants/icons";
import { DropItem } from "./UI/DropGroup";
import HorizontalDivider from "./UI/HorizontalDivider";
import { useParams } from "react-router-dom";

interface WorkspaceMenuProps {
  workspaceId?: String;
  userWorkspace: IUserWorkspace;
  userWorkspaces: IUserWorkspace[];
  user: IUser;
  mobile?: boolean;
}

const WorkspaceMenu: React.FC<WorkspaceMenuProps> = (props) => {
  const logout = useLogout();
  const { userWorkspace, userWorkspaces, user, mobile } = props;
  const { workspace } = userWorkspace;
  const workspaces = userWorkspaces.map(({ workspace }) => workspace);

  const { workspaceSlug } = useParams();

  return (
    <Menu as="div" className={!mobile ? 'sticky top-0 z-10 w-full' : ''}>
      {({ open }) => (
        <>
          <Menu.Button
            className={`w-fit md:w-full z-50
            hover:bg-[var(--backgrounds-hover-clicked)]
            h-12 ${open ? "!bg-[var(--backgrounds-hover-clicked)]" : ""}
            duration-300 hover:!duration-0 rounded-lg cursor-pointer
            flex items-center space-x-2 p-3 px-2`}
          >
            <Media
              media={workspace.logo}
              width={32}
              height={32}
              alt={workspace.name}
              fontSize={16}
            />
            {!mobile && (
              <>
                <div className="flex-1 truncate text-left">
                  <div className="text-heading-6 text-[var(--text-default)] medium capitalize">
                    {workspace.name}
                  </div>
                  <div
                    className="text-body-micro regular text-[var(--text-neutral)] truncate"
                    title={user.email}
                  >
                    {user.email}
                  </div>
                </div>
                <div>
                  <ChevronDown />
                </div>
              </>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              // className="max-h-[75vh] overflow-y-auto w-64 absolute left-0 mt-2 origin-top-left divide-y divide-grey-50 rounded-md÷ bg-white shadow-xl border border-grey-50">
              className="origin-top-left absolute max-w-[350px] w-full mt-2 p-1 flex flex-col items-start gap-2 rounded-lg border border-[var(--border-on-bg)] bg-[var(--backgrounds-dropdowns)] shadow-[0px_4px_8px_0px_rgba(89,_89,_89,_0.10)]"
            >
              {/* <div className="p-2 space-y-6"> */}
              <div className="flex flex-col gap-2 items-start w-full">
                <div
                  className="px-3 text-body-micro bold text-[var(--text-neutral)] truncate"
                  title={user.email}
                >
                  {user.email}
                </div>

                <div className="flex flex-col gap-1 items-start w-full">
                  {workspaces.map((each) => (
                    <Menu.Item key={each.id}>
                      {({ active }) => (
                        <Link
                          to={`/workspace/${each.slug}/project`}
                          className={`${
                            active
                              ? "bg-[var(--backgrounds-hover-clicked)]"
                              : ""
                          } rounded cursor-pointer flex items-center gap-2.5 px-2 h-10 w-full`}
                        >
                          <Media
                            media={each.logo}
                            width={32}
                            height={32}
                            alt={each.name}
                            fontSize={16}
                          />
                          <div className="flex-1 truncate text-left capitalize">
                            <div className="text-body-small medium text-[var(--text-neutral)]">
                              {each.name}
                            </div>
                          </div>
                          {workspace.id === each.id && <Check />}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </div>

              <HorizontalDivider />

              <div className="space-y-0.5 w-full">
                <Menu.Item>
                  {({ active }) => (
                    // <button
                    //   className={`${active ? 'bg-grey-50' : ''
                    //     } group flex w-full items-start justify-start rounded p-2 py-1.5 text-red-600`}
                    // >
                    //   Workspace settings
                    // </button>
                    <Link to={`/workspace/${workspaceSlug}/settings/general`}>
                      <DropItem item={{ name: "Settings" }} />
                      {/* <DropItem item={{ name: 'Workspace settings' }} /> */}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/invite-to-workspace?${new URLSearchParams({
                        workspace: userWorkspace.workspace.slug,
                      }).toString()}`}
                      // className={`${active ? 'bg-grey-50' : ''
                      //   } group flex w-full items-center rounded p-2 py-1.5 text-sm text-gray-900`}
                    >
                      <DropItem item={{ name: "Invite new members" }} />
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/create-workspace"
                      // className={`${active ? 'bg-grey-50' : ''
                      //   } group flex w-full items-center rounded-md p-2 py-1.5 text-sm text-gray-900`}
                    >
                      <DropItem item={{ name: "Create new workspace" }} />
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/join-workspace"
                      // className={`${active ? 'bg-grey-50' : ''
                      //   } group flex w-full items-center rounded-md p-2 py-1.5 text-sm text-gray-900`}
                    >
                      <DropItem item={{ name: "Add workspace" }} />
                    </Link>
                  )}
                </Menu.Item>
              </div>

              <HorizontalDivider />

              <div className="space-y-0.5 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to=""
                      // className={`${active ? 'bg-grey-50' : ''
                      //   } group flex w-full items-center rounded-md p-2 py-1.5 text-sm text-gray-900`}
                    >
                      <DropItem item={{ name: "Help & support" }} />
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div onClick={logout}>
                      <DropItem item={{ name: "Log out" }} />
                    </div>
                    // <button
                    //   onClick={logout}
                    //   className={`${active ? 'bg-grey-50' : ''
                    //     } group flex w-full items-center rounded-md p-2 py-1.5 text-sm text-gray-900`}
                    // >
                    //   Log out
                    // </button>
                  )}
                </Menu.Item>
              </div>
              {/* </div> */}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default WorkspaceMenu;
