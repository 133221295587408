import { Navigate, Outlet } from 'react-router-dom';
import { useLocalStorage } from "@uidotdev/usehooks";


export default function AuthLayout() {
    const [accessToken] = useLocalStorage("accessToken", undefined);

    // If authorized, return element that will navigate to home page
    // If not, return an outlet that will render child elements
    return (
        // accessToken ? <Navigate to="/" /> :
        <div className="flex min-h-screen w-full">
            <div className="outlet m-0 rounded-none !overflow-hidden h-screen flex justify-center items-baseline !bg-[var(--backgrounds-canvas)] md:h-[calc(100vh-16px)] md:m-2 md:rounded-lg md:!items-center">
                <div className="outlet_inner mt-16 w-full h-fit md:mt-0">
                    <main className='flex flex-col max-w-[500px] items-start gap-14 h-full p-4 w-full'>
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    )
}