import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {ME} from "src/lib/queries";
import {UPSERT_ONE_USER_NOTIFICATION_SETTINGS} from "src/lib/mutations";

export function useUpsertOneUserNotificationSettings() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  } : {}

  return useMutation(UPSERT_ONE_USER_NOTIFICATION_SETTINGS, {...options, refetchQueries: [{query: ME, ...options}],});
}
