import React, { ChangeEvent, Fragment } from 'react';
import { Listbox, Transition } from "@headlessui/react";
import {
  AdAnnouncementMegaphone,
  ArrowDropdownCaretSortSelectArrow,
  CheckGoodYes, Search,
} from "react-basicons";
import { IProjectGoal, PostType } from "src/lib/types";
import { fuzzySearchRegex, theme, transformToTitleCase } from "src/lib/helpers";
import classNames from "classnames";
import Button from "src/components/Button";
import { useDebounce } from "@uidotdev/usehooks";
import StatusTag from './UI/Tags/StatusTags';
import { Check, ChevronDown, MegaPhone } from 'src/constants/icons';

interface PostTypeSelectProps {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
}

const PostTypeSelect: React.FC<PostTypeSelectProps> = props => {
  const { readOnly = false, value, onChange } = props;
  const [searchTerm, setSearchTerm] = React.useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const regex = fuzzySearchRegex(debouncedSearchTerm);
  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const searchTermFilter = (type: string) => {
    if (debouncedSearchTerm.length) {
      return regex.test(type);
    }
    return true;
  };

  const postTypes = Object.values(PostType);
  const filteredPostTypes = postTypes.filter(searchTermFilter);
  const noSearchResults = filteredPostTypes.length === 0 && debouncedSearchTerm.length > 0;

  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative">
        <Listbox.Button className={`relative cursor-pointer`}>
          <StatusTag leftIcon={<MegaPhone className='w-4 h-4' />} rightIcon={<ChevronDown className='w-4 h-4' />} color={'default'} size={'large'} value={transformToTitleCase(value)} />
          {/* <AdAnnouncementMegaphone size={12} weight={2}/>
          <span>{transformToTitleCase(value)}</span>
          <ArrowDropdownCaretSortSelectArrow size={16} color={(theme.textColor.grey as any)['300']}/> */}
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Listbox.Options className="absolute w-[200px] mt-3 z-10 drop-group p-1 overflow-y-auto flex flex-col gap-1">
            {/* <div className="sticky top-0 z-10 bg-white rounded-t-lg">
              {readOnly || (
                <div className="relative border-b border-grey-100">
                  <label
                    htmlFor="post-type-search"
                    className="absolute left-0 inset-y-0 px-3 grid place-content-center"
                  >
                    <Search size={12} />
                  </label>
                  <input
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    id="post-type-search"
                    type="text"
                    className="input-clear py-3 !pl-8 !pr-3 w-full text-sm font-light"
                    placeholder="Search"
                  />
                </div>
              )}
              {noSearchResults || (
                <div className="py-3 px-4 text-xs text-grey-300">
                  What type of of update is this?
                </div>
              )}
            </div> */}
            {/* {noSearchResults && (
              <p className="text-grey-300 text-xs text-center p-4">
                No search found for “<span className="font-semibold">{debouncedSearchTerm}</span>”
              </p>
            )} */}
            {filteredPostTypes.map((type, index) => (
              <Listbox.Option
                key={index}
                disabled={readOnly}
                className={classNames(
                  `drop-item flex px-2 items-center justify-between w-full cursor-pointer rounded gap-2.5 h-8 no-snippet`,
                  { 'cursor-pointer': !readOnly }
                )}
                value={type}
              >
                {({ selected }) => (
                  <>
                    <span className={`flex-1 truncate text-body-small medium text-[var(--text-on-bg)]`}>
                      {transformToTitleCase(type)}
                    </span>
                    {selected ? (
                      <span className="">
                        <Check />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default PostTypeSelect;
