import React, {forwardRef} from 'react';
import { Check } from 'src/constants/icons';

export const TablePopOverWrap = forwardRef<HTMLDivElement, {
  children: React.ReactNode;
  className?: string;
}>(({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}, ref) => {
    return (
        <div ref={ref} className={`absolute z-10 top-8 rounded-lg w-52 flex flex-col gap-1 items-start p-1 border-[var(--border-on-bg)] bg-[var(--backgrounds-dropdowns)] shadow-[0px_8px_16px_0px_rgba(42,42,42,0.10)] ${className}`}>
            {children}
        </div>
    )
})

export function PopOverItem({ active, name, snippet, onClick }: {
    name: string;
    snippet: string;
    active: boolean;
    onClick: () => void;
}) {
    return (
        <div className='cursor-pointer flex gap-3 items-center p-3 rounded hover:bg-[var(--backgrounds-hover-clicked)] transition duration-300 ease' onClick={onClick}>
            <div className="flex flex-col items-start">
                <p className="text-body-small medium text-[var(--text-default)]">{name}</p>
                <p className="text-body-micro regular text-[var(--text-neutral)]">{snippet}</p>
            </div>
            <div>
                {active && <Check />}
            </div>
        </div>
    )
}
