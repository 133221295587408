// Menu.tsx
import React from 'react';
import MenuItem from './MenuItem';

interface MenuProps {
  options: { username: string }[];
  active: number;
  menuPosition: { left: number; top: number } | undefined;
  selectItem: (idx: number) => () => void;
}

const Menu: React.FC<MenuProps> = ({ options, active, menuPosition, selectItem }) => (
  <div
    className={`absolute bg-gray-100 ${menuPosition ? '' : 'hidden'}`}
    style={{ left: menuPosition?.left, top: menuPosition?.top }}
    role="listbox"
  >
    {options.map((option, idx) => (
      <MenuItem
        key={option.username}
        user={option}
        setItem={selectItem(idx)}
        selected={active === idx}
      />
    ))}
  </div>
);

export default Menu;
