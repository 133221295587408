export default function LightMode() {
    return (
        <svg width="145" height="106" viewBox="0 0 145 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.391892" y="0.391892" width="144.216" height="102.788" rx="5.87838" fill="#F9F9F9" stroke="#E0E0E0" strokeWidth="0.783784" />
            <g filter="url(#filter0_d_5401_104981)">
                <rect x="33.1426" y="4.14288" width="71.4643" height="26.9286" rx="3.13514" fill="white" />
            </g>
            <g filter="url(#filter1_d_5401_104981)">
                <rect x="33.1426" y="35.2143" width="71.4643" height="64.2143" rx="3.13514" fill="white" />
            </g>
            <g filter="url(#filter2_d_5401_104981)">
                <rect x="108.75" y="4.14288" width="32.1071" height="95.2857" rx="3.13514" fill="url(#paint0_radial_5401_104981)" />
            </g>
            <rect x="14.5" y="8.28571" width="14.5" height="3.10714" rx="1.55357" fill="#E6E6E6" />
            <rect x="14.5" y="13.4643" width="9.32143" height="3.10714" rx="1.55357" fill="#E6E6E6" />
            <rect x="10.3574" y="24.8571" width="18.6429" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="10.3574" y="31.0714" width="18.6429" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="4.14258" y="24.8571" width="4.14286" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="4.14258" y="31.0714" width="4.14286" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="4.14258" y="43.5" width="21.75" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="4.14258" y="49.7143" width="21.75" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="4.14258" y="55.9286" width="21.75" height="4.14286" rx="2.07143" fill="#E6E6E6" />
            <rect x="4.14258" y="8.28571" width="8.28571" height="8.28571" rx="1.56757" fill="#E6E6E6" />
            <defs>
                <filter id="filter0_d_5401_104981" x="30.0074" y="4.14288" width="77.7346" height="33.1989" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.13514" />
                    <feGaussianBlur stdDeviation="1.56757" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5401_104981" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5401_104981" result="shape" />
                </filter>
                <filter id="filter1_d_5401_104981" x="30.0074" y="35.2143" width="77.7346" height="70.4846" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.13514" />
                    <feGaussianBlur stdDeviation="1.56757" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5401_104981" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5401_104981" result="shape" />
                </filter>
                <filter id="filter2_d_5401_104981" x="105.615" y="4.14288" width="38.3772" height="101.556" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.13514" />
                    <feGaussianBlur stdDeviation="1.56757" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0 0.886605 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5401_104981" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5401_104981" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_5401_104981" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(124.286 46.6072) rotate(90) scale(43.5 15.2085)">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" />
                </radialGradient>
            </defs>
        </svg>

    )
}