import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ME } from "src/lib/queries";
import { UPDATE_ONE_USER_EMAIL } from "src/lib/mutations";

export function useUpdateOneUserEmail() {
    const [accessToken] = useLocalStorage("accessToken", undefined);

    const options = accessToken ? {
        context: {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    } : {}

    return useMutation(UPDATE_ONE_USER_EMAIL, { ...options, refetchQueries: [{ query: ME, ...options }], });
}
