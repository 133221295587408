import { ReactNode, CSSProperties } from "react";

interface SectionWrapProps {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

export default function SectionWrap({ children, className, style }: SectionWrapProps) {
    return (
        <section
            className={`w-full border border-[var(--border-default)] bg-[var(--backgrounds-on-canvas)] md:rounded-lg ${className ? className : ''}`}
            style={style}
        >
            {children}
        </section>
    );
}
