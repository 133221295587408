interface LogoPurpleProps {
    className?: string;
}

export default function LogoPurple({ className }: LogoPurpleProps) {
    return (
        <svg className={className} width="124" height="131" viewBox="0 0 124 131" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.1026 123.298C80.7368 119.264 80.6943 106.104 74.0077 93.9053C67.3211 81.7065 56.5224 75.088 49.8882 79.1223C43.254 83.1567 43.2965 96.3163 49.9832 108.515C56.6698 120.714 67.4684 127.333 74.1026 123.298Z" fill="#8178EF" />
            <path d="M34.8408 77.9617C46.4224 70.9187 52.706 59.5445 48.8758 52.5567C45.0455 45.569 32.5518 45.6137 20.9703 52.6567C9.3887 59.6997 3.10503 71.0739 6.93528 78.0616C10.7655 85.0494 23.2593 85.0046 34.8408 77.9617Z" fill="#8178EF" />
            <path d="M74.1104 51.4808C80.7446 47.4464 80.7021 34.2868 74.0155 22.088C67.3289 9.88914 56.5302 3.27057 49.896 7.30496C43.2619 11.3393 43.3044 24.499 49.991 36.6978C56.6776 48.8966 67.4762 55.5152 74.1104 51.4808Z" fill="#8178EF" />
            <path d="M103.03 77.9617C114.612 70.9187 120.896 59.5445 117.065 52.5567C113.235 45.569 100.741 45.6137 89.1597 52.6567C77.5782 59.6997 71.2945 71.0739 75.1247 78.0616C78.955 85.0494 91.4487 85.0046 103.03 77.9617Z" fill="#8178EF" />
        </svg>
    )
}