// Import necessary libraries and components
import React, { ChangeEvent, Fragment, ReactElement, useEffect, useState } from 'react';
import { Popover, Transition } from "@headlessui/react";
import { AddPlus, CheckGoodYes, Search, XCloseDelete } from "react-basicons";
import { useProjectTopics } from "src/api/useProjectTopics";
import { deepEqual, fuzzySearchRegex, stopPropagation, theme } from "src/lib/helpers";
// import Button from "src/components/Button";
import { useDebounce } from "@uidotdev/usehooks";
import { IProjectTopic } from "src/lib/types";
import { useCreateOneProjectTopic } from "src/api/useCreateOneProjectTopic";
import usePrevious from "src/hooks/usePrevious";
import { PROJECT_TOPICS } from "src/lib/queries";
import classNames from "classnames";
import { Check, Plus } from 'src/constants/icons';
import HorizontalDivider from './UI/HorizontalDivider';
import Button from './UI/Button/Button';

// Define the properties for the ProjectTopicsSelect component


interface ProjectTopicsSelectProps {
  workspaceSlug: string;
  projectId?: string;
  value?: string | String[];
  onChange: Function;
  readOnly?: boolean;
  renderButton?: (label: string) => ReactElement;
  multiple?: boolean;
  label?: string;
}

const ProjectTopicsSelect: React.FC<ProjectTopicsSelectProps> = props => {
  const { workspaceSlug, projectId, value, onChange, readOnly = true, renderButton, multiple = true, label } = props;
  const queryVariables = {
    where: {
      workspace: {
        slug: {
          equals: workspaceSlug
        }
      }
    }
  }
  const { loading: projectTopicsLoading, data } = useProjectTopics({
    variables: queryVariables,
  });

  // console.log(data, 'data')

  const [selectedOption, setSelectedOption] = useState<IProjectTopic>();
  const prevSelectedOption = usePrevious(selectedOption);

  const [selectedOptions, setSelectedOptions] = useState<IProjectTopic[]>([]);
  const prevSelectedOptions = usePrevious(selectedOptions);

  const [searchTerm, setSearchTerm] = React.useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const regex = fuzzySearchRegex(debouncedSearchTerm);
  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const searchTermFilter = (topic: IProjectTopic) => {
    if (debouncedSearchTerm.length) {
      return regex.test(topic.name);
    }
    return true;
  };

  const [createOneProjectTopic, { loading: createOneProjectTopicLoading }] = useCreateOneProjectTopic();
  const loading = projectTopicsLoading || createOneProjectTopicLoading;

  // create custom project topic option
  const handleAddProjectTopic = () => {
    if (searchTerm.length) {
      createOneProjectTopic({
        variables: {
          data: {
            name: searchTerm,
            workspace: {
              connect: {
                slug: workspaceSlug
              }
            }
          }
        },
        onCompleted: ({ createOneProjectTopic }) => {
          setSearchTerm('');
          setSelectedOptions([...selectedOptions, createOneProjectTopic]);
          setSelectedOption(createOneProjectTopic);
        },
        update: (cache, { data: { createOneProjectTopic } }) => {
          const { projectTopics }: any = cache.readQuery({ query: PROJECT_TOPICS, variables: queryVariables });
          cache.writeQuery({
            query: PROJECT_TOPICS,
            variables: queryVariables,
            data: {
              projectTopics: [...projectTopics, createOneProjectTopic]
            }
          });
        }
      });
    }
  }

  // watch selected options
  useEffect(() => {
    if (!loading && multiple && prevSelectedOptions && prevSelectedOptions.length !== selectedOptions.length) {
      onChange(selectedOptions.map(({ id }) => id));
    }
  }, [multiple, loading, prevSelectedOptions, selectedOptions]);

  // watch selected option
  useEffect(() => {
    if (!loading && !multiple && selectedOption && prevSelectedOption !== selectedOption) {
      onChange(selectedOption.id);
    }
  }, [multiple, loading, prevSelectedOption, selectedOption]);

  const handleCheckboxChange = (option: IProjectTopic) => {
    if (!multiple) {
      setSelectedOption(option);
    } else if (selectedOptions.findIndex(each => deepEqual(each, option)) >= 0) {
      setSelectedOptions(selectedOptions.filter((item) => !deepEqual(item, option)));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const maxItemCount = 3;

  let button = (
    <div className="flex items-center space-x-1">
      <AddPlus size={12} weight={3} />
      <span className="text-grey-400">Add workstream</span>
    </div>
  );

  const buttonLabel = multiple
    ? selectedOptions
      .slice(0, 3)
      .map(option => option.name).join(', ')
    : selectedOption?.name ?? 'Select workstream';

  if (selectedOptions.length) {
    button = projectId ? (
      <div className="text-grey-900 text-left">
        {buttonLabel}
        {selectedOptions.length > maxItemCount && (
          <>, <span className="text-grey-300">{selectedOptions.length - maxItemCount}+</span></>
        )}
      </div>
    ) : (
      <ul className="flex flex-wrap items-center -my-0.5">
        {selectedOptions.map(option => (
          <li key={option.id} className="pill mr-2 my-0.5">
            <span>{option.name}</span>
            <div className="cursor-pointer" onClick={stopPropagation(() => handleCheckboxChange(option))}>
              <XCloseDelete size={10} color={(theme.textColor.grey as any)['200']} weight={2} />
            </div>
          </li>
        ))}
        {Boolean(projectId) || (
          <li className="my-0.5">
            <AddPlus size={20} color={(theme.textColor.grey as any)['300']} weight={2} />
          </li>
        )}
      </ul>
    );
  }

  if (typeof renderButton === 'function') {
    button = renderButton(buttonLabel)
  }

  const projectTopics = data?.projectTopics ?? [];
  const prevProjectTopics = usePrevious(projectTopics);
  const filteredProjectTopics = projectTopics.filter(searchTermFilter);

  useEffect(() => {
    if (prevProjectTopics?.length !== projectTopics.length && projectTopics.length) {
      if (multiple) {
        setSelectedOptions(
          projectTopics.filter((each: IProjectTopic) => (value ?? []).includes(each.id))
        );
      } else {
        setSelectedOption(
          projectTopics.find((each: IProjectTopic) => value === each.id)
        );
      }
    }
  }, [prevProjectTopics, projectTopics]);

  const noSearchResults = filteredProjectTopics.length === 0 && debouncedSearchTerm.length > 0;
  return (
    <Popover className={`relative ${typeof renderButton === 'function' ? '' : '-mx-2'}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={
              typeof renderButton === 'function' ? '' : `${open ? 'bg-grey-50' : ''} focus:outline-none inline-flex rounded-lg transition hover:bg-grey-50 p-1 px-2`
            }>
            {button}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-10 mt-3 min-w-64 max-w-full space-y-4">
              <div className="drop-group p-1 w-full overflow-y-auto relative z-10 flex flex-col gap-1">
                <div className="sticky top-0 z-10 rounded-t-lg">
                  {readOnly || (
                    <div className="relative">
                      <input
                        autoFocus
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        id="workstream-search"
                        type="text"
                        className="input-clear text-[var(--text-default)] !px-2 w-full text-body-small regular bg-transparent placeholder:text-[var(--text-disabled)]"
                        placeholder={multiple ? 'Add Workstream' : 'Search workstream'}
                        disabled={createOneProjectTopicLoading}
                      />
                      <HorizontalDivider />
                      {multiple && (
                        <div className="absolute right-0 inset-y-0 px-3 grid place-content-center">
                          <Button
                            type="button"
                            size="small"
                            loading={createOneProjectTopicLoading}
                            disabled={createOneProjectTopicLoading}
                            onClick={handleAddProjectTopic} value={''} btnType={'ghost'}                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {noSearchResults || (
                    <div className="py-3 px-4 text-body-micro medium text-[var(--text-disabled)]">
                      {label ?? 'Workstream'}
                    </div>
                  )} */}
                </div>
                <div>
                  {noSearchResults && (
                    <div className="flex flex-col items-center">
                      <p className="text-body-micro medium text-[var(--text-disabled)] text-center p-4">
                        {multiple && (
                          <>
                            No search found for “<span className="font-semibold">{debouncedSearchTerm}</span>”
                          </>
                        )}
                        {multiple || (
                          <>
                            No search found for “<span className="font-semibold">{debouncedSearchTerm}</span>”
                            but you can create a workstream below
                          </>
                        )}
                      </p>
                      {multiple || (
                        <Button
                          type="button"
                          size="small"
                          loading={createOneProjectTopicLoading}
                          disabled={createOneProjectTopicLoading}
                          onClick={handleAddProjectTopic}
                          className="mb-2 w-auto"
                          icon='left'
                          value={`Add workstream`}
                          // value={`Create “${debouncedSearchTerm}” workstream`}
                          btnType={'neutral'}
                        >
                          <Plus />
                        </Button>
                      )}
                    </div>
                  )}
                  <ul>
                    {filteredProjectTopics.map((topic: IProjectTopic) => (
                      <li key={topic.id} className="drop-item flex px-2 items-center justify-between w-full cursor-pointer rounded gap-2.5 h-8 no-snippet" >
                        <label className={
                          classNames("flex items-center w-full space-x-2 py-2", { 'cursor-pointer': !readOnly })
                        }>
                          <div className="flex-1 text-body-small medium text-[var(--text-on-bg)]">
                            {topic.name}
                          </div>
                          {!readOnly && multiple && (
                            <div className="pr-4">
                              <input
                                type="checkbox"
                                disabled={readOnly}
                                className="!rounded"
                                checked={
                                  multiple
                                    ? selectedOptions.some(({ id }: IProjectTopic) => (id === topic.id))
                                    : selectedOption?.id === topic.id
                                }
                                onChange={() => handleCheckboxChange(topic)}
                              />
                            </div>
                          )}
                          {!readOnly && !multiple && (
                            <>
                              {selectedOption?.id === topic.id && (
                                <Check />
                              )}
                              <input
                                type="checkbox"
                                disabled={readOnly}
                                className="sr-only"
                                checked={value === topic.id}
                                onChange={() => handleCheckboxChange(topic)}
                              />
                            </>
                          )}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default ProjectTopicsSelect;
