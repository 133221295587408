import SideNavMenuBtn from "src/components/sideNav/SideNavMenu";
import { Link, useParams } from "react-router-dom";
import { useLogout } from "src/hooks/useLogout";
import { Alert, ArrowBack, Bank, CreditCard, Inbox, Lock, Mute, Plus, Projects, Rocket, Status, ThumbsDown, UserCircle, Users } from "src/constants/icons";

export default function SettingSideNav() {
  const { workspaceSlug } = useParams()
  const logout = useLogout();

  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}`


  return (
    <>
      <Link to={`${WORKSPACE_URL_BASE }`} className="w-full">
        <SideNavMenuBtn type='settings' label={'Back'} icon={<ArrowBack />} />
      </Link>
      <div className="side_nav_group">
        <p className="nav_title text-body-micro bold">Workspace settings</p>
        <div className="menu_btn_group">
          <Link to={`${WORKSPACE_URL_BASE}/settings/general`}><SideNavMenuBtn type='settings' label={'General'} icon={<Bank />} link={`${WORKSPACE_URL_BASE}/settings/general`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/members`}><SideNavMenuBtn type='settings' label={'Members'} icon={<Users />} link={`${WORKSPACE_URL_BASE}/settings/members`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/security`}><SideNavMenuBtn type='settings' label={'Security'} icon={<Lock />} link={`${WORKSPACE_URL_BASE}/settings/security`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/billing`}><SideNavMenuBtn type='settings' label={'Billing'} icon={<CreditCard />} link={`${WORKSPACE_URL_BASE}/settings/billing`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/projects`}><SideNavMenuBtn type='settings' label={'Projects'} icon={<Projects className="w-4 h-4" />} link={`${WORKSPACE_URL_BASE}/settings/projects`} /></Link>
        </div>
      </div>
      <div className="side_nav_group">
        <p className="nav_title text-body-micro bold">Account settings</p>
        <div className="menu_btn_group">
          <Link to={`${WORKSPACE_URL_BASE}/settings/profile`}><SideNavMenuBtn type='settings' label={'My profile'} icon={<UserCircle />} link={`${WORKSPACE_URL_BASE}/settings/profile`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/preference`}><SideNavMenuBtn type='settings' label={'Preference'} icon={<Status />} link={`${WORKSPACE_URL_BASE}/settings/preference`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/notifications`}><SideNavMenuBtn type='settings' label={'Notifications'} icon={<Alert className="w-4 h-4" />} link={`${WORKSPACE_URL_BASE}/settings/notifications`} /></Link>
        </div>
      </div>
      <div className="side_nav_group">
        <p className="nav_title text-body-micro bold">Others & support</p>
        <div className="menu_btn_group">
          <Link to={`/create-workspace`}><SideNavMenuBtn type='settings' label={'Create workspace'} icon={<Plus />} link={`/create-workspace`} /></Link>
          <Link to={`${WORKSPACE_URL_BASE}/settings/what's-new`}><SideNavMenuBtn type='settings' label={'What’s new'} icon={<Rocket />} link={`${WORKSPACE_URL_BASE}/settings/what's-new`} /></Link>
          <a href="mailto:hi@overlap.work"><SideNavMenuBtn type='settings' label={'Feedback'} icon={<Mute />} link={``} /></a>
          <div className="w-full" onClick={logout}>
          <SideNavMenuBtn type='settings' label={'Log out'} icon={<ThumbsDown />} link={`${WORKSPACE_URL_BASE}/settings/log-out`} />
          </div>
        </div>
      </div>
    </>
  )
}