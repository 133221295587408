import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import EditProject from "../components/EditProject";
import { useMe } from "../api/auth/useMe";
import { IProject, IUserWorkspace, IWorkspace } from "../lib/types";
import SectionWrap from "src/components/UI/SectionWrap";
import Button from "src/components/UI/Button/Button";
import { Plus } from "src/constants/icons";
import { useTheme } from "src/context/ThemeContext";
import WorkspaceMenu from "src/components/WorkspaceMenu";
import IconButton from "src/components/UI/Button/IconButton";
import Media from "src/components/Media";
import MobileWorkSpaceMenu from "src/components/UI/MobileWorkSpaceMenu";
import { useProjectContext } from "src/context/ProjectContext";
import PageLoader from "src/components/UI/Loader/PageLoader";
import useMobile from "src/hooks/useMobile";
import SideNavMenuBtn from "src/components/sideNav/SideNavMenu";
import { getColorTag } from "src/lib/helpers";
import { colorStyles } from "src/lib/ColorPreset";
import SideNavMobile from "src/components/sideNav/SideNavMobile";

const getCurrentTheme = (
  theme: "light" | "dark" | "system"
): "light" | "dark" => {
  if (theme === "system") {
    const prefersDarkScheme = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return prefersDarkScheme ? "dark" : "light";
  }
  return theme;
};

function WorkspacePage() {
  const isMobile = useMobile();
  const { projects, userData } = useProjectContext();

  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

  const [createProject, setCreateProject] = useState(false);
  // const { loading, data } = useMe();
  const userWorkspaces = userData?.me?.userWorkspaces;
  const userWorkspace = userWorkspaces?.find(
    ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
  );

  useEffect(() => {
    if (projects.length > 0) {
      navigate(`/workspace/${workspaceSlug}/project/${isMobile ? '' : projects[0].id}`);
    } else {
      navigate(`/workspace/${workspaceSlug}/project/`);
    }
  }, [projects, workspaceSlug, navigate]);

  const { theme } = useTheme();
  const currentTheme = getCurrentTheme(theme);

  const [illustrationSrc, setIllustrationSrc] = useState<string>(
    currentTheme === "dark"
      ? "/images/Illustration2.svg"
      : "/images/Illustration.svg"
  );

  useEffect(() => {
    const workspaces = userData?.me?.userWorkspaces.map(
      ({ workspace }: IUserWorkspace) => workspace
    );
    const workspace = workspaces?.find(
      (each: IWorkspace) => each.slug === workspaceSlug
    );
    // if (workspace?.projects.length) {
    //   const firstProject = workspace?.projects[0];
    //   navigate(`/workspace/${workspace.slug}/project/${firstProject.id}`);
    // }
  }, [userData, workspaceSlug]);

  useEffect(() => {
    setIllustrationSrc(
      currentTheme === "dark"
        ? "/images/Illustration2.svg"
        : "/images/Illustration.svg"
    );
  }, [currentTheme]);


  // if (loading) return <PageLoader snippet="Loading user data" />;

  return (
    <>
      <SectionWrap className="flex items-center justify-center">
        <div className="page-title z-10 flex items-center justify-between md:hidden h-14">
          <MobileWorkSpaceMenu
            userWorkspace={userWorkspace}
            userWorkspaces={userWorkspaces}
            user={userData.me}
          />
          <p className="tet-body-small bold text-[var(--text-neutral)]">
            Projects
          </p>
          <IconButton icon={<Plus />} size={"small"} type={"ghost"} onClick={() => setCreateProject(true)} />
        </div>
        {Boolean(isMobile && projects.length) ?
          <div className='h-full flex flex-col gap-2 w-full pt-24 px-4'>
            {projects.map((project: IProject, key) => (
              <Link key={project.id} to={`${WORKSPACE_URL_BASE}/${project.id}`}>
                <SideNavMenuBtn
                  avatarColor={colorStyles[getColorTag(project.name)]}
                  sideAvatar={project.name}
                  type='home'
                  label={project.name}
                  link={`${WORKSPACE_URL_BASE}/${project.id}`}
                  showBadge={false}
                />
              </Link>
            ))}
            {/* <Button value={'New project'} size={'medium'} btnType={'ghost'} icon='left' className='justify-end' onClick={secNavFunc}>
                    <Plus />
                </Button> */}
          </div>
          :
          <div className="flex flex-col items-start gap-6 max-w-[410px] px-4">
            <img
              src={illustrationSrc}
              className="w-full max-w-[73px] md:max-w-[145px] h-auto"
              alt="overlap Projects page"
            />
            <div className="w-full">
              <h2 className="text-[var(--text-default)] text-heading-6 medium md:text-heading-5">
                Projects
              </h2>
              <p className="text-[var(--text-neutral)] mb-6 text-body-mini regular md:text-body-small">
                A project can be defined as a discrete set of tasks or activities
                aimed at achieving a specific goal or objective within the
                software platform.
                <br />
                <br />A project can be defined as a discrete set of tasks or
                activities aimed at achieving a specific goal or objective within
                the software platform.
              </p>
              <Button
                onClick={() => setCreateProject(true)}
                value={"Create a project"}
                size={"medium"}
                btnType={"primary"}
                icon="left"
                className="w-auto"
              >
                <Plus />
              </Button>
            </div>
          </div>
        }
      </SectionWrap>

      <EditProject
        show={createProject}
        handleClose={() => setCreateProject(false)}
      />
      <SideNavMobile />
    </>
  );
}

export default WorkspacePage;
