import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Validator from "validatorjs";
// import Button from "src/components/Button";
import {
  XCloseDelete
} from "react-basicons";
import ValidationErrorMessages from "src/components/ValidationErrorMessages";
import { useUpdateOnePost } from "src/api/post/useUpdateOnePost";
import { useSingleUpload } from "src/api/useSingleUpload";
import { IMedia, IPost, PostType, ImageData, IUser } from "src/lib/types";
import usePrevious from "src/hooks/usePrevious";
import { deepEqual, deleteTypeNameKey, theme, transformToTitleCase } from "src/lib/helpers";
import { useCreateOnePost } from "src/api/post/useCreateOnePost";
import ProjectTopicsSelect from "src/components/ProjectTopicsSelect";
import PostTypeSelect from "src/components/PostTypeSelect";
import BalloonEditor from 'ckeditor5-custom-build';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useParams } from 'react-router-dom';
import toast from "react-hot-toast";
import ToastMessage from "./ToastMessage";
import { MAX_FILE_UPLOAD_SIZE } from "../lib/consts";
import { AnimatePresence, motion } from 'framer-motion';
import HeaderText from './UI/HeaderTexts';
import { Attachment, ChevronDown, Close, Plus, WorkStream } from 'src/constants/icons';
import HorizontalDivider from './UI/HorizontalDivider';
import IconButton from './UI/Button/IconButton';
import StatusTag from './UI/Tags/StatusTags';
import Button from './UI/Button/Button';
import { renderToStaticMarkup } from 'react-dom/server'
import Avatar from "./Avatar";

type FormData = {
  title: string;
  comment: string;
  topicId?: string;
  type: PostType;
}

type MentionFeed = {
  id: string;
  userId: string;
  avatar?: IMedia;
  name: string;
  position: string;
};

function EditPost({ show, handleClose, post, workspaceSlug, collaborators = [] }: {
  show: boolean;
  handleClose: Function;
  post?: IPost;
  workspaceSlug: string;
  collaborators?: IUser[];
}) {
  const prevShow = usePrevious(show);
  const prevPost = usePrevious(post);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { projectId } = useParams<{ projectId: string; }>();
  const [mentionedUserIDs, setMentionedUserIDs] = useState<any[]>([]);
  const initialFormData = {
    title: post?.title ?? '',
    comment: post?.comment ?? '',
    topicId: post?.topic?.id,
    type: post?.type ?? PostType.GENERAL,
  };
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] = useState<Validator.ValidationErrors | null>(null);
  const [createOnePost, { loading: createOnePostLoading }] = useCreateOnePost();
  const [updateOnePost, { loading: updateOnePostLoading }] = useUpdateOnePost();
  const [attachments, setAttachments] = useState<Array<IMedia>>([]);
  const [images, setImages] = useState<ImageData[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [singleUpload, { loading: singleUploadLoading }] = useSingleUpload();
  const loading = createOnePostLoading || singleUploadLoading || updateOnePostLoading;

  // set focus on the title input field
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 300)
    }
  }, [show]);

  useEffect(() => {
    if (prevShow !== show && !show) {
      setImages([]);
      setAttachments([]);
      setFormData(initialFormData);
    }
  }, [show, prevShow]);

  useEffect(() => {
    // if (post && !deepEqual(prevPost, post)) {
    if (post) {
      setFormData({
        title: post.title,
        comment: post.comment,
        topicId: post.topic?.id,
        type: post.type ?? PostType.GENERAL,
      });
      setAttachments(post.attachments.map(deleteTypeNameKey));
      setMentionedUserIDs(post.mentionedUserIDs);
    }
  }, [post, prevPost]);

  // ==================

  const handleInputChange = (field: string) =>
    (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;
      console.log(e, 'e')
      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    }

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const maxImageCount = 4 - attachments.length;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files).slice(0, maxImageCount - images.length);
  
      const oversizedFiles: string[] = [];
  
      const imagePromises = files.map((file: File) => {
        if (file.size > MAX_FILE_UPLOAD_SIZE.bytes) {
          oversizedFiles.push(file.name);
          return Promise.resolve(null); // Skip this file
        }
  
        return new Promise<ImageData>((resolve, reject) => {
          const reader = new FileReader();
  
          reader.onload = (e) => {
            if (e.target) {
              resolve({ file, dataURL: e.target.result as string });
            } else {
              reject(new Error('Error reading image file: target is null.'));
            }
          };
  
          reader.onerror = (error) => {
            reject(error);
          };
  
          reader.readAsDataURL(file);
        });
      });
  
      Promise.all(imagePromises)
        .then((imageDataArray: (ImageData | null)[]) => {
          const validImages = imageDataArray.filter((imageData) => imageData !== null) as ImageData[];
          const newImages = validImages.map((imageData: ImageData) => ({
            file: imageData.file,
            dataURL: imageData.dataURL,
          }));
          setImages((prevImages) => [...prevImages, ...newImages]);
  
          if (oversizedFiles.length > 0) {
            toast(t => (
              <ToastMessage
                id={t.id}
                title={`Cannot upload files more than ${MAX_FILE_UPLOAD_SIZE.humanReadable}. Images [${oversizedFiles.join(', ')}] will be skipped.`}
              />
            ));
          }
  
          // Reset the file input value
          e.target.value = '';
        })
        .catch((error) => {
          console.error('Error reading image files:', error);
  
          // Reset the file input value
          e.target.value = '';
        });
    }
  };
  

  const uploadFiles = async (files: Blob[]) => {
    const uploadPromises = files.map(async file => {
      const { data } = await singleUpload({ variables: { file } });
      return deleteTypeNameKey(data.singleUpload);
    });
    return await Promise.all(uploadPromises);
  }

  const handleSubmit = async () => {
    const validation = new Validator(formData, {
      // title: 'required',
      comment: 'required',
    });

    if (validation.passes()) {
      setFormErrors(null);

      const imageFiles = images.map(({ file }: ImageData) => file);
      const uploadedImages = await uploadFiles(imageFiles);

      if (post) {
        updateOnePost({
          variables: {
            data: {
              title: { set: formData.title },
              comment: { set: formData.comment },
              topic: formData.topicId ? {
                connect: { id: formData.topicId }
              } : undefined,
              type: { set: formData.type },
              attachments: [...attachments, ...uploadedImages],
              mentionedUsers: {
                set: mentionedUserIDs.map(id => ({ id }))
              }
            },
            where: { id: post.id }
          },
          onCompleted: () => {
            toast(t => (
              <ToastMessage
                id={t.id}
                // icon={<TrashDeleteBin size={16} weight={3} />}
                title="Post edited successfully."
              // handleUndo={() => console.log('undoing...')}
              >
              </ToastMessage>
            ));
            handleClose()
          },
        })
      } else {
        createOnePost({
          variables: {
            data: {
              title: formData.title,
              comment: formData.comment,
              topic: formData.topicId ? {
                connect: { id: formData.topicId }
              } : undefined,
              type: formData.type,
              project: {
                connect: { id: projectId }
              },
              attachments: uploadedImages,
              mentionedUsers: {
                connect: mentionedUserIDs.map(id => ({ id }))
              }
            }
          },
          onCompleted: () => {
            handleClose();
            toast(t => (
              <ToastMessage
                id={t.id}
                // icon={<TrashDeleteBin size={16} weight={3} />}
                title="Post created successfully."
              // handleUndo={() => console.log('undoing...')}
              >
              </ToastMessage>
            ));
            setFormData(initialFormData);
          }
        });
      }

    } else {
      setFormErrors(validation.errors.all());
    }
  }

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleRemoveAttachment = (index: number) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  const title = post ? 'Edit post' : 'What would you like to share?';
  const button = post ? 'Save' : 'Post';

  const mentionFeed: MentionFeed[] = collaborators.map(collaborator => ({
    id: `@${collaborator.detail.fullName}`,
    userId: collaborator.id,
    name: collaborator.detail.fullName,
    position: transformToTitleCase(collaborator.detail.position),
    avatar: collaborator.detail.avatar,
  }));

  const customItemRenderer = (item: MentionFeed) => {
    const itemElement = document.createElement('span');

    itemElement.innerHTML = renderToStaticMarkup(
      <div className="flex space-x-2">
        <Avatar alt={item.name} avatar={item.avatar} size={28} />
        <div className="">
          <div className="!font-medium !text-base">{item.name}</div>
          <div className="!text-sm !text-gray-500">{item.position}</div>
        </div>
      </div>
    );

    return itemElement;
  }

  const editorConfig: any = {
    placeholder: 'Type your update here...',
    mention: {
      feeds: [
        {
          marker: '@',
          feed: mentionFeed,
          itemRenderer: customItemRenderer,
          minimumCharacters: 1
        }
      ]
    }
  };

  const handleEditorChange = (event: any, editor: any) => {
    handleInputChange('comment')(editor.getData());

    const documentRoot = editor.model.document.getRoot();

    if (documentRoot) {
      const range = editor.model.createRangeIn(documentRoot);
      const mentions = [];

      //iterate through the whole tree in that range (TreeWalker)
      for (const treeWalker of range.getWalker({ ignoreElementEnd: true }))
        if (treeWalker.type === 'text') {
          // the item property represents TextProxy which is not instance of node
          const node = (treeWalker.item as any).textNode;

          if (node.hasAttribute('mention')) {
            const mention: MentionFeed = node.getAttribute('mention');
            if (mention && mention.userId) {
              mentions.push(mention.userId)
            }
          }
        }

      setMentionedUserIDs(mentions);
    }
  }

  // =============================

  return (
    <>
      <AnimatePresence>
        {show && (<motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-10 inset-0 bg-black/30"
        >
          <div className='fixed inset-0 flex w-screen items-start mt-16 justify-center p-4'>
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className='group-container border rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]'
            >
              <div className="flex items-center justify-between w-full p-5">
                <HeaderText subTitle={title} />
                <IconButton icon={<Close />} size={'micro'} type={'ghost'} onClick={() => handleClose()} />
              </div>
              <HorizontalDivider />
              <div className="p-5 max-h-[500px] overflow-y-auto">
                <input
                  type="text"
                  ref={inputRef}
                  disabled={loading}
                  placeholder="Title here"
                  value={formData.title}
                  onChange={handleInputChange('title')}
                  className="input-clear bg-transparent text-heading-4 medium text-[var(--text-default)] !border-[var(--backgrounds-dropdowns)] !outline-[var(--backgrounds-dropdowns)] !focus-visible:outline-none !outline-none w-full text-2xl placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 placeholder:medium"
                />
                <ValidationErrorMessages name='title' errors={formErrors} />

                <CKEditor
                  editor={BalloonEditor as any}
                  data={formData.comment}
                  onChange={handleEditorChange}
                  config={editorConfig}
                />
                <ValidationErrorMessages name='comment' errors={formErrors} />
                {(images.length > 0 || attachments.length > 0) && (
                  <ul className="flex items-center space-x-2 mt-6">
                    {attachments.map((media, index) => (
                      <li key={index} className="relative flex-1">
                        <img
                          className="aspect-square bg-grey-100 rounded-lg object-cover"
                          src={[process.env.REACT_APP_API_URL, media.url].join('/')}
                          alt={media.filename}
                        />
                        <button
                          type="button"
                          disabled={loading}
                          className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                          onClick={() => handleRemoveAttachment(index)}
                        >
                          <XCloseDelete size={8} color={theme.colors.white} weight={3} />
                        </button>
                      </li>
                    ))}
                    {images.map((image, index) => (
                      <li key={index} className="relative flex-1">
                        <img
                          src={image.dataURL}
                          alt={`Image ${index}`}
                          className="aspect-square bg-grey-100 rounded-lg object-cover"
                        />
                        <button
                          type="button"
                          disabled={loading}
                          className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <XCloseDelete size={8} color={theme.colors.white} weight={3} />
                        </button>
                      </li>
                    ))}
                    {images.length < maxImageCount && (
                      <li>
                        <IconButton icon={<Plus />} size={'standard'} type={'neutral'} onClick={handleFileClick} />
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <HorizontalDivider />
              <div className='p-5'>
                <div className="flex gap-4 flex-col md:flex-row items-start md:justify-between md:items-center">
                  <ul className="flex items-start md:items-center flex-col md:flex-row flex-wrap gap-3">
                    <li>
                      <ProjectTopicsSelect
                        workspaceSlug={workspaceSlug}
                        value={formData.topicId}
                        onChange={handleInputChange('topicId')}
                        readOnly={false}
                        multiple={false}
                        label=""
                        // label="What workstream is this update for?"
                        renderButton={label => (
                          <StatusTag leftIcon={<WorkStream className='w-4 h-4' />} rightIcon={<ChevronDown className='w-4 h-4' />} color={'default'} size={'large'} value={label} />
                        )}
                      />
                    </li>
                    <li>
                      <PostTypeSelect
                        value={formData.type}
                        onChange={handleInputChange('type')}
                      />
                    </li>
                  </ul>
                  <div className="ml-auto gap-3 items-center flex">
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      multiple
                      className="sr-only"
                      onChange={handleFileChange}
                    />
                    <div>
                      <IconButton icon={<Attachment />} size={'standard'} type={'neutral'} onClick={handleFileClick} />
                    </div>
                    <Button
                      type="button"
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      value={button}
                      size={'medium'}
                      btnType={'primary'} />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
        )}
      </AnimatePresence>
      {/* <Modal
        as={'form'}
        title="What’s the update?"
        show={show}
        handleClose={handleClose}
        footer={(
          <div className="flex justify-between items-center">
            <ul className="max-w-80 flex flex-wrap space-x-2">
              <li>
                <ProjectTopicsSelect
                  workspaceSlug={workspaceSlug}
                  // projectId={projectId}
                  value={formData.topicId}
                  onChange={handleInputChange('topicId')}
                  readOnly={false}
                  multiple={false}
                  label="What workstream is this update for?"
                  renderButton={label => (
                    <div className="pill">
                      <HashtagTrendingNumber size={12} />
                      <span>{label}</span>
                      <ArrowDropdownCaretSortSelectArrow size={16} color={(theme.textColor.grey as any)['300']} />
                    </div>
                  )}
                />
              </li>
              <li>
                <PostTypeSelect
                  value={formData.type}
                  onChange={handleInputChange('type')}
                />
              </li>
            </ul>
            <div className="space-x-2 flex">
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                multiple
                className="sr-only"
                onChange={handleFileChange}
              />
              <Button
                type="button"
                disabled={loading}
                className="aspect-square !px-2"
                onClick={handleFileClick} value={''} size={'small'} btnType={'primary'}              >
                <AttachmentClipHook size={18} color={(theme.textColor.grey as any)['300']} weight={2} />
              </Button>
              <Button
                type="button"
                onClick={handleSubmit}
                loading={loading}
                disabled={loading} value={''} size={'small'} btnType={'primary'}              >
                <span>{post ? 'Save' : 'Post'}</span>
                <ArrowTopRight size={14} color="white" weight={3} />
              </Button>
            </div>
          </div>
        )}
      >
        <div className="space-y-4">
          <div>
            <input
              type="text"
              ref={inputRef}
              disabled={loading}
              placeholder="Title here"
              value={formData.title}
              onChange={handleInputChange('title')}
              className="input-clear w-full font-graphik text-2xl placeholder:text-grey-200 text-grey-900"
            />
            <ValidationErrorMessages name='title' errors={formErrors} />

            <CKEditor
              editor={BalloonEditor as any}
              data={formData.comment}
              onChange={(event, editor) => handleInputChange('comment')(editor.getData())}
              config={{ placeholder: 'Type your update here...' }}
            />
            <ValidationErrorMessages name='comment' errors={formErrors} />
          </div>

          {(images.length > 0 || attachments.length > 0) && (
            <ul className="space-x-4 flex items-center">
              {attachments.map((media, index) => (
                <li key={index} className="relative">
                  <img
                    className="w-24 aspect-square rounded-xl object-cover"
                    src={[process.env.REACT_APP_API_URL, media.url].join('/')}
                    alt={media.filename}
                  />
                  <button
                    type="button"
                    disabled={loading}
                    className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    <XCloseDelete size={8} color={theme.colors.white} weight={3} />
                  </button>
                </li>
              ))}
              {images.map((image, index) => (
                <li key={index} className="relative">
                  <img
                    src={image.dataURL}
                    alt={`Image ${index}`}
                    className="w-24 aspect-square rounded-xl object-cover"
                  />
                  <button
                    type="button"
                    disabled={loading}
                    className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <XCloseDelete size={8} color={theme.colors.white} weight={3} />
                  </button>
                </li>
              ))}
              {images.length < maxImageCount && (
                <li>
                  <Button
                    type="button"
                    size="small"
                    disabled={loading}
                    onClick={handleFileClick}
                    className="aspect-square" value={''} btnType={'primary'}                  >
                    <AddPlus size={18} color={(theme.textColor.grey as any)['300']} weight={2} />
                  </Button>
                </li>
              )}
            </ul>
          )}
        </div>
      </Modal> */}
    </>
  );
}

export default EditPost;





{/* <AnimatePresence>
        {show && (<motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-10 inset-0 bg-black/30"
        >
          <div className='fixed inset-0 flex w-screen items-start mt-16 justify-center p-4'>
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className='group-container border rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]'
            >
              <div className="flex items-center justify-between w-full p-5">
                <HeaderText title='What’s the update?' />
                <IconButton icon={<Close />} size={'micro'} type={'ghost'} onClick={() => handleClose()} />
              </div>
              <HorizontalDivider />
              <div className="p-5">
                <input
                  type="text"
                  ref={inputRef}
                  disabled={loading}
                  placeholder="Title here"
                  value={formData.title}
                  onChange={handleInputChange('title')}
                  className="input-clear bg-transparent text-heading-4 medium text-[var(--text-default)] !border-[var(--backgrounds-dropdowns)] !outline-[var(--backgrounds-dropdowns)] !focus-visible:outline-none !outline-none w-full text-2xl placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 placeholder:medium"
                />
                <ValidationErrorMessages name='title' errors={formErrors} />

                <CKEditor
                  editor={BalloonEditor as any}
                  data={formData.comment}
                  onChange={(event, editor) => handleInputChange('comment')(editor.getData())}
                  config={{ placeholder: 'Type your update here...' }}
                />
                <ValidationErrorMessages name='comment' errors={formErrors} />
                {(images.length > 0 || attachments.length > 0) && (
                  <ul className="flex gap-2 items-center mt-6">
                    {attachments.map((media, index) => (
                      <li key={index} className="relative flex items-center justify-center h-[116px] rounded overflow-hidden">
                        <img
                          className="object-cover w-full h-full"
                          src={[process.env.REACT_APP_API_URL, media.url].join('/')}
                          alt={media.filename}
                        />
                        <button
                          type="button"
                          disabled={loading}
                          className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                          onClick={() => handleRemoveAttachment(index)}
                        >
                          <XCloseDelete size={8} color={theme.colors.white} weight={3} />
                        </button>
                      </li>
                    ))}
                    {images.map((image, index) => (
                      <li key={index} className="relative flex items-center justify-center h-[116px] rounded overflow-hidden">
                        <img
                          src={image.dataURL}
                          alt={`Image ${index}`}
                          className="object-cover w-full h-full"
                        />
                        <button
                          type="button"
                          disabled={loading}
                          className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <XCloseDelete size={8} color={theme.colors.white} weight={3} />
                        </button>
                      </li>
                    ))}
                    {images.length < maxImageCount && (
                      <li>
                        <IconButton icon={<Plus />} size={'standard'} type={'neutral'} onClick={handleFileClick} />
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <HorizontalDivider />
              <div className='p-5'>
                <div className="flex justify-between items-center">
                  <div className="space-x-2 items-center flex justify-end">
                    <Button
                      type="button"
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      value={'Post'}
                      size={'medium'}
                      btnType={'neutral'} />
                    <Button
                      type="button"
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading}
                      value={'Post'}
                      size={'medium'}
                      btnType={'primary'} />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
        )}
      </AnimatePresence> */}
