import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import EditProject from "../components/EditProject";
import { useMe } from "../api/auth/useMe";
import { IProject, IUserWorkspace, IWorkspace } from "../lib/types";
import SectionWrap from "src/components/UI/SectionWrap";
import Button from "src/components/UI/Button/Button";
import { Plus } from "src/constants/icons";
import { useTheme } from "src/context/ThemeContext";
import WorkspaceMenu from "src/components/WorkspaceMenu";
import IconButton from "src/components/UI/Button/IconButton";
import Media from "src/components/Media";
import MobileWorkSpaceMenu from "src/components/UI/MobileWorkSpaceMenu";
import { useProjectContext } from "src/context/ProjectContext";
import PageLoader from "src/components/UI/Loader/PageLoader";
import useMobile from "src/hooks/useMobile";
import SideNavMenuBtn from "src/components/sideNav/SideNavMenu";
import { getColorTag } from "src/lib/helpers";
import { colorStyles } from "src/lib/ColorPreset";

const getCurrentTheme = (
    theme: "light" | "dark" | "system"
): "light" | "dark" => {
    if (theme === "system") {
        const prefersDarkScheme = window.matchMedia(
            "(prefers-color-scheme: dark)"
        ).matches;
        return prefersDarkScheme ? "dark" : "light";
    }
    return theme;
};

function MobileHome() {
    const isMobile = useMobile();
    const { projects } = useProjectContext();

    const navigate = useNavigate();
    const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
    const [createProject, setCreateProject] = useState(false);
    const { loading, data } = useMe();
    const userWorkspaces = data?.me?.userWorkspaces;
    const userWorkspace = userWorkspaces?.find(
        ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
    );

    const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

    useEffect(() => {
        if (projects.length > 0) {
            navigate(`/workspace/${workspaceSlug}/project/${isMobile ? 'all' : projects[0].id}`);
        } else {
            navigate(`/workspace/${workspaceSlug}/project/`);
        }
    }, [projects, workspaceSlug, navigate]);

    useEffect(() => {
        const workspaces = data?.me?.userWorkspaces.map(
            ({ workspace }: IUserWorkspace) => workspace
        );
        const workspace = workspaces?.find(
            (each: IWorkspace) => each.slug === workspaceSlug
        );
    }, [data, workspaceSlug]);

    if (loading) return <PageLoader snippet="Loading user projects" />;

    return (
        <>
            <SectionWrap className="">
                <div className="page-title z-1 flex items-center justify-between md:hidden">
                    <MobileWorkSpaceMenu
                        userWorkspace={userWorkspace}
                        userWorkspaces={userWorkspaces}
                        user={data.me}
                    />
                    <p className="tet-body-small bold text-[var(--text-neutral)]">
                        Projects
                    </p>
                    <IconButton icon={<Plus />} size={"small"} type={"ghost"} onClick={() => setCreateProject(true)} />
                </div>
                <div className='flex flex-col gap-2 w-full mt-20 pt-1.5 px-4'>
                { projects.map((project: IProject, key) => (
                    <Link key={project.id} to={`${WORKSPACE_URL_BASE}/${project.id}`}>
                        <SideNavMenuBtn
                            avatarColor={colorStyles[getColorTag(project.name)]}
                            sideAvatar={project.name}
                            type='home'
                            label={project.name}
                            link={`${WORKSPACE_URL_BASE}/${project.id}`}
                            showBadge={false}
                        />
                    </Link>
                ))}
                {/* <Button value={'New project'} size={'medium'} btnType={'ghost'} icon='left' className='justify-end' onClick={secNavFunc}>
                    <Plus />
                </Button> */}
            </div>
            </SectionWrap>

            <EditProject
                show={createProject}
                handleClose={() => setCreateProject(false)}
            />
        </>
    );
}

export default MobileHome;
