import React from "react";
import HeaderText from "src/components/UI/HeaderTexts";
import HorizontalDivider from "src/components/UI/HorizontalDivider";
import InfoSwitchCard from "src/components/UI/InfoSwitchCard";
import { Alert, Inbox } from "src/constants/icons";
import { useMe } from "../../../api/auth/useMe";
import { useUpsertOneUserNotificationSettings } from "../../../api/user/useUpsertOneUserNotificationSettings";
import { useLocalStorage } from "@uidotdev/usehooks";
import PageLoader from "src/components/UI/Loader/PageLoader";

interface NotificationDataItem {
    icon?: JSX.Element;
    subtitle: string;
    snippet: string;
    key: string;
}

interface NotificationGroup {
    title: string;
    data: NotificationDataItem[];
}

export default function Notification() {
    const { data, loading } = useMe();
    const [userId] = useLocalStorage("userId", undefined);
    const [upsertOneUserNotificationSettings] = useUpsertOneUserNotificationSettings();

    if (loading) return <PageLoader snippet="Loading your notifications settings" />;

    const { notificationSettings } = data.me;

    const notificationData: NotificationGroup[] = [
        {
            title: 'Notification channels',
            data: [
                {
                    icon: <Inbox className="h-6 w-6" />,
                    subtitle: 'Email address',
                    snippet: 'Receive notifications via your email address',
                    key: 'enableEmailNotification',
                },
                {
                    icon: <Alert className="h-6 w-6" />,
                    subtitle: 'Push notifications',
                    snippet: 'Receive notifications via push notifications',
                    key: 'enablePushNotification',
                },
            ]
        },
        {
            title: 'Projects',
            data: [
                {
                    subtitle: 'Added to a project',
                    snippet: 'Get notified when someone adds you as a collaborator to a project',
                    key: 'addedToProject',
                },
                {
                    subtitle: 'Project status is updated',
                    snippet: 'Get notified when the status of a project is updated',
                    key: 'updatedProjectStatus',
                },
                {
                    subtitle: 'Project deleted',
                    snippet: 'Get notified when a project you’re collaborating on is deleted',
                    key: 'deletedProject',
                },
                {
                    subtitle: 'Project deleted permanently',
                    snippet: 'Get notified when a project you’re collaborating on is deleted permanently',
                    key: 'deletedProjectPermanently',
                },
            ]
        },
        {
            title: 'Posts',
            data: [
                {
                    subtitle: 'New post in a project',
                    snippet: 'Get notified when someone shares a post to a project you’re following',
                    key: 'newProjectPost',
                },
                {
                    subtitle: 'Mentions',
                    snippet: 'Get notified when someone mentions you in a post or comment',
                    key: 'newPostOrCommentMention',
                },
                {
                    subtitle: 'Comments',
                    snippet: 'Get notified when someone comments on a post you’ve been mentioned in',
                    key: 'newCommentInMentionedPost',
                },
                {
                    subtitle: 'Replies',
                    snippet: 'Get notified when someone adds a reply to a comment on your post',
                    key: 'newPostCommentReplies',
                },
            ]
        },
        {
            title: 'Workspace',
            data: [
                {
                    subtitle: 'Workspace deactivated',
                    snippet: 'Get notified when your workspace is deactivated',
                    key: 'deactivatedWorkspace',
                },
            ]
        },
    ];

    const handleSwitchToggle = async (type: string, value: boolean) => {
        await upsertOneUserNotificationSettings({
            variables: {
                create: {
                    user: {
                        connect: { id: userId }
                    },
                    [type]: value
                },
                update: {
                    [type]: { set: value },
                },
                where: { id: userId }
            },
        });
    };

    const isDisabled = !notificationSettings.enableEmailNotification && !notificationSettings.enablePushNotification;

    return (
        <div className="workspace-page flex flex-col gap-10">
            <div className="header_div">
                <HeaderText title="Notifications" />
                <HorizontalDivider />
            </div>
            {notificationData.map((notificationGroup, index) => (
                <>
                    <div className="flex flex-col gap-8 items-start" key={notificationGroup.title + index}>
                        <p className="notification-title text-body-large medium">{notificationGroup.title}</p>
                        <div className={`flex flex-col items-start w-full ${notificationGroup.title === 'Notification channels' ? 'gap-8' : 'gap-6'}`}>
                            {notificationGroup.data.map((data, index) => (
                                <InfoSwitchCard
                                    key={data.key + index}
                                    type={data.key}
                                    checked={notificationSettings ? notificationSettings[data.key] : false}
                                    title={data.subtitle}
                                    snippet={data.snippet}
                                    svg={data.icon}
                                    onSwitch={handleSwitchToggle}
                                    disabled={isDisabled && (data.key !== 'enableEmailNotification' && data.key !== 'enablePushNotification')}
                                />
                            ))}
                        </div>
                    </div>
                    {index < 3 && <HorizontalDivider key={index} />}
                </>
            ))}
        </div>
    );
}
