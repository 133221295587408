import React, { useState, Fragment, ChangeEvent, FormEvent } from "react";
import NameAvatar from "src/components/UI/Avatar/NameAvatar";
import Button from "src/components/UI/Button/Button";
import IconButton from "src/components/UI/Button/IconButton";
import { DropItem } from "src/components/UI/DropGroup";
import HeaderText from "src/components/UI/HeaderTexts";
import HorizontalDivider from "src/components/UI/HorizontalDivider";
import Input from "src/components/UI/Inputs/Default";
import { PopOverItem } from "src/components/UI/Tables/PopOver";
import Tabs, { Tab } from "src/components/UI/Tables/Tab";
import TableLayout from "src/components/UI/Tables/TableLayout";
import SwitchToggle from "src/components/UI/ToggleSwitch";
import { ChevronDown, Issues, ThreeDots, Trash, Triangle, Plus } from "src/constants/icons";
import { useWorkspace } from "src/api/workspace/useWorkspace";
import { useParams } from "react-router-dom";
import { IInvitation, IUserWorkspace, IWorkspace, UserWorkspaceRole } from "../../../lib/types";
import { transformToTitleCase } from "../../../lib/helpers";
import { useUpdateOneWorkspaceMemberRole } from "../../../api/workspace/useUpdateOneWorkspaceMemberRole";
import { useUpdateOneWorkspaceInvitation } from "../../../api/workspace/useUpdateOneWorkspaceInvitation";
import { useDeactivateOneWorkspaceMember } from "../../../api/workspace/useDeactivateOneWorkspaceMember";
import { useReactivateOneWorkspaceMember } from "../../../api/workspace/useReactivateOneWorkspaceMember";
import { useDeleteOneWorkspaceInvitation } from "../../../api/workspace/useDeleteOneWorkspaceInvitation";
import { WORKSPACE } from "../../../lib/queries";
import { Menu, Transition } from "@headlessui/react";
import { useUpdateOneWorkspace } from "../../../api/workspace/useUpdateOneWorkspace";
import { useToast } from "../../../context/ToastContext";
import Validator from "validatorjs";
import ValidationErrorMessages from "../../../components/ValidationErrorMessages";
import { useInviteTeamToOneWorkspace } from "../../../api/useInviteTeamToOneWorkspace";

enum TabType {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
  DEACTIVATED = "DEACTIVATED",
}

type EmailRole = {
  email: string;
  role: UserWorkspaceRole;
}

type FormData = {
  emailRoles: Array<EmailRole>;
}

const Members = () => {
  const {workspaceSlug} = useParams<{ projectId: string; workspaceSlug: string }>();
  const queryVariables = {
    where: {slug: workspaceSlug}
  };
  const {loading: workspaceLoading, data: workspaceData} = useWorkspace({
    variables: queryVariables
  });
  const [inviteTeamToOneWorkspace, {
    loading: inviteTeamToOneWorkspaceLoading,
    error: inviteTeamToOneWorkspaceError
  }] = useInviteTeamToOneWorkspace();

  const initialEmailRoles = [{email: '', role: UserWorkspaceRole.ADMIN}];
  const [formData, setFormData] = useState<FormData>({
    emailRoles: initialEmailRoles,
  });
  const [formErrors, setFormErrors] = useState<Validator.ValidationErrors | null>(null);
  const inviteTeamToOneWorkspaceErrorMessage = inviteTeamToOneWorkspaceError && inviteTeamToOneWorkspaceError.message;

  const handleEmailRolesInputChange = (field: string, index: number) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      const value = typeof e === 'string' ? e : e.target.value;
      setFormData({
        emailRoles: [
          ...formData.emailRoles.slice(0, index),
          {...formData.emailRoles[index], [field]: value},
          ...formData.emailRoles.slice(index + 1)
        ]
      });
      setFormErrors(null);
    }

  const handleInviteTeam = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      'emailRoles.*.email': ['required', 'email'],
    }, {
      required: 'The email field is required.',
      email: 'That email is invalid.',
    });

    if (validation.passes()) {
      setFormErrors(null);

      await inviteTeamToOneWorkspace({
        variables: {
          data: {
            emailRoles: formData.emailRoles
          },
          where: { slug: workspaceSlug },
        },
        onCompleted: ({ inviteTeamToOneWorkspace }) => {
          showSuccessToast('Invite sent to members');
          setFormData({
            emailRoles: initialEmailRoles
          });
        }
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  }

  const handleAddAnotherEmailRole = () => {
    const lastEmailRole = formData.emailRoles[formData.emailRoles.length - 1];

    if (lastEmailRole && lastEmailRole.email) {
      setFormData({
        emailRoles: [...formData.emailRoles, {email: '', role: UserWorkspaceRole.ADMIN}]
      });
    }
  }

  const handleRemoveEmailRole = (index: number) => () => {
    if (formData.emailRoles.length === 1) return;
    setFormData({
      emailRoles: [
        ...formData.emailRoles.slice(0, index),
        ...formData.emailRoles.slice(index + 1)
      ]
    })
  }

  const workspaceRoleOptions = [
    {
      role: UserWorkspaceRole.ADMIN,
      snippet: 'Admin can manage workspace, members, create and manage projects.'
    },
    {
      role: UserWorkspaceRole.MEMBER,
      snippet: 'Member can participate in projects and access workspace resources.'
    }
  ];

  const tabs: Tab[] = Object.keys(TabType).map(key => ({
    id: key,
    value: TabType[key as keyof typeof TabType].toLowerCase()
  }));

  const roles: string[] = Object.keys(UserWorkspaceRole);

  const {showSuccessToast, showErrorToast} = useToast();
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].id);
  const [updateOneWorkspaceMemberRole] = useUpdateOneWorkspaceMemberRole();
  const [updateOneWorkspaceInvitation] = useUpdateOneWorkspaceInvitation();
  const [deactivateOneWorkspaceMember] = useDeactivateOneWorkspaceMember();
  const [reactivateOneWorkspaceMember] = useReactivateOneWorkspaceMember();
  const [deleteOneWorkspaceInvitation] = useDeleteOneWorkspaceInvitation();
  const [updateOneWorkspace] = useUpdateOneWorkspace();

  if (workspaceLoading) return <div className="dark:text-white">Loading members page...</div>;

  const {workspace}: { workspace: IWorkspace } = workspaceData;
  const members = workspace.members ?? [];
  const invitations = workspace.invitations ?? [];

  const isDeactivatedMember = (member: IUserWorkspace) => !!member.deactivatedAt;
  const transformMember = (member: IUserWorkspace) => ({
    id: member.id,
    name: member.user.detail.fullName,
    position: member.user.detail.position
      .toLowerCase()
      .split('_')
      .join(' '),
    workspaceRole: member.role,
    userRole: member.user.role,
    status: member.user.status,
    isOwner: member.isOwner,
    memberId: member.user.id,
  });

  const activeMembers = members
    .filter(member => !isDeactivatedMember(member))
    .map(transformMember);
  const deactivatedMembers = members
    .filter(member => isDeactivatedMember(member))
    .map(transformMember);
  const invitedMembers = invitations.map(invite => ({
    id: invite.id,
    inviteeRole: invite.role,
    inviteeEmail: invite.inviteeEmail,
  }));

  const showActiveMembers = TabType.ALL === currentTab || TabType.ACTIVE === currentTab;
  const showDeactivatedMembers = TabType.ALL === currentTab || TabType.DEACTIVATED === currentTab;
  const showInvitedMembers = TabType.ALL === currentTab || TabType.INVITED === currentTab;

  const membersCount = {
    [TabType.ALL]: activeMembers.length + deactivatedMembers.length + invitedMembers.length,
    [TabType.ACTIVE]: activeMembers.length,
    [TabType.DEACTIVATED]: deactivatedMembers.length,
    [TabType.INVITED]: invitedMembers.length,
  };
  const currentMembersCount = membersCount[currentTab as keyof typeof TabType];

  const handleClickMemberRole = async (memberId: string, role: UserWorkspaceRole) => {
    await updateOneWorkspaceMemberRole({
      variables: {
        data: {
          memberId: memberId,
          role: role
        },
        where: {
          slug: workspaceSlug
        }
      }
    })
  };

  const handleClickInviteeRole = async (inviteId: string, role: UserWorkspaceRole) => {
    await updateOneWorkspaceInvitation({
      variables: {
        data: {
          role: {set: role}
        },
        where: {
          id: inviteId
        }
      }
    })
  };

  const handleClickDeactivateMember = async (memberId: string) => {
    await deactivateOneWorkspaceMember({
      variables: {
        data: {
          memberId: memberId
        },
        where: {
          slug: workspaceSlug
        }
      }
    })
  };

  const handleClickReactivateMember = async (memberId: string) => {
    await reactivateOneWorkspaceMember({
      variables: {
        data: {
          memberId: memberId
        },
        where: {
          slug: workspaceSlug
        }
      }
    })
  };

  const handleClickRemoveInvite = async (inviteId: string) => {
    await deleteOneWorkspaceInvitation({
      variables: {
        where: {
          id: inviteId
        }
      },
      update: (cache, {data: {deleteOneWorkspaceInvitation}}) => {
        const {workspace}: any = cache.readQuery({query: WORKSPACE, variables: queryVariables});
        cache.writeQuery({
          query: WORKSPACE,
          variables: queryVariables,
          data: {
            workspace: {
              ...workspace,
              invitations: workspace.invitations
                .filter((invite: IInvitation) => invite.id !== deleteOneWorkspaceInvitation.id)
            }
          }
        });
      }
    })
  }

  const handleToggleInviteToken = () => {
    updateOneWorkspace({
      variables: {
        data: {
          enableInviteToken: {
            set: !workspaceData?.workspace.enableInviteToken
          },
        },
        where: {
          slug: workspaceSlug
        }
      },
    });
  }

  const secretLink = window.location.origin + '/auth/accept-invite?token=' + workspace.inviteToken;

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(secretLink)
      .then(() => {
        showSuccessToast('Invite link is copied to clipboard.')
      })
      .catch(err => {
        showErrorToast('Error copying text to clipboard.');
      });
  }

  return (
    <>
      <div className="workspace-page flex flex-col gap-10">
        <div className="header_div">
          <HeaderText title="Members"/>
          <HorizontalDivider/>
        </div>

        {/* invite members by link */}
        <div className="flex flex-col items-start gap-6">
          <div className="flex items-center w-full justify-between gap-4">
            <HeaderText subTitle="Invite with link"
                        snippet="Enable a secret link for workspace admins to invite new members."/>
            <SwitchToggle
              id="toggle1"
              isChecked={workspace?.enableInviteToken ?? false}
              onChange={handleToggleInviteToken}
              label="Toggle 1"
              className="toggle-container"
            />
          </div>
          {workspace?.enableInviteToken && (
            <div className="flex gap-3 w-full">
              <div className="truncate secret-link flex items-center px-4 rounded-lg bg-[var(--backgrounds-default)] w-full">
                <p className="truncate text-body-small regular text-[var(--text-on-bg)]">{secretLink}</p>
              </div>
              <div>
                <Button onClick={handleCopyInviteLink} size="medium" btnType="neutral" icon="left" value={'Copy link'}>
                  <div>
                    <Issues/>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
        <HorizontalDivider/>

        {/* Invite with email */}
        <form
          onSubmit={handleInviteTeam}
          className="flex flex-col items-start gap-6"
        >
          <HeaderText subTitle="Invite with email" snippet="Invite new members to your workspace."/>
          <div className="w-full flex flex-col gap-8">
            {formData.emailRoles.map((item, index) => (
              <div>
                <div key={index} className="w-full flex items-end gap-3">
                  <Input
                    type="email"
                    value={formData.emailRoles[index].email}
                    label="Email"
                    placeholder="Enter email"
                    onChange={handleEmailRolesInputChange('email', index)}
                    feedback=""
                  />
                  <div className="cta flex gap-3 items-center">
                    <Menu as="div" className="relative inline-block ml-auto">
                      {({open}) => (
                        <>
                          <div>
                            <Menu.Button className={``}>
                              <div
                                className={`${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''} flex items-center px-4 h-10 gap-2 border border-[var(--border-input-default)] rounded-lg cursor-pointer`}>
                                <p className="text-body-small regular text-[var(--text-default)]">
                                  {transformToTitleCase(formData.emailRoles[index].role)}
                                </p>
                                <div>
                                  <Triangle/>
                                </div>
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                              <div className="px-1 py-1 ">
                                {roles.map((role) => (
                                  <Menu.Item key={role}>
                                    {({active}) => (
                                      <button type="button">
                                        <DropItem
                                          item={{name: transformToTitleCase(role),}}
                                          onItemClick={() => handleEmailRolesInputChange('role', index)(role)}
                                        />
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>

                    <IconButton
                      icon={<Trash/>}
                      size='standard'
                      type='neutral'
                      onClick={handleRemoveEmailRole(index)}
                    />
                  </div>
                </div>
                <ValidationErrorMessages name={`emailRoles.${index}.email`} errors={formErrors} />
              </div>
            ))}
            <div className="flex items-center justify-between">
              <Button
                onClick={handleAddAnotherEmailRole}
                size="medium"
                btnType="neutral"
                icon="left"
                value={'Add another'}
                className='w-auto'
                type="button"
              >
                <Plus/>
              </Button>
              <Button
                type="submit"
                btnType="primary"
                size="medium"
                value='Send invite'
                disabled={inviteTeamToOneWorkspaceLoading}
                className='w-auto'
              />
            </div>
          </div>
          {inviteTeamToOneWorkspaceErrorMessage && (
            <p className="text-xs text-red-500">
              {inviteTeamToOneWorkspaceErrorMessage}
            </p>
          )}
        </form>
        <HorizontalDivider/>

        {/* Manage members */}
        <div className="flex flex-col items-start gap-8 w-full">
          <HeaderText subTitle="Manage members" snippet="Manage the members in your workspace."/>
          <Tabs
            tabs={tabs}
            onTabChange={setCurrentTab}
            initialSelectedTab={currentTab}
          />
          <p className="text-body-small medium text-[var(--text-default)]">
            {currentMembersCount > 0 ? currentMembersCount : 'No' } <span
            className="capitalize">{currentTab !== 'ALL' && currentTab.toLowerCase()}</span> {currentMembersCount === 1 ? 'Member' : 'Members'}
          </p>

          <div className="flex flex-col items-start w-full">
            {/* Active Members */}
            {showActiveMembers && activeMembers.map(({
                                                       id,
                                                       memberId,
                                                       name,
                                                       userRole,
                                                       workspaceRole,
                                                       isOwner,
                                                       position
                                                     }: any) => (
              <TableLayout
                key={name}
                Col1={(
                  <NameAvatar
                    loading={false}
                    name={name}
                    position={position}
                    role={isOwner && 'OWNER'}
                  />
                )}
                Col2={
                  <Menu as="div" className="relative inline-block mr-auto">
                    {({open}) => (
                      <>
                        <div>
                          <Menu.Button>
                            <Button
                              btnType='ghost'
                              size='small'
                              value={transformToTitleCase(workspaceRole)}
                              className={`w-auto ${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''}`}
                            >
                              <ChevronDown/>
                            </Button>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              {workspaceRoleOptions.map(({role, snippet}) => (
                                <Menu.Item>
                                  {({active}) => (
                                    <PopOverItem
                                      key={role}
                                      name={transformToTitleCase(role)}
                                      snippet={snippet}
                                      active={workspaceRole === role}
                                      onClick={() => handleClickMemberRole(memberId, role)}
                                    />
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                }
                menu={(
                  <Menu as="div" className="relative inline-block ml-auto">
                    {({open}) => (
                      <>
                        <div>
                          <Menu.Button className={`${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''} rounded`}>
                            <IconButton
                              size='micro'
                              type='ghost'
                              icon={<ThreeDots/>}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({active}) => (
                                  <button>
                                    <DropItem
                                      item={{name: 'Change email',}}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({active}) => (
                                  <button>
                                    <DropItem
                                      item={{name: 'Deactivate member',}}
                                      onItemClick={() => handleClickDeactivateMember(memberId)}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                )}
              />
            ))}

            {/* Invited Members */}
            {showInvitedMembers && invitedMembers.map(({id, inviteeEmail, inviteeRole}) => (
              <TableLayout
                key={inviteeEmail}
                Col1={<NameAvatar loading={false} name={inviteeEmail} role="Invited"/>}
                Col2={
                  <Menu as="div" className="relative inline-block mr-auto">
                    {({open}) => (
                      <>
                        <div>
                          <Menu.Button>
                            <Button
                              btnType='ghost'
                              size='small'
                              value={transformToTitleCase(inviteeRole)}
                              className={`w-auto ${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''}`}
                            >
                              <ChevronDown/>
                            </Button>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              {workspaceRoleOptions.map(({role, snippet}) => (
                                <Menu.Item>
                                  {({active}) => (
                                    <PopOverItem
                                      key={role}
                                      name={transformToTitleCase(role)}
                                      snippet={snippet}
                                      active={inviteeRole === role}
                                      onClick={() => handleClickInviteeRole(id, role)}
                                    />
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                }
                menu={(
                  <Menu as="div" className="relative inline-block ml-auto">
                    {({open}) => (
                      <>
                        <div>
                          <Menu.Button className={`${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''} rounded`}>
                            <IconButton
                              size='micro'
                              type='ghost'
                              icon={<ThreeDots/>}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({active}) => (
                                  <button>
                                    <DropItem
                                      item={{name: 'Change email',}}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({active}) => (
                                  <button>
                                    <DropItem
                                      item={{name: 'Remove member',}}
                                      onItemClick={() => handleClickRemoveInvite(id)}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                )}
              />
            ))}

            {/* Deactivated Members */}
            {showDeactivatedMembers && deactivatedMembers.map(({
                                                                 id,
                                                                 memberId,
                                                                 name,
                                                                 userRole,
                                                                 workspaceRole,
                                                                 status,
                                                                 position
                                                               }: any) => (
              <TableLayout
                key={name}
                containerClassName={'opacity-50'}
                Col1={(
                  <NameAvatar
                    loading={false}
                    name={name}
                    position={position}
                    role={'Deactivated'}
                  />
                )}
                Col2={
                  <Menu as="div" className="relative inline-block mr-auto">
                    {({open}) => (
                      <>
                        <div>
                          <Menu.Button>
                            <Button
                              btnType='ghost'
                              size='small'
                              value={transformToTitleCase(workspaceRole)}
                              className={`w-auto ${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''}`}
                            >
                              <ChevronDown/>
                            </Button>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              {workspaceRoleOptions.map(({role, snippet}) => (
                                <Menu.Item>
                                  {({active}) => (
                                    <PopOverItem
                                      key={role}
                                      name={transformToTitleCase(role)}
                                      snippet={snippet}
                                      active={workspaceRole === role}
                                      onClick={() => handleClickMemberRole(memberId, role)}
                                    />
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                }
                menu={(
                  <Menu as="div" className="relative inline-block ml-auto">
                    {({open}) => (
                      <>
                        <div>
                          <Menu.Button className={`${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''} rounded`}>
                            <IconButton
                              size='micro'
                              type='ghost'
                              icon={<ThreeDots/>}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({active}) => (
                                  <button>
                                    <DropItem
                                      item={{name: 'Reactivate member',}}
                                      onItemClick={() => handleClickReactivateMember(memberId)}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                )}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
