// Mentionify.tsx
import React, { useRef, useState, ChangeEvent, KeyboardEvent } from 'react';
import Textarea from './Textarea';
import Menu from './Menu';

interface User {
  username: string;
}

interface MentionifyProps {
  users: User[];
  resolveFn: (prefix: string) => User[] | Promise<User[]>;
  replaceFn: (user: User, trigger: string) => string;
}

const properties = [
  'direction', 'boxSizing', 'width', 'height', 'overflowX', 'overflowY',
  'borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth', 'borderStyle',
  'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft',
  'fontStyle', 'fontVariant', 'fontWeight', 'fontStretch', 'fontSize', 'fontSizeAdjust', 'lineHeight', 'fontFamily',
  'textAlign', 'textTransform', 'textIndent', 'textDecoration', 'letterSpacing', 'wordSpacing',
  'tabSize', 'MozTabSize'
];

const isFirefox = typeof window !== 'undefined' && (window as any)['mozInnerScreenX'] != null;

function getCaretCoordinates(element: HTMLTextAreaElement, position: number) {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const style = div.style;
  const computed = getComputedStyle(element);

  style.whiteSpace = 'pre-wrap';
  style.wordWrap = 'break-word';
  style.position = 'absolute';
  style.visibility = 'hidden';

  properties.forEach(prop => {
    style[prop as any] = computed[prop as any];
  });

  if (isFirefox) {
    if (element.scrollHeight > parseInt(computed.height))
      style.overflowY = 'scroll';
  } else {
    style.overflow = 'hidden';
  }

  div.textContent = element.value.substring(0, position);

  const span = document.createElement('span');
  span.textContent = element.value.substring(position) || '.';
  div.appendChild(span);

  const coordinates = {
    top: span.offsetTop + parseInt(computed['borderTopWidth']),
    left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
    height: span.offsetHeight
  };

  div.remove();

  return coordinates;
}

const Mentionify: React.FC<MentionifyProps> = ({ users, resolveFn, replaceFn }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [options, setOptions] = useState<User[]>([]);
  const [active, setActive] = useState<number>(0);
  const [triggerIdx, setTriggerIdx] = useState<number | undefined>(undefined);
  const [menuPosition, setMenuPosition] = useState<{ left: number, top: number } | undefined>(undefined);

  const closeMenu = () => {
    setTimeout(() => {
      setOptions([]);
      setTriggerIdx(undefined);
      setMenuPosition(undefined);
    }, 0);
  };

  const selectItem = (active: number) => () => {
    if (textareaRef.current) {
      const preMention = textareaRef.current.value.substr(0, triggerIdx!);
      const option = options[active];
      const mention = replaceFn(option, textareaRef.current.value[triggerIdx!]);
      const postMention = textareaRef.current.value.substr(textareaRef.current.selectionStart!);
      const newValue = `${preMention}${mention}${postMention}`;
      textareaRef.current.value = newValue;
      const caretPosition = textareaRef.current.value.length - postMention.length;
      textareaRef.current.setSelectionRange(caretPosition, caretPosition);
      closeMenu();
      textareaRef.current.focus();
    }
  };

  const onInput = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    const positionIndex = ev.target.selectionStart!;
    const textBeforeCaret = ev.target.value.slice(0, positionIndex);
    const tokens = textBeforeCaret.split(/\s/);
    const lastToken = tokens[tokens.length - 1];
    const triggerIdx = textBeforeCaret.endsWith(lastToken)
      ? textBeforeCaret.length - lastToken.length
      : -1;
    const maybeTrigger = textBeforeCaret[triggerIdx];
    const keystrokeTriggered = maybeTrigger === '@';

    if (!keystrokeTriggered) {
      closeMenu();
      return;
    }

    const query = textBeforeCaret.slice(triggerIdx + 1);
    Promise.resolve(resolveFn(query)).then(options => {
      if (options.length !== 0) {
        setOptions(options);
      } else {
        closeMenu();
      }
    });

    const coords = getCaretCoordinates(ev.target, positionIndex);
    const { top, left } = ev.target.getBoundingClientRect();

    setTimeout(() => {
      setActive(0);
      setMenuPosition({
        left: window.scrollX + coords.left + left + ev.target.scrollLeft,
        top: window.scrollY + coords.top + top + coords.height - ev.target.scrollTop
      });
      setTriggerIdx(triggerIdx);
    }, 0);
  };

  const onKeyDown = (ev: KeyboardEvent<HTMLTextAreaElement>) => {
    if (triggerIdx !== undefined) {
      let keyCaught = false;
      switch (ev.key) {
        case 'ArrowDown':
          setActive(Math.min(active + 1, options.length - 1));
          keyCaught = true;
          break;
        case 'ArrowUp':
          setActive(Math.max(active - 1, 0));
          keyCaught = true;
          break;
        case 'Enter':
        case 'Tab':
          selectItem(active)();
          keyCaught = true;
          break;
      }
      if (keyCaught) {
        ev.preventDefault();
      }
    }
  };

  return (
    <div className="relative container mx-auto max-w-[80ch] p-4">
      <Textarea ref={textareaRef} onInput={onInput} onKeyDown={onKeyDown} />
      <Menu options={options} active={active} menuPosition={menuPosition} selectItem={selectItem} />
    </div>
  );
};

export default Mentionify;
