import React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { WORKSPACE_INVITATION } from "../../lib/queries";
import { useSendAuthToken } from "../../api/auth/useSendAuthToken";
import { ACCEPT_ONE_WORKSPACE_INVITATION } from "../../lib/mutations";
import Media from "../../components/Media";
import Spinner from "../../components/Spinner";

function AcceptInvitePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const workspaceInvitationStatus = useQuery(WORKSPACE_INVITATION, {
    variables: {
      where: { token }
    }
  });

  const [acceptOneWorkspaceInvitation, acceptOneWorkspaceInvitationStatus] = useMutation(ACCEPT_ONE_WORKSPACE_INVITATION);
  const [sendAuthToken, sendAuthTokenStatus] = useSendAuthToken();

  const handleAcceptInvite = async () => {
    await acceptOneWorkspaceInvitation({
      variables: {
        where: { token }
      },
      onCompleted: async ({ acceptOneWorkspaceInvitation }) => {
        const { accepted, inviteeEmail } = acceptOneWorkspaceInvitation;
        if (accepted) {
          await sendAuthToken({
            variables: { email: inviteeEmail },
            onCompleted: data => {
              navigate('/auth/verify-otp?email=' + encodeURIComponent(inviteeEmail));
            }
          });
        }
      }
    });
  }

  if (workspaceInvitationStatus.loading) return null;

  const { workspaceInvitation } = workspaceInvitationStatus.data;
  const busy = acceptOneWorkspaceInvitationStatus.loading || sendAuthTokenStatus.loading;

  return (
    <main className="flex flex-col items-center justify-center min-h-screen p-4 bg-register">
      <div className="flex flex-col space-y-12 max-w-md">
        <div className="flex flex-col items-center space-y-2">
          <Media
            media={workspaceInvitation.workspace.logo}
            alt={workspaceInvitation.workspace.name}
            width={40}
            height={40}
            fontSize={16}
          />
          <h1 className="text-2xl font-medium text-center text-grey-700 font-graphik">
            {workspaceInvitation.inviter.detail.fullName} has invited you to join {' '}
            {workspaceInvitation.workspace.name}’s workspace
          </h1>
          <div className="p-1.5 px-3 rounded-xl bg-primary-100 text-primary-900 text-xs">
            You’re joining as <strong className='font-medium'>{workspaceInvitation.inviteeEmail}</strong>
          </div>
        </div>

        <button
          type="button"
          disabled={busy}
          onClick={handleAcceptInvite}
          className="btn-primary w-full"
        >
          Accept invite
          {busy && <Spinner />}
        </button>
      </div>
    </main>
  );
}

export default AcceptInvitePage;
