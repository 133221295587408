import React, {useState} from 'react';
import {getColorObject} from "src/lib/helpers";
import classNames from "classnames";

interface AvatarProps {
  avatar?: {
    filename: string;
    mimetype: string;
    url: string;
  };
  alt: string;
  size?: number;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = props => {
  const {avatar, alt, size = 20, className} = props;

  if (avatar) return (
    <img
      src={process.env.REACT_APP_API_URL + '/' + avatar?.url}
      alt={alt}
      style={{width: size, height: size}}
      className="aspect-square border border-white rounded-full object-cover"
    />
  );

  const {background, color} = getColorObject(alt);

  return (
    <div
      className={classNames(
        "relative text-xs border border-white rounded-full grid place-content-center",
        className
      )}
      style={{width: size, height: size, background}}
    >
      <span className="opacity-75" style={{color, fontSize: `calc(${size}px /2)`}}>
        <div>{alt[0]}</div>
      </span>
    </div>
  );
};

export default Avatar;
