import React, { useState, ChangeEvent } from 'react';
import DefaultAvatar from './Avatar/DefaultAvatar';
import { Plus } from 'src/constants/icons';
import { useSingleUpload } from 'src/api/useSingleUpload';
import { useToast } from "src/context/ToastContext";
import { useParams } from 'react-router-dom';
import { useUpdateOneWorkspaceLogo } from 'src/api/workspace/useUpdateOneWorkspaceLogo';
import { IMedia } from 'src/lib/types';
import toast from 'react-hot-toast';
import ToastMessage from '../ToastMessage';

interface LogoProps {
    name: string;
    userId: string;
    size: number;
    fontSize: number;
    logo: IMedia | null;
}

const Logo: React.FC<LogoProps> = ({ userId, name, size, fontSize, logo }) => {
    // Toasts definitions
    const { showToast } = useToast();

    // GraphQL definitions
    const [singleUpload] = useSingleUpload();
    const [updateOneWorkspaceLogo] = useUpdateOneWorkspaceLogo();

    // Profile image modal and input states
    const [profileImageUrl, setProfileImageUrl] = useState<string | null>(logo?.url || null);
    const [image, setImage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    // routerDOM
    const { workspaceSlug } = useParams();

    function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result as string);
            };
            reader.readAsDataURL(file);
            handleSingleUpload(file);
        }
    }

    async function handleSingleUpload(file: File) {
        setLoading(true);
        try {
            const { data: result } = await singleUpload({ variables: { file } });
            setProfileImageUrl(result.singleUpload.url);
            await updateUserAvatar(result.singleUpload);
            showToast('Profile image uploaded successfully');
        } catch (error) {
            showToast('Error uploading profile image');
        } finally {
            setLoading(false);
        }
    }

    async function updateUserAvatar(uploadResult: any) {
        setLoading(true);
        try {
            const { data } = await updateOneWorkspaceLogo({
                variables: {
                    data: {
                        logo: {
                            set: {
                                filename: uploadResult.filename,
                                mimetype: uploadResult.mimetype,
                                url: uploadResult.url,
                            },
                        },
                    },
                    where: {
                        slug: workspaceSlug,
                    },
                },
            });
            setProfileImageUrl(data.updateOneWorkspaceLogo.logo?.url);
            toast(t => (
                <ToastMessage
                    id={t.id}
                    // icon={<TrashDeleteBin size={16} weight={3} />}
                    title="Workspace logo updated succesfully"
                // handleUndo={() => console.log('undoing...')}
                >
                </ToastMessage>
            ));
        } catch (error) {
            toast(t => (
                <ToastMessage
                    id={t.id}
                    // icon={<TrashDeleteBin size={16} weight={3} />}
                    title="Error updating workspace logo"
                // handleUndo={() => console.log('undoing...')}
                >
                </ToastMessage>
            ));
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="image-uploader relative overflow-hidden rounded object-cover" style={{ '--size': `${size}px` } as React.CSSProperties}>
            <label htmlFor="file-upload" className="upload-label">
                <div className="default_state avatar_wrap relative">
                    <div className="image-overlay absolute flex items-center justify-center top-2/4 left-1/2 w-full h-full bg-opacity-65 bg-black">
                        <Plus />
                    </div>
                    {!profileImageUrl && !image ? (
                        <DefaultAvatar name={name} size={size} fontSize={fontSize} fontWeight={600} />
                    ) : (
                        <img src={image || `${process.env.REACT_APP_API_URL}/${profileImageUrl}`} alt="Uploaded" className="uploaded-image" />
                    )}
                </div>
                <input
                    className="hidden"
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                />
            </label>
        </div>
    );
};

export default Logo;
