import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {SINGLE_UPLOAD} from "src/lib/mutations";

export function useSingleUpload() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Apollo-Require-Preflight': 'true',
      },
    }
  } : {}

  return useMutation(SINGLE_UPLOAD, options);
}
