import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {DELETE_ONE_PROJECT} from "src/lib/mutations";

export function useDeleteOneProject() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  } : {}


  return useMutation(DELETE_ONE_PROJECT, options);
}
