import SwitchToggle from "./ToggleSwitch";
import './ui.css'

interface DataItem {
    type: string;
    svg?: React.ReactNode;
    title: string;
    snippet: string;
}

interface InfoSwitchCardProps extends DataItem {
    checked: boolean;
    disabled?: boolean;
    onSwitch: (type: string, value: boolean) => void;
}

const InfoSwitchCard: React.FC<InfoSwitchCardProps> = ({ checked, disabled, svg, title, snippet, type, onSwitch }) => {
    return (
        <div className="InfoSwitchCard flex gap-4 items-center w-full">
            {svg}
            <div className="flex flex-col gap-0.5 w-full">
                <p className="title text-body-small regular">{title}</p>
                <p className="snippet text-body-small regular">{snippet}</p>
            </div>
            <SwitchToggle isChecked={checked} disabled={disabled} onChange={() => onSwitch(type, !checked)} id={type} />
        </div>
    );
};

export default InfoSwitchCard;
