// src/components/ImageViewer.tsx
import React, { useEffect } from 'react';
import IconButton from './Button/IconButton';
import { ChevronDown, Close } from 'src/constants/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { IMedia } from 'src/lib/types';

interface ImageViewerProps {
    images: IMedia[];
    currentImage: number;
    setCurrentImage: (index: number) => void;
    setShowImageModal: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ images, currentImage, setCurrentImage, setShowImageModal }) => {
    const goToPreviousImage = () => {
        setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1);
    };

    const goToNextImage = () => {
        setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                goToPreviousImage();
            } else if (event.key === 'ArrowRight') {
                goToNextImage();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentImage]);

    return (
        <AnimatePresence>
            <div className="fixed w-screen h-screen z-50 left-0 top-0 flex items-center justify-center !mt-0">
                <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="absolute z-20 flex items-center gap-2.5">
                    <div>
                        <IconButton icon={<ChevronDown className="rotate-90" />} size="standard" type="neutral" onClick={goToPreviousImage} />
                    </div>
                    <div className="w-[600px] max-h-[600px] h-auto flex items-center justify-center relative rounded-[20.69px] overflow-hidden">
                        <img
                            className="object-cover w-full h-full"
                            src={`${process.env.REACT_APP_API_URL}/${images[currentImage].url}`}
                            alt={`image-${currentImage}`}
                        />
                        <button className="w-7 rounded-full aspect-square h-7 absolute top-4 right-4 flex items-center justify-center bg-[rgba(42,_42,_42,_0.90)]" onClick={setShowImageModal}>
                            <div>
                                <Close className="close_icon" />
                            </div>
                        </button>
                    </div>
                    <div>
                        <IconButton icon={<ChevronDown className="-rotate-90" />} size="standard" type="neutral" onClick={goToNextImage} />
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.5 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    onClick={setShowImageModal}
                    className="w-screen h-screen bg-[var(--backgrounds-blanket)] opacity-50 z-10 left-0 top-0"></motion.div>
            </div>
        </AnimatePresence>
    );
};

export default ImageViewer;
