"use client";

import React, {useState} from "react";
import Modal from 'src/components/UI/Modals/Modal';
import Button from "src/components/Button";
import {TrashDeleteBin} from "react-basicons";
import ToastMessage from "src/components/ToastMessage";
import toast from "react-hot-toast";
import {IPostComment} from "src/lib/types";
import {useUpdateOnePost} from "src/api/post/useUpdateOnePost";
import { motion, AnimatePresence } from 'framer-motion'

export default function DeletePostComment(props: {
  show: boolean,
  handleClose: Function,
  comment: IPostComment | null,
}) {
  const {show, handleClose, comment} = props;
  const [updateOnePost, {loading: updateOnePostLoading}] = useUpdateOnePost();

  const handleDelete = () => {
    if (comment) {
      updateOnePost({
        variables: {
          data: {
            comments: {
              delete: [
                { id: comment.id }
              ]
            }
          },
          where: {id: comment.post.id}
        },
        onCompleted: data => {
          toast(t => (
            <ToastMessage
              id={t.id}
              icon={<TrashDeleteBin size={16} weight={3}/>}
              title="This comment has been deleted."
            />
          ));
          handleClose();
        },
        onError: error => {
          toast(t => (
            <ToastMessage
              id={t.id}
              icon={<TrashDeleteBin size={16} weight={3}/>}
              title={"There was an error deleting this comment."}
            >
              {error.message}
            </ToastMessage>
          ));
        },
      });
    }
  }

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center">
          <Modal open={show} data={{
            subtitle: "You’re about to delete this comment",
            snippet: "Are you sure you want to delete this comment? You cannot undo this action.",
            btn1: "Cancel",
            btn2: "Delete",
            btn1Type: "neutral",
            btn2Type: "destructive"
          }}
            onBtn1Click={() => handleClose()}
            onBtn2Click={() => handleDelete()}
          />
        </motion.div>
      )}
      </AnimatePresence>
    // <Modal
    //   as={'form'}
    //   size="small"
    //   show={show}
    //   handleClose={handleClose}
    // >
    //   <div className="space-y-8">
    //     <div className="space-y-4">
    //       <p className="text-base text-grey-900 font-medium">
    //         You’re about to delete this comment
    //       </p>
    //       <p>
    //         Are you sure you want to delete this comment?
    //         You cannot undo this action.
    //       </p>
    //     </div>

    //     <div className="flex justify-end">
    //       <div className="space-x-3">
    //         <Button
    //           onClick={() => handleClose()}
    //           type="button"
    //           size="medium"
    //           color="ghost"
    //         >
    //           Cancel
    //         </Button>
    //         <Button
    //           onClick={handleDelete}
    //           type="button"
    //           size="medium"
    //           color="negative"
    //           loading={updateOnePostLoading}
    //           disabled={updateOnePostLoading}
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     </div>
    //   </div>
    // </Modal>
  )
}
