import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IProjectLink, ProjectStatus } from "../lib/types";
import { useNavigate, useParams } from "react-router-dom";
import { useMe } from "../api/auth/useMe";
import { useLocalStorage } from "@uidotdev/usehooks";
import toast from "react-hot-toast";
import ToastMessage from "../components/ToastMessage";
import { useProject } from "../api/project/useProject";
import { useUpdateOneProject } from "../api/project/useUpdateOneProject";
import { deleteTypeNameKey, getColorTag, transformToTitleCase } from "../lib/helpers";
import EditProjectHeading from "../components/EditProjectHeading";
// import EditProjectDetails2 from "../components/EditProjectDetails2";
// import Avatar from "../components/Avatar";
import TextareaAutosize from "react-textarea-autosize";
import Posts from "../components/Posts";
import { ArrowTopRight, TrashDeleteBin } from "react-basicons";
import Button from "../components/Button";
import EditProject from "../components/EditProject";
import DeleteProject from "../components/DeleteProject";
import EditPost from "../components/EditPost";
import Avatar from 'src/components/UI/Avatar/Avatar';
import IconButton from 'src/components/UI/Button/IconButton';
import { ArrowBack, ArrowUp, Menu } from 'src/constants/icons';
import SectionWrap from 'src/components/UI/SectionWrap';
import ArchiveProject from 'src/components/ArchiveProject';
import EditProjectDetails from 'src/components/EditProjectDetails';
import { useArchiveOneProject } from 'src/api/project/useArchiveOneProject';
import { useDeleteOneProject } from 'src/api/project/useDeleteOneProject';
import { useProjectContext } from 'src/context/ProjectContext';
import PageLoader from 'src/components/UI/Loader/PageLoader';
import useMobile from 'src/hooks/useMobile';
import HorizontalDivider from 'src/components/UI/HorizontalDivider';
import { colorStyles } from 'src/lib/ColorPreset';
import clsx from 'clsx';

type FormData = {
  name: string;
  description: string;
  collaboratorIDs: string[];
  topicIDs: string[];
  goalIDs: string[];
  status: ProjectStatus;
  isPublic: boolean;
  links: IProjectLink[];
}

function ProjectPage() {
  const isMobile = useMobile();
  const { projectId, workspaceSlug } = useParams<{ projectId: string; workspaceSlug: string }>();
  const { loading: loadingMe, data: myData } = useMe();
  const [userId] = useLocalStorage("userId", undefined);
  const [deleteProject, setDeleteProject] = useState(false);
  const [archiveProject, setArchiveProject] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [editPost, setEditPost] = useState(false);
  const [funcType, setFuncType] = useState<'archive' | 'delete'>();

  const { projects, removeProject, restoreProject } = useProjectContext();

  const navigate = useNavigate();
  const [archiveOneProject, { loading: archiveOneProjectLoading }] = useArchiveOneProject();
  const [deleteOneProject, { loading: deleteOneProjectLoading }] = useDeleteOneProject();

  const HeadingContainerRef = useRef<HTMLDivElement | null>(null);
  const [heightHeadingContainerRef, setHeightHeadingContainerRef] = useState(0);


  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopyLink = () => {
    if (data?.project) {
      const origin = window.location.origin;
      const { project } = data;
      const workspace = project.workspace;
      const link = `${origin}/workspace/${workspace.slug}/project/${project.id}`;
      navigator.clipboard.writeText(link)
        .then(() => {
          toast(t => (
            <ToastMessage
              id={t.id}
              // visible={t.visible}
              title={'Link to post is copied to clipboard.'}
            />
          ));
        })
        .catch(err => {
          toast(t => (
            <ToastMessage
              id={t.id}
              // visible={t.visible}
              title={'Error copying text to clipboard.'}
            />
          ));
        });
    }
  }

  const handleEdit = () => {
    setEditProject(true);
  }

  const handleDelete = () => {
    setDeleteProject(true);
  }

  const handleCancel = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      restoreProject(data.project.id)
    }
  };

  useEffect(() => {
    console.log(projects);
  }, [projects]);

  const handlePreRequest = (type: 'archive' | 'delete') => {
    removeProject(data.project.id)
    // navigate(`/workspace/${workspaceSlug}/project/}`, { replace: true });
    toast(t => (
      <ToastMessage
        id={t.id}
        // icon={<TrashDeleteBin size={16} weight={3} />}
        title={`This project has been ${type}d.`}
        buttonText={type === 'archive' ? 'Undo' : 'Restore'}
        handleUndo={() => {
          handleCancel();
          toast.dismiss(t.id);
        }}
      >
        {/* You can go to the “project” part of workspace settings to access this project */}
      </ToastMessage>
    ));
    timeoutRef.current = setTimeout(() => type === 'archive' ? handleArchive() : handleFinalDelete(), 5000);
  }

  const handleArchive = () => {
    archiveOneProject({
      variables: {
        where: { id: data.project.id }
      },
      onCompleted: data => {
        // navigate(`/workspace/${workspaceSlug}/project`, { replace: true });
        toast(t => (
          <ToastMessage
            id={t.id}
            // icon={<TrashDeleteBin size={16} weight={3} />}
            title="You can access archived projects from 'Settings'."
          // handleUndo={() => console.log('undoing...')}
          >
          </ToastMessage>
        ), { duration: 500000 });
      },
      onError: error => {
        toast(t => (
          <ToastMessage
            id={t.id}
            icon={<TrashDeleteBin size={16} weight={3} />}
            title={"There was an error archiving this project."}
          >
            {error.message}
          </ToastMessage>
        ));
      }
    });
  }

  const handleFinalDelete = () => {
    deleteOneProject({
      variables: {
        where: { id: data.project.id }
      },
      onCompleted: data => {
        // navigate(`/workspace/${project.workspace.slug}`, { replace: true });
        toast(t => (
          <ToastMessage
            id={t.id}
            // icon={<TrashDeleteBin size={16} weight={3} />}
            title="This project has been deleted."
          // handleUndo={() => console.log('undoing...')}
          >
          </ToastMessage>
        ));
      },
      onError: error => {
        toast(t => (
          <ToastMessage
            id={t.id}
            // icon={<TrashDeleteBin size={16} weight={3} />}
            title={"There was an error deleting this project."}
          >
            {error.message}
          </ToastMessage>
        ));
      }
    });
  }

  const { loading, data } = useProject({
    variables: {
      where: { id: projectId }
    }
  });

  const [updateOneProject] = useUpdateOneProject();

  let formData: FormData = {
    name: "",
    description: "",
    collaboratorIDs: [],
    topicIDs: [],
    goalIDs: [],
    status: ProjectStatus.PLANNING,
    isPublic: true,
    links: [],
  };

  if (!loading && data?.project) {
    const { project } = data;
    formData = {
      name: project.name,
      description: project.description,
      collaboratorIDs: project.collaboratorIDs,
      topicIDs: project.topicIDs,
      goalIDs: project.goalIDs,
      status: project.status,
      isPublic: project.isPublic,
      links: project.links.map(deleteTypeNameKey),
    }
  }

  const handleInputChange = (field: string) =>
    (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      const data: FormData = { ...formData, [field]: value };

      updateOneProject({
        variables: {
          data: {
            collaborators: {
              set: data.collaboratorIDs.map(id => ({ id }))
            },
            goals: {
              set: data.goalIDs.map(id => ({ id }))
            },
            topics: {
              set: data.topicIDs.map(id => ({ id }))
            },
            isPublic: { set: data.isPublic },
            status: { set: data.status },
            links: {
              upsert: data.links.map((link: IProjectLink) => ({
                create: link,
                update: {
                  label: { set: link.label },
                  url: { set: link.url },
                },
                where: {
                  url_projectId: {
                    projectId: projectId,
                    url: link.url,
                  }
                }
              })),
            }
          },
          where: { id: projectId }
        },
      })
    }

  useEffect(() => {
    // Function to update the height
    const updateHeight = () => {
      if (HeadingContainerRef.current?.offsetHeight) {
        setHeightHeadingContainerRef(HeadingContainerRef.current.offsetHeight);
      }
    };
    // Update height on mount and when the window is resized
    updateHeight();
    window.addEventListener('resize', updateHeight);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [HeadingContainerRef.current?.offsetHeight, data?.project]);

  if (loading || loadingMe) return <PageLoader snippet="Loading your user project data and details" />;


  const isProjectOwner = userId === data?.project.owner.id;

  return (
    <>
      <div className="page-title z-10 flex items-center justify-between md:hidden h-14">
        <IconButton icon={<ArrowBack />} size={"small"} type={"ghost"} onClick={() => { navigate(-1) }} />
        <div className='flex items-center gap-2 capitalize'>
          <div>
            <div
              className={clsx(
                'flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold',
                // sideAvatarClassName // Merge custom className with default classes
              )}
              style={colorStyles[getColorTag(data.project.name)]}
            >
              {data.project.name[0]}
            </div>
          </div>
          <p className="tet-body-small bold text-[var(--text-neutral)]">
            {data.project.name}
          </p>
        </div>
        <IconButton icon={<Menu />} size={"small"} type={"ghost"} />
      </div>
      {/* Center pane */}
      <section className="w-full flex flex-col gap-2 h-[calc(100%-64px)] md:h-full mt-16 md:mt-0">
        {/* Header Section */}
        <div ref={HeadingContainerRef}>
          {data?.project && userId && (
            <EditProjectHeading
              userId={userId}
              project={data.project}
              handleEdit={handleEdit}
              handleDelete={() => setDeleteProject(true)}
              handleDArchive={() => {
                handlePreRequest('archive')
              }}
              handleCopyLink={handleCopyLink}
            >
              <div className='w-full fixed z-10 bottom-0 left-0 p-4 bg-[var(--backgrounds-on-canvas)] md:static md:z-0 md:!p-0'>
                <div className='md:hidden bottom-[72px] absolute w-full left-0'>
                  <HorizontalDivider />
                </div>
                <div className="flex relative w-full">
                  <div className="absolute left-0 inset-y-0 px-2 grid place-content-center">
                    <Avatar
                      name={myData.me.detail.fullName}
                      src={myData.me.detail.avatar?.url}
                      loading={loading}
                      alt={`${myData.me.detail.fullName} profile picture at overlap workspace`}
                      size={24}
                    />
                  </div>
                  <TextareaAutosize
                    name="form-field-name"
                    placeholder="What would you like to share?"
                    className="input resize-none bg-none placeholder:text-base placeholder:!font-medium !rounded-full w-full !bg-[var(--backgrounds-default)] !pl-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none"
                    onClick={() => setEditPost(true)}
                    readOnly
                  />
                  <div className="absolute right-0 inset-y-0 px-1.5 grid place-content-center">
                    <IconButton
                      onClick={() => setEditPost(true)}
                      type='primary'
                      size='standard'
                      icon={<ArrowUp />}
                      className='!rounded-full'
                    />
                  </div>
                </div>
              </div>
            </EditProjectHeading>
          )}
        </div>

        {/* <div className="lg:hidden card">
          {workspaceSlug && (
            <EditProjectDetails
              value={formData}
              onChange={handleInputChange}
              workspaceSlug={workspaceSlug}
              projectId={projectId}
              readOnly={!isProjectOwner}
            />
          )}
        </div> */}

        {projectId && workspaceSlug && (
          <Posts
            heightHeadingContainerRef={heightHeadingContainerRef}
            // projectId={projectId}
            workspaceSlug={workspaceSlug}
            handleCreatePost={() => setEditPost(true)}
            collaborators={data?.project.collaborators}
          />
        )}
      </section>

      {/* Stats */}
      <aside className="absolute z-10 md:sticky right-0 h-svh top-0 md:max-w-[406px] w-full transition-all lg:block bg-[rgba(7,7,7,0.5)] md:bg-transparent">
        <SectionWrap className="sticky top-0 h-full p-5 overflow-y-auto w-[94%] md:w-full ml-auto">
          <div className='mb-8 md:hidden'>
            <IconButton icon={<ArrowBack />} size={"small"} type={"ghost"} />
          </div>
          {workspaceSlug && (
            <EditProjectDetails
              value={formData}
              onChange={handleInputChange}
              workspaceSlug={workspaceSlug}
              projectId={projectId}
              readOnly={!isProjectOwner || true}
            />
          )}
        </SectionWrap>
      </aside>

      <EditProject
        show={editProject}
        project={data?.project}
        handleClose={() => setEditProject(false)}
      />

      <DeleteProject
        show={deleteProject}
        project={data?.project}
        handleClose={() => setDeleteProject(false)}
        handlePreArchive={() => {
          handlePreRequest('archive')
        }}
        handlePreDelete={() => {
          handlePreRequest('delete')
        }}
      />

      {/* archive project modal */}
      <ArchiveProject
        show={archiveProject}
        project={data?.project}
        handleClose={() => setArchiveProject(false)}
      />

      {workspaceSlug && (
        <EditPost
          show={editPost}
          handleClose={() => setEditPost(false)}
          workspaceSlug={workspaceSlug}
          collaborators={data?.project.collaborators}
        />
      )}
    </>
  );
}

export default ProjectPage;
