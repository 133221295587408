import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Validator from "validatorjs";
import ValidationErrorMessages from "../components/ValidationErrorMessages";
import Button from 'src/components/UI/Button/Button';
import { useInviteTeamToOneWorkspace } from "../api/useInviteTeamToOneWorkspace";

type FormData = {
  emails: string;
}

function InviteToWorkspacePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workspaceSlug = searchParams.get('workspace');

  const [formData, setFormData] = useState<FormData>({
    emails: '',
  });
  const [formErrors, setFormErrors] = useState<Validator.ValidationErrors | null>(null);
  const [inviteTeamToOneWorkspace, { loading, error }] = useInviteTeamToOneWorkspace();
  const errorMessage = error && `Submission error! ${error.message}`;
  const disabled = loading || !workspaceSlug;

  const handleInputChange = (field: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      const value = typeof e === 'string' ? e : e.target.value;
      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      emails: ['required', 'regex:/^\\s*([\\w.-]+@[a-zA-Z\\d.-]+\\.[a-zA-Z]{2,})(\\s*,\\s*([\\w.-]+@[a-zA-Z\\d.-]+\\.[a-zA-Z]{2,}))*\\s*$/'],
    });

    if (validation.passes()) {
      setFormErrors(null);

      // Using a regex to split by commas and trim any leading/trailing whitespace
      const emails = formData.emails.split(/\s*,\s*/);

      await inviteTeamToOneWorkspace({
        variables: {
          data: {
            emailRoles: emails.map(email => ({ email }))
          },
          where: { slug: workspaceSlug },
        },
        onCompleted: ({ inviteTeamToOneWorkspace }) => {
          alert(inviteTeamToOneWorkspace);
          navigate('/workspace/' + workspaceSlug);
        }
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  }

  return (
    <div className='flex flex-col gap-10 md:gap-14'>
      <div className="w-full flex flex-col gap-4 items-start">
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">Invite team members</h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            Invite your team members to your workspace with emails and start collaborating.
          </p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-full flex flex-col gap-6'
      >
        <div className="space-y-2">
          <label htmlFor="emails" className='text-body-small regular text-[var(--text-default)]'>
            Email address
          </label>
          <div className="space-y-1">
            <textarea
              autoFocus
              id="emails"
              className="w-full input min-h-20"
              disabled={disabled}
              value={formData.emails}
              onChange={handleInputChange('emails')}
              placeholder="daniel@gmail.com, elijah@gmail.com"
              rows={3}
            ></textarea>

            <ValidationErrorMessages name='emails' errors={formErrors} />

            {errorMessage && (
              <p className="text-xs text-red-500">
                {errorMessage}
              </p>
            )}

            <p className="text-body-micro regular text-[var(--text-neutral)]">
              You can invite multiple emails at once with this format {' '}
              <span className="medium text-[var(--text-default)]">email1@example.com, email2@example.com, email3@example.com</span>
            </p>
          </div>
        </div>

        <Button
          type="submit"
          btnType="primary"
          size="large"
          value='Continue'
          loading={loading}
          disabled={disabled}
          className="w-full"
        />
        <Link to={`/workspace/${workspaceSlug}/project`}>
          <Button
            type="submit"
            btnType="ghost"
            size="large"
            value='Skip for later'
            loading={loading}
            disabled={disabled}
            className="w-full"
          />
        </Link>
      </form>
    </div>
  );
}

export default InviteToWorkspacePage;
