import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {useLocalStorage} from "@uidotdev/usehooks";

function ProtectedRoute() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return accessToken ? <Outlet /> : <Navigate to="/auth/login" />;
  // return true ? <Outlet /> : <Navigate to="/auth/login" />; // remember to remove this
}

export default ProtectedRoute;
