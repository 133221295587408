import clsx from 'clsx';
import { Link } from "react-router-dom";
import { Plus, Triangle } from "src/constants/icons"
import IconButton from "../UI/Button/IconButton"
import { IProject } from "src/lib/types";
import SideNavMenuBtn from './SideNavMenu';
import { getColorTag, transformToTitleCase } from "src/lib/helpers";
import { useContext, useEffect, useState } from 'react';
import { colorStyles } from 'src/lib/ColorPreset';
import Button from '../UI/Button/Button';
import { useProjectContext } from 'src/context/ProjectContext';

interface NavigationHeaderProps {
    baseLink: string;
    label: string | number;
    openInbox: boolean;
    // projects: IProject[];
    secNavFunc?: () => void;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ label, secNavFunc, openInbox, baseLink }) => {
    const { projects } = useProjectContext();

    useEffect(() => {
        console.log(projects, 'NavigationHeaderProps');
    }, [projects]);

    const [toggleNavChild, setToggleNavChild] = useState(true);

    return (
        <div className='flex flex-col gap-2'>
            <div className="flex items-center justify-between w-full">
                <button className="side__menu__btn px-3 py-0.5 h-6 gap-2 truncate !justify-start w-fit" onClick={() => setToggleNavChild(!toggleNavChild)}>
                    <div className='text-body-micro bold truncate capitalize text-[var(--text-disabled)]'>{label}</div>
                    <div className={clsx('dropdown_icon inline-block transition-transform duration-300', {
                        'rotate-0': toggleNavChild,
                        '-rotate-90': !toggleNavChild
                    })}><Triangle />
                    </div>
                </button>
                <div>
                    <IconButton
                        open={openInbox}
                        type='ghost'
                        size='small'
                        icon={<Plus />}
                        onClick={secNavFunc}
                    />
                </div>
            </div>
            <div className='flex flex-col gap-1'>
                {toggleNavChild && projects.map((project: IProject, key) => (
                    <Link key={project.id} to={`${baseLink}/${project.id}`}>
                        <SideNavMenuBtn
                            avatarColor={colorStyles[getColorTag(project.name)]}
                            sideAvatar={project.name}
                            type='home'
                            label={project.name}
                            link={`${baseLink}/${project.id}`}
                            showBadge={false}
                        />
                    </Link>
                ))}
                {/* <Button value={'New project'} size={'medium'} btnType={'ghost'} icon='left' className='justify-end' onClick={secNavFunc}>
                    <Plus />
                </Button> */}
            </div>
        </div>
    )
}

export default NavigationHeader;