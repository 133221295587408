import React, {Fragment} from 'react';
import {XCloseDelete} from "react-basicons";
import {theme} from "src/lib/helpers";
// import Button from "src/components/Button";
import toast from 'react-hot-toast';
import Button from './UI/Button/Button';
import IconButton from './UI/Button/IconButton';
import { Close } from 'src/constants/icons';

function ToastMessage({title, buttonText, handleUndo, icon, children, id}: {
  id: any;
  children?: React.ReactNode;
  handleUndo?: Function;
  icon?: React.ReactNode;
  title: string;
  buttonText?: string;
}) {

  const handleDismiss = () => toast.dismiss(id);

  return (
    <div className="flex gap-4 items-center justify-between w-full text-body-small medium !text-[var(--text-on-bg)]">
      {icon && (
        <div className="py-1">
          {icon}
        </div>
      )}
      <div className="w-full justify-between flex items-center">
        {title && (
          <h2 className="!text-[var(--Text-on-bg)]">
            {title}
          </h2>
        )}
        {children && <div className="mt-1">{children}</div>}
        {handleUndo && buttonText && (
          <Button onClick={() => handleUndo()} type='button' size="small" className="w-fit" value={buttonText} btnType={'neutral'} />
        )}
      </div>
      <button onClick={handleDismiss} className="w-fit">
        <div>
          <div>
            <Close />
          </div>
        </div>
      </button>
    </div>
  );
}

export default ToastMessage;
