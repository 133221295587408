import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {CREATE_ONE_PROJECT_GOAL} from "src/lib/mutations";

export function useCreateOneProjectGoal() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  } : {}


  return useMutation(CREATE_ONE_PROJECT_GOAL, options);
}
