import './ui.css'


interface HeaderTextProps {
    title?: string;
    subTitle?: string;
    snippet?: string;
}

const HeaderText: React.FC<HeaderTextProps> = ({
    title,
    subTitle,
    snippet
}) => {
    return (
        <div className="header_text">
            {title && <h1 className='title text-heading-4 medium'>{title}</h1>}
            {subTitle && <h2 className='subtitle text-body-small medium md:text-body-large'>{subTitle}</h2>}
            {snippet && <p className='snippet text-body-small regular' dangerouslySetInnerHTML={{ __html: snippet }}></p>}
        </div>
    );
};

export default HeaderText;

