import React from 'react';
import Validator from "validatorjs";

function ValidationErrorMessages({errors, name}: {
  errors: Validator.ValidationErrors | null,
  name: string,
}) {

  if (errors && errors[name]) {
    return (
      <>
        {errors[name].map(error => (
          <div key={error} className="text-xs text-red-500/75 font-medium mt-1">
            {error}
          </div>
        ))}
      </>
    );
  }

  return null;

}

export default ValidationErrorMessages;
