import React, {useEffect, useState} from 'react';
import {ChevronDownArrow, Reply, XCloseDelete} from "react-basicons";
import {getUniqueCommentAuthors, theme} from "src/lib/helpers";
import PostCommentBody from "src/components/PostCommentBody";
import TextareaAutosize from "react-textarea-autosize";
import Button from "src/components/Button";
import {IPostComment} from "src/lib/types";
import Avatar from "src/components/Avatar";
import classNames from "classnames";
import usePrevious from "src/hooks/usePrevious";

function PostCommentReplies(props: {
  comment: IPostComment;
  handlePost: Function;
  onClose: Function;
  show: boolean;
  loading: boolean;
}) {
  const {comment, handlePost, onClose, show, loading} = props;
  const prevLoading = usePrevious(loading);
  const [showInput, setShowInput] = useState(false);
  const [response, setResponse] = useState('');
  const commentAuthors = getUniqueCommentAuthors(comment.responses);

  useEffect(() => {
    if (prevLoading && !loading) {
      setShowInput(false);
      setResponse('');
    }
  }, [loading, prevLoading]);

  return (
    <>
      {show && <div onClick={() => onClose()} className="fixed inset-0 z-10"></div>}
      <div className={
        classNames(
          "overflow-auto fixed inset-y-0 right-0 w-96 lg:w-[29rem] bg-white z-10 shadow-2xl p-6 py-10 space-y-6 transition",
          show ? 'translate-x-0 opacity-100' : 'translate-x-96 lg:translate-x-[29rem] opacity-0 pointer-events-none'
        )
      }>
        {/* <header className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="text-lg font-semibold text-grey-900">
              Replies
            </div>
            <button type="button" onClick={() => onClose()}>
              <XCloseDelete size={20} color={(theme.textColor.grey as any)['300']}/>
            </button>
          </div>
          <div className="text-grey-300 rounded-lg bg-grey-50 inline-flex items-center space-x-1 p-1 px-2 text-xs">
            <Reply size={12} color={(theme.textColor.grey as any)['300']}/>
            <span className="truncate">
              {comment.post.author.detail.fullName} posted: {comment.post.title}
            </span>
          </div>
        </header> */}

        {/* <PostCommentBody comment={comment}/> */}

        {/* <div className="space-y-4">
          <TextareaAutosize
            onFocus={() => setShowInput(true)}
            value={response}
            onChange={e => setResponse(e.target.value)}
            disabled={loading}
            placeholder="Reply to this comment"
            className="resize-none w-full placeholder:text-grey-200 text-sm shadow-none !border-grey-25 !bg-grey-25"
          />

          {showInput && (
            <div className="flex items-center justify-end space-x-3">
              <Button
                onClick={() => setShowInput(false)}
                type="button"
                size="small"
              >
                Cancel
              </Button>
              <Button
                onClick={handlePost(response)}
                type="button"
                color="primary"
                size="small"
                loading={loading}
                disabled={loading}
              >
                Reply
              </Button>
            </div>
          )}
        </div> */}

        {/* <hr/> */}

        {/* {comment.responses.length > 0 && (
          <div className="flex items-center space-x-1">
            <ul className="flex -space-x-1">
              {commentAuthors.map(user => (
                <li key={user.id}>
                  <Avatar
                    alt={user.detail.fullName}
                    avatar={user.detail.avatar}
                  />
                </li>
              ))}
            </ul>

            <button
              type="button"
              disabled
              className="flex items-center space-x-1"
            >
              <div className="font-medium text-xs text-grey-300">
                {comment.responses.length} replies
              </div>
              <ChevronDownArrow size={12} color={(theme.textColor.grey as any)['200']} weight={3}/>
            </button>
          </div>
        )} */}

        {comment.responses.map(response => (
          <PostCommentBody key={response.id} comment={response} />
        ))}
      </div>
    </>
  );
}

export default PostCommentReplies;
