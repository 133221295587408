export default function DarkMode() {
    return (
        <svg width="145" height="104" viewBox="0 0 145 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.411932" y="0.411932" width="144.176" height="102.748" rx="6.17898" fill="#090909" stroke="#646464" strokeWidth="0.823864" />
            <rect x="33.1431" y="4.14288" width="71.4643" height="26.9286" rx="3.29545" fill="#2F2F2F" />
            <rect x="33.1431" y="35.2143" width="71.4643" height="64.2143" rx="3.29545" fill="#2F2F2F" />
            <rect x="108.75" y="4.14288" width="32.1071" height="95.2857" rx="3.29545" fill="#2F2F2F" />
            <rect x="14.5" y="8.28571" width="14.5" height="3.10714" rx="1.55357" fill="#2F2F2F" />
            <rect x="14.5" y="13.4643" width="9.32143" height="3.10714" rx="1.55357" fill="#2F2F2F" />
            <rect x="10.3569" y="24.8571" width="18.6429" height="4.14286" rx="2.07143" fill="#2F2F2F" />
            <rect x="10.3569" y="31.0714" width="18.6429" height="4.14286" rx="2.07143" fill="#2F2F2F" />
            <rect x="4.14307" y="24.8571" width="4.14286" height="4.14286" rx="2.07143" fill="#222222" />
            <rect x="4.14307" y="31.0714" width="4.14286" height="4.14286" rx="2.07143" fill="#222222" />
            <rect x="4.14307" y="43.5" width="21.75" height="4.14286" rx="2.07143" fill="#2F2F2F" />
            <rect x="4.14307" y="49.7143" width="21.75" height="4.14286" rx="2.07143" fill="#2F2F2F" />
            <rect x="4.14307" y="55.9286" width="21.75" height="4.14286" rx="2.07143" fill="#2F2F2F" />
            <rect x="4.14307" y="8.28571" width="8.28572" height="8.28572" rx="1.64773" fill="#222222" />
        </svg>

    )
}