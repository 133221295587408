import React, { Fragment, useState } from 'react';
import { Menu, Transition } from "@headlessui/react";
// import { theme } from "src/lib/helpers";
import IconButton from './UI/Button/IconButton';
import { Archive, Copy, Edit, ThreeDots, Trash } from 'src/constants/icons';
import { DropItem } from './UI/DropGroup';
import DeletePost from './DeletePost';
import EditPost from './EditPost';
import { IPost } from 'src/lib/types';

function PostCommentMenu(props: {
  handleCopy: Function;
  handleEdit: Function;
  handleDelete: Function;
  canEdit: boolean;
  canArchive: boolean;
  canDelete: boolean;
  type?: 'post' | 'comment' | 'reply'
}) {
  const { handleCopy, handleEdit, handleDelete, canDelete = false, canArchive = false, canEdit = false, type = 'post' } = props;

  // const [editPost, setEditPost] = useState<IPost | undefined>(undefined);
  // const [deletePost, setDeletePost] = useState<IPost | undefined>(undefined);

  // const handleEditPost = (post: IPost) => () => setEditPost(post);
  // const handleDeletePost = (post: IPost) => () => setDeletePost(post);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className={`${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''} rounded`}>
              <IconButton
                size='micro'
                type='ghost'
                icon={<ThreeDots />}
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
              <div className="px-1 py-1 ">
                {canEdit && (
                  <Menu.Item>
                    {({ active }) => (
                      <button>
                        <DropItem
                          item={{
                            name: `Edit ${type}`,
                            icon: <Edit />
                          }}
                          onItemClick={() => handleEdit()}
                        />
                      </button>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <button>
                      <DropItem
                        item={{
                          name: 'Copy Link',
                          icon: <Copy />
                        }}
                        onItemClick={() => handleCopy()}
                      />
                    </button>
                  )}
                </Menu.Item>
                {/* {canArchive && (
                  <Menu.Item>
                    {({ active }) => (
                      <button>
                        <DropItem
                          item={{
                            name: `Archive ${type}`,
                            icon: <Archive />
                          }}
                          onItemClick={() => handleDelete()}
                        />
                      </button>
                    )}
                  </Menu.Item>
                )} */}
                {canDelete && (
                  <Menu.Item>
                    {({ active }) => (
                      <button>
                        <DropItem
                          item={{
                            name: `Delete ${type}`,
                            icon: <Trash />
                          }}
                          onItemClick={() => handleDelete()}
                        />
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default PostCommentMenu;
