import { useState, useEffect, useMemo } from "react";
import { usePositionEnum } from "src/api/usePositionEnum";
import { useUpdateOneUserDetail } from "src/api/user/useUpdateOneUserDetail";
import Button from "src/components/UI/Button/Button";
import Input from "src/components/UI/Inputs/Default";
import SelectInput from "src/components/UI/Inputs/Select";
import { useToast } from "src/context/ToastContext";
import { convertCamelToText } from "src/utils/Index";

interface ProfileDetailProps {
    name: string;
    userRole: string;
    setUserRole: (arg: RoleData) => void;
    setUserName: (name: string) => void;
    handleUpdateUserNameAndRole: () => void;
}

interface RoleData {
    name: string;
    value: string;
}


export default function ProfileDetail({ name, userRole, setUserName, setUserRole, handleUpdateUserNameAndRole }: ProfileDetailProps) {
    // Toasts definitions
    const { showSuccessToast, showErrorToast } = useToast();

     // GraphQl definitions
    const [updateOneUserDetail] = useUpdateOneUserDetail();

    // state definition
    const [selectModal, setSelectModal] = useState(false);
    const [role, setRole] = useState('');

    // setting up all user roles with the position enums
    const positionEnum = usePositionEnum();
    const positionEnumValues = positionEnum.data?.__type.enumValues ?? [];
    const formattedRoles = useMemo(() => 
        positionEnumValues.map((item: { name: string }) => ({
            name: convertCamelToText(item.name),
            value: item.name,
        })), 
        [positionEnumValues]
    );

    useEffect(() => {
        const role = formattedRoles.find((enumValues: RoleData) => enumValues.value === userRole);
        setRole(role?.name ?? '');
    }, [userRole, formattedRoles]);

    function handleSelectChange (arg: RoleData) {
        setSelectModal(false);
        setUserRole(arg);
    };

    return (
        <>
            <Input
                type="text"
                value={name}
                feedback=""
                label="Full name"
                placeholder="Enter your full name"
                onChange={(e) => setUserName(e.target.value)}
            />
            <SelectInput
                showDropGroup={selectModal}
                value={role}
                style={{ maxHeight: '210px' }}
                data={formattedRoles}
                placeholder="Enter your role"
                onClick={() => setSelectModal(!selectModal)}
                onItemClick={handleSelectChange}
                clickOutside={() => setSelectModal(false)}
            />
            <div className="self-end">
                <Button
                    btnType="primary"
                    value="Update"
                    size="medium"
                    className="w-auto"
                    onClick={handleUpdateUserNameAndRole}
                />
            </div>
        </>
    );
}
