import React from "react";
import { useNavigate } from "react-router-dom";
import { useArchiveOneProject } from "src/api/project/useArchiveOneProject";
import { ArrowTopRight, TrashDeleteBin } from "react-basicons";
import { useDeleteOneProject } from "src/api/project/useDeleteOneProject";
import ToastMessage from "src/components/ToastMessage";
import toast from "react-hot-toast";
import { IProject } from "src/lib/types";

import { motion, AnimatePresence } from 'framer-motion'
import Modal from "./UI/Modals/Modal";
import Button from "./UI/Button/Button";
import { Archive, Plus, Trash } from "src/constants/icons";

export default function ArchiveProject(props: {
    show: boolean,
    handleClose: Function,
    project: IProject,
}) {

    const { show, handleClose, project } = props;
    const navigate = useNavigate();
    const [archiveOneProject, { loading: archiveOneProjectLoading }] = useArchiveOneProject();
    const [deleteOneProject, { loading: deleteOneProjectLoading }] = useDeleteOneProject();

    const handleArchive = () => {
        archiveOneProject({
            variables: {
                where: { id: project.id }
            },
            onCompleted: data => {
                navigate(`/workspace/${project.workspace.slug}`, { replace: true });
                toast(t => (
                    <ToastMessage
                        id={t.id}
                        icon={<TrashDeleteBin size={16} weight={3} />}
                        title="This project has been archived."
                        handleUndo={() => console.log('undoing...')}
                    >
                        You can access archived projects from "Settings".
                    </ToastMessage>
                ), { duration: 5000 });
            },
            onError: error => {
                toast(t => (
                    <ToastMessage
                        id={t.id}
                        icon={<TrashDeleteBin size={16} weight={3} />}
                        title={"There was an error archiving this project."}
                    >
                        {error.message}
                    </ToastMessage>
                ));
            }
        });
    }

    const handleDelete = () => {
        deleteOneProject({
            variables: {
                where: { id: project.id }
            },
            onCompleted: data => {
                navigate(`/workspace/${project.workspace.slug}`, { replace: true });
                toast(t => (
                    <ToastMessage
                        id={t.id}
                        icon={<TrashDeleteBin size={16} weight={3} />}
                        title="This project has been deleted."
                        handleUndo={() => console.log('undoing...')}
                    >
                        You can access deleted projects from "Settings".
                    </ToastMessage>
                ));
                handleClose();
            },
            onError: error => {
                toast(t => (
                    <ToastMessage
                        id={t.id}
                        icon={<TrashDeleteBin size={16} weight={3} />}
                        title={"There was an error deleting this project."}
                    >
                        {error.message}
                    </ToastMessage>
                ));
            }
        });
    }

    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center">
                    <Modal open={show} data={{
                        subtitle: "You’re about to delete this project",
                        snippet: "If you change your mind, you can restore it within the next 30 days. After that it will be permanently deleted from your workspace.",
                        btn1: "Cancel",
                        btn2: "Archive",
                        btn1Type: "neutral",
                        btn2Type: "primary"
                    }}
                        onBtn1Click={() => handleClose()}
                        onBtn2Click={() => handleArchive()}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    )
}
