import React, { FormEvent, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Validator from "validatorjs";
import { useSendAuthToken } from "../../api/auth/useSendAuthToken";
import SocialAuthButtonGroup from "../../components/SocialAuthButtonGroup";
import Input from 'src/components/UI/Inputs/Default';
import Button from 'src/components/UI/Button/Button';
import { LogoNeutral } from 'src/constants/icons';
import { useToast } from 'src/context/ToastContext';
import LogoPurple from 'src/components/Logo/LogoPurple';

type FormData = {
  email: string
}

export default function RegisterPage() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [sendAuthToken, { data, loading, error }] = useSendAuthToken();

  // const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [formData, setFormData] = useState<FormData>({
    email: ''
  });

  const [formErrors, setFormErrors] = useState<Validator.ValidationErrors | null>(null);
  const errorMessage: string = error ? `Submission error! ${error.message}` : '';

  async function handleContinue(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const validation = new Validator(formData, {
      email: 'required|email',
    });

    if (validation.passes()) {
      try {
        setFormErrors(null);

        const res = await sendAuthToken({
          variables: { email: formData.email },
          onCompleted: data => {
            navigate('/auth/verify-otp?email=' + encodeURIComponent(formData.email));
          },
        });

        if (res.errors) {
          showToast(res.errors[0].message);
        }
      } catch (error) {
        console.error('Error sending auth token:', error); // Log the error
        showToast('An error occurred. Please try again later.'); // User-friendly error message
      } finally {
      }
    } else {
      setFormErrors(validation.errors.all());
    }
  }




  return (
    <div className='w-full flex flex-col gap-6 items-center justify-center'>
      <div className='flex items-center gap-1'>
        <LogoPurple className='w-[18.655px] h-auto' />
        <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
          overlap
        </h1>
      </div>
      <div className='w-full flex flex-col px-4 py-8 items-center justify-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)] md:px-8'>
        <form
          onSubmit={handleContinue}
          className="w-full flex flex-col gap-10 justify-center">
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-center text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
              Create an account
            </h1>
            <p className="text-body-small hidden text-center medium text-[var(--text-neutral)] md:block">
              Help your team stay on the same page
            </p>
            <p className="text-body-small block text-center medium text-[var(--text-neutral)] md:hidden md:text-body-large">
              Give your team the gift of seamless communication
            </p>
          </div>

          <div className='flex flex-col gap-8'>
            <Input
              autoFocus
              type="email"
              value={formData.email}
              feedback={formErrors ? formErrors.email : ''}
              feedbackType={formErrors && formErrors.email ? 'error' : 'success'}
              label="Email address"
              placeholder="Enter your email address"
              onChange={(e) => setFormData({email: e.target.value})}
            />
            <Button
              type="submit"
              btnType="primary"
              size="large"
              value={'Continue'}
              loading={loading}
              disabled={loading}
              className="w-full"
            />
          </div>
        </form>
        <p className='text-body-small medium text-[var(--text-neutral)] text-center'>or</p>
        <SocialAuthButtonGroup />
        <p className="text-body-micro regular text-center text-[var(--text-neutral)] max-w-[336px]">
          By creating an account, you agree to the <span className='medium'>Terms of Service</span> and  <span className='medium'>Privacy Policy</span> of Overlap.
        </p>
      </div>
    </div>
  );
}
