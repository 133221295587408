import React from 'react';
import { EyePasswordShow, FlagCountry, HashtagTrendingNumber, Link, Pin, UserGroupAccounts } from "react-basicons";
import ProjectCollaboratorsSelect from "src/components/ProjectCollaboratorsSelect";
import ProjectTopicsSelect from "src/components/ProjectTopicsSelect";
import ProjectGoalsSelect from "src/components/ProjectGoalsSelect";
import ProjectStatusSelect from "src/components/ProjectStatusSelect";
import ProjectPublicAccessSelect from "src/components/ProjectPublicAccessSelect";
import { IProjectLink, ProjectStatus } from "src/lib/types";
import { useLocalStorage } from "@uidotdev/usehooks";
import ProjectLinksSelect from "src/components/ProjectLinksSelect";
import { theme } from "src/lib/helpers";
import { Files, LightBulb, Lock, Status, UserCircle, Users } from 'src/constants/icons';
import AssignItem from './UI/AssignItem';
import NameAvatar from './UI/Avatar/NameAvatar';
import ViewProjectLead from './ViewProjectLead';
import ProjectLeadSelect from './ProjectLeadSelect';

function EditProjectDetails({ projectId, onChange, value, workspaceSlug, readOnly = true, user }: {
  projectId?: string;
  workspaceSlug: string;
  value: {
    collaboratorIDs: string[];
    // projectLeadID: string[];
    topicIDs: string[];
    goalIDs: string[];
    status: ProjectStatus;
    isPublic: boolean;
    links: IProjectLink[];
  };
  onChange: Function;
  readOnly?: boolean;
  user?: {
    name: string;
    src: string;
    alt: string;
    position: string;
  };
}) {
  const [userId] = useLocalStorage("userId", undefined);

  return (
    <table className="-my-3 w-full font-medium text-sm">
      <tbody>
        {/* Project lead */}
        <tr>
          <td className="w-[133px] py-3">
            <AssignItem icon={<UserCircle />} text='Project Lead' />
          </td>
          <td className="py-3">
            {/* <ViewProjectLead 
              user={user}
            /> */}
            <ProjectLeadSelect
              userId={userId}
              projectId={projectId}
              workspaceSlug={workspaceSlug}
              value={value.collaboratorIDs}
              onChange={onChange('collaboratorIDs')} 
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Collaborators */}
        <tr>
          <td className="w-[133px] py-3">
            <AssignItem icon={<Users />} text='Collaborators' />
          </td>
          <td className="py-3">
            <ProjectCollaboratorsSelect
              userId={userId}
              projectId={projectId}
              workspaceSlug={workspaceSlug}
              value={value.collaboratorIDs}
              onChange={onChange('collaboratorIDs')}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Workstream */}
        {/* <tr>
          <td className="py-3 align-top">
            <div className="flex items-center space-x-2 text-grey-300 py-1.5">
              <HashtagTrendingNumber size={16} color={(theme.textColor.grey as any)['300']} weight={2} />
              <span className="text-nowrap">Workstream</span>
            </div>
          </td>
          <td className="py-3">
            <ProjectTopicsSelect
              projectId={projectId}
              value={value.topicIDs}
              onChange={onChange('topicIDs')}
              readOnly={readOnly}
            />
          </td>
        </tr> */}

        {/* Goals */}
        <tr>
          <td className="py-3 align-top">
            <AssignItem icon={<LightBulb />} text='Goals' />
          </td>
          <td className="py-3">
            <ProjectGoalsSelect
              workspaceSlug={workspaceSlug}
              projectId={projectId}
              value={value.goalIDs}
              onChange={onChange('goalIDs')}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Status */}
        <tr>
          <td className="py-3">
            <AssignItem icon={<Status />} text='Status' />
          </td>
          <td className="py-3">
            <ProjectStatusSelect
              value={value.status}
              onChange={onChange('status')}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Access */}
        <tr>
          <td className="py-3">
            <AssignItem icon={<Lock />} text='Access' />
          </td>
          <td className="py-3">
            <ProjectPublicAccessSelect
              projectId={projectId}
              checked={value.isPublic}
              onChange={onChange('isPublic')}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Resources */}
        <tr className="">
          <td className="py-3 flex">
            <AssignItem icon={<Files />} text='Resources' />
          </td>
          <td className="py-3">
            <ProjectLinksSelect
              projectId={projectId}
              value={value.links}
              onChange={onChange('links')}
              readOnly={readOnly}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default EditProjectDetails;
