import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckGoodYes } from 'react-basicons';
import { ProjectStatus } from 'src/lib/types';
import { transformToTitleCase } from 'src/lib/helpers';
import classNames from 'classnames';
import StatusTag from './UI/Tags/StatusTags';
import { Check, Circle } from 'src/constants/icons';

interface ProjectStatusSelectProps {
  value: ProjectStatus;
  onChange: (value: ProjectStatus) => void;
  readOnly?: boolean;
}

const ProjectStatusSelect: React.FC<ProjectStatusSelectProps> = (props) => {
  const mapStatusToColor: { [key in ProjectStatus]: 'default' | 'info' | 'success' | 'negative' | 'warning' | 'disabled' } = {
    [ProjectStatus.PLANNING]: 'info',
    [ProjectStatus.ONGOING]: 'warning',
    [ProjectStatus.COMPLETED]: 'success',
    [ProjectStatus.CANCELLED]: 'negative',
  };
  const { readOnly = true, onChange, value } = props;

  return (
    <div className="relative">
      {readOnly && <div className="relative justify-start">
        <StatusTag color={mapStatusToColor[value]} value={transformToTitleCase(value)} size="medium" leftIcon={<Circle />} />
      </div>}
      {!readOnly && (
        <Listbox value={value} onChange={onChange}>
          <div className="relative">
            <Listbox.Button className="relative justify-start cursor-pointer">
              <StatusTag color={mapStatusToColor[value]} value={transformToTitleCase(value)} size="medium" leftIcon={<Circle />} />
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Listbox.Options className="absolute mt-3 max-h-60 z-10 w-48 overflow-auto drop-group p-1">
                {/* <div className="py-3 px-4 text-xs text-grey-300">Status</div> */}
                {Object.values(ProjectStatus).map((status: ProjectStatus, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={classNames(
                      `drop-item flex px-2 items-center w-full text-[var(--text-neutral)] text-body-small medium cursor-pointer rounded gap-2.5 h-8 no-snippet`,
                      { 'cursor-pointer': !readOnly }
                    )}
                    value={status}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`dot dot-${mapStatusToColor[status]}`}></span>
                        <span className="flex-1 truncate">{transformToTitleCase(status)}</span>
                        {selected ? (
                          <span>
                            <Check />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      )}
    </div>
  );
};

export default ProjectStatusSelect;
