import { gql } from "@apollo/client";
import {INBOX_FIELDS, WORKSPACE_FIELDS} from "./fragments";

export const SIGN_IN = gql`
    mutation SignIn($data: SignInInput!) {
        signIn(data: $data) {
            accessToken
            refreshToken
            userId
        }
    }
`;

export const SEND_AUTH_TOKEN = gql`
    mutation SendAuthToken($email: String!) {
        sendAuthToken(email: $email)
    }
`;

export const CHECK_AUTH_TOKEN = gql`
    mutation CheckAuthToken($data: CheckTokenInput!) {
        checkAuthToken(data: $data) {
            accessToken
            refreshToken
            userId
        }
    }
`;

export const UPSERT_ONE_USER_DETAIL = gql`
    mutation UpsertOneUserDetail($create: UserDetailCreateInput!, $update: UserDetailUpdateInput!, $where: UserWhereUniqueInput!) {
        upsertOneUserDetail(create: $create, update: $update, where: $where) {
            id
        }
    }
`;

export const UPSERT_ONE_USER_NOTIFICATION_SETTINGS = gql`
    mutation UpsertOneUserNotificationSettings($create: NotificationSettingsCreateInput!, $update: NotificationSettingsUpdateInput!, $where: UserWhereUniqueInput!) {
        upsertOneUserNotificationSettings(create: $create, update: $update, where: $where) {
            id
        }
    }
`;

export const UPDATE_ONE_USER_AVATAR = gql`
    mutation UpdateOneUserDetail($data: UserDetailUpdateInput!, $where: UserWhereUniqueInput!) {
        updateOneUserDetail(data: $data, where: $where) {
            detail {
                avatar {
                    filename
                    mimetype
                    url
                }
            }
        }
    }
`;

export const UPDATE_ONE_USER_EMAIL = gql`
    mutation UpdateOneUserEmail($data: UpdateUserEmailInput!) {
        updateOneUserEmail(data: $data)
    }
`;

export const VERIFY_UPDATE_ONE_USER_EMAIL = gql`
    mutation VerifyUpdateOneUserEmail($data: CheckTokenInput!, $where: UserWhereUniqueInput!) {
        verifyUpdateOneUserEmail(data: $data, where: $where) {
            email
            emailConfirmed
        }
    }
`;

export const UPDATE_ONE_USER_DETAIL = gql`
    mutation UpsertOneUserDetail($data: UserDetailUpdateInput!, $where: UserWhereUniqueInput!) {
        updateOneUserDetail(data: $data, where: $where) {
            detail {
                avatar {
                    filename
                    mimetype
                    url
                }
                position
                fullName
                id
                userId
            }
        }
    }
`;

export const UPDATE_ONE_USER_PASSWORD = gql`
    mutation UpdateOneUserPassword($data: UpdateUserPasswordInput!, $where: UserWhereUniqueInput!) {
        updateOneUserPassword(data: $data, where: $where) {
            id
        }
    }
`;

export const JOIN_ONE_WORKSPACE = gql`
    mutation JoinOneWorkspace($where: WorkspaceWhereUniqueInput!) {
        joinOneWorkspace(where: $where) {
            id
        }
    }
`;

export const EXIT_ONE_WORKSPACE = gql`
    mutation ExitOneWorkspace($where: WorkspaceWhereUniqueInput!) {
        exitOneWorkspace(where: $where) {
            id
        }
    }
`;

export const CREATE_ONE_WORKSPACE = gql`
    mutation CreateOneWorkspace($data: WorkspaceCreateInput!) {
        createOneWorkspace(data: $data) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const UPDATE_ONE_WORKSPACE = gql`
    mutation UpdateOneWorkspace($data: WorkspaceUpdateInput!, $where: WorkspaceWhereUniqueInput!) {
        updateOneWorkspace(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const DELETE_ONE_WORKSPACE = gql`
    mutation DeleteOneWorkspace($where: WorkspaceWhereUniqueInput!) {
        deleteOneWorkspace(where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const VERIFY_DELETE_ONE_WORKSPACE = gql`
    mutation VerifyDeleteOneWorkspace($data: CheckTokenInput!, $where: WorkspaceWhereUniqueInput!) {
        verifyDeleteOneWorkspace(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const CREATE_ONE_USER_WORKSPACE = gql`
    mutation CreateOneUserWorkspace($data: UserWorkspaceCreateInput!) {
        createOneUserWorkspace(data: $data) {
            id
            workspaceId
            workspace {
                slug
            }
        }
    }
`;

export const CREATE_ONE_WORKSPACE_DOMAIN = gql`
    mutation CreateOneWorkspaceDomain($data: WorkspaceDomainInput!, $where: WorkspaceWhereUniqueInput!) {
        createOneWorkspaceDomain(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const REMOVE_ONE_WORKSPACE_DOMAIN = gql`
    mutation RemoveOneWorkspaceDomain($data: WorkspaceDomainInput!, $where: WorkspaceWhereUniqueInput!) {
        removeOneWorkspaceDomain(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const ENABLE_ONE_WORKSPACE_DOMAIN = gql`
    mutation EnableOneWorkspaceDomain($data: WorkspaceDomainInput!, $where: WorkspaceWhereUniqueInput!) {
        enableOneWorkspaceDomain(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const DISABLE_ONE_WORKSPACE_DOMAIN = gql`
    mutation DisableOneWorkspaceDomain($data: WorkspaceDomainInput!, $where: WorkspaceWhereUniqueInput!) {
        disableOneWorkspaceDomain(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const VERIFY_ONE_WORKSPACE_DOMAIN = gql`
    mutation VerifyOneWorkspaceDomain($data: CheckTokenInput!, $where: WorkspaceWhereUniqueInput!) {
        verifyOneWorkspaceDomain(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const SEND_ONE_WORKSPACE_DOMAIN_TOKEN = gql`
    mutation SendOneWorkspaceDomainToken($data: WorkspaceDomainInput!, $where: WorkspaceWhereUniqueInput!) {
        sendOneWorkspaceDomainToken(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const CREATE_ONE_PROJECT = gql`
    mutation CreateOneProject($data: ProjectCreateInput!) {
        createOneProject(data: $data) {
            id
            name
            description
            owner {
                id
                detail {
                    fullName
                }
            }
            createdAt
            collaboratorIDs
            topicIDs
            goalIDs
            status
            isPublic
            workspace {
                id
                slug
            }
        }
    }
`;

export const UPDATE_ONE_WORKSPACE_LOGO = gql`
    mutation UpdateOneWorkspace($data: WorkspaceUpdateInput!, $where: WorkspaceWhereUniqueInput!) {
        updateOneWorkspace(data: $data, where: $where) {
            logo {
                filename
                mimetype
                url
            }
        }
    }
`;


export const UPDATE_ONE_WORKSPACE_INVITATION = gql`
    mutation UpdateOneWorkspaceInvitation($data: WorkspaceInvitationUpdateInput!, $where: WorkspaceInvitationWhereUniqueInput!) {
        updateOneWorkspaceInvitation(data: $data, where: $where) {
            id
            inviteeEmail
            role
        }
    }
`;

export const UPDATE_ONE_WORKSPACE_MEMBER_ROLE = gql`
    mutation UpdateOneWorkspaceMemberRole($data: WorkspaceMemberRoleInput!, $where: WorkspaceWhereUniqueInput!) {
        updateOneWorkspaceMemberRole(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const DEACTIVATE_ONE_WORKSPACE_MEMBER = gql`
    mutation DeactivateOneWorkspaceMember($data: WorkspaceMemberInput!, $where: WorkspaceWhereUniqueInput!) {
        deactivateOneWorkspaceMember(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const REACTIVATE_ONE_WORKSPACE_MEMBER = gql`
    mutation ReactivateOneWorkspaceMember($data: WorkspaceMemberInput!, $where: WorkspaceWhereUniqueInput!) {
        reactivateOneWorkspaceMember(data: $data, where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const INVITE_TEAM_TO_ONE_USER_WORKSPACE = gql`
    mutation InviteTeamToOneUserWorkspace($data: UserWorkspaceInviteTeamInput!, $where: UserWorkspaceWhereUniqueInput!) {
        inviteTeamToOneUserWorkspace(data: $data, where: $where)
    }
`;

export const UPDATE_ONE_PROJECT = gql`
    mutation UpdateOneProject($data: ProjectUpdateInput!, $where: ProjectWhereUniqueInput!) {
        updateOneProject(data: $data, where: $where) {
            id
            name
            description
            owner {
                id
                detail {
                    fullName
                }
            }
            createdAt
            collaboratorIDs
            topicIDs
            goalIDs
            status
            isPublic
            workspace {
                id
                slug
            }
        }
    }
`;

export const DELETE_ONE_PROJECT = gql`
    mutation DeleteOneProject($where: ProjectWhereUniqueInput!) {
        deleteOneProject(where: $where) {
            id
        }
    }
`;

export const DELETE_ONE_POST = gql`
    mutation DeleteOnePost($where: PostWhereUniqueInput!) {
        deleteOnePost(where: $where) {
            id
        }
    }
`;

export const ARCHIVE_ONE_PROJECT = gql`
    mutation ArchiveOneProject($where: ProjectWhereUniqueInput!) {
        archiveOneProject(where: $where) {
            id
        }
    }
`;

export const CREATE_ONE_POST = gql`
    mutation CreateOnePost($data: PostCreateInput!) {
        createOnePost(data: $data) {
            id
            title
            comment
            createdAt
            attachments {
                filename
                mimetype
                url
            }
            author {
                detail {
                    fullName
                    avatar {
                        filename
                        mimetype
                        url
                    }
                }
            }
        }
    }
`;

export const UPDATE_ONE_POST = gql`
    mutation UpdateOnePost($data: PostUpdateInput!, $where: PostWhereUniqueInput!) {
        updateOnePost(data: $data, where: $where) {
            id
            title
            comment
            createdAt
            attachments {
                filename
                mimetype
                url
            }
            author {
                detail {
                    fullName
                    avatar {
                        filename
                        mimetype
                        url
                    }
                }
            }
        }
    }
`;

export const CREATE_ONE_POST_COMMENT = gql`
    mutation CreateOnePostComment($data: PostCommentCreateInput!) {
        createOnePostComment(data: $data) {
            id
        }
    }
`;

export const CREATE_ONE_PROJECT_TOPIC = gql`
    mutation CreateOneProjectTopic($data: ProjectTopicCreateInput!) {
        createOneProjectTopic(data: $data) {
            id
            name
        }
    }
`;

export const CREATE_ONE_PROJECT_GOAL = gql`
    mutation CreateOneProjectGoal($data: ProjectGoalCreateInput!) {
        createOneProjectGoal(data: $data) {
            id
            name
        }
    }
`;

export const INVITE_TEAM_TO_ONE_WORKSPACE = gql`
    mutation InviteTeamToOneWorkspace($data: WorkspaceInviteTeamInput!, $where: WorkspaceWhereUniqueInput!) {
        inviteTeamToOneWorkspace(data: $data, where: $where)
    }
`;

export const DELETE_ONE_WORKSPACE_INVITATION = gql`
    mutation DeleteOneWorkspaceInvitation($where: WorkspaceInvitationWhereUniqueInput!) {
        deleteOneWorkspaceInvitation(where: $where) {
            id
        }
    }
`;

export const ACCEPT_ONE_WORKSPACE_INVITATION = gql`
    mutation AcceptOneWorkspaceInvitation($where: WorkspaceInvitationWhereUniqueInput!) {
        acceptOneWorkspaceInvitation(where: $where) {
            id
            accepted
            inviteeEmail
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(email: $email)
    }
`;

export const SINGLE_UPLOAD = gql`
    mutation SingleUpload($file: Upload!) {
        singleUpload(file: $file) {
            filename
            mimetype
            url
        }
    }
`;

export const UPDATE_ONE_POST_COMMENT = gql`
    mutation UpdateOnePostComment($data: PostCommentUpdateInput!, $where: PostCommentWhereUniqueInput!) {
        updateOnePostComment(data: $data, where: $where) {
            id
        }
    }
`;

export const READ_ONE_INBOX = gql`
    mutation ReadOneInbox($where: InboxWhereUniqueInput!) {
        readOneInbox(where: $where) {
            ...InboxFields
        }
    }
    ${INBOX_FIELDS}
`;

export const READ_ALL_INBOX = gql`
    mutation ReadAllInbox {
        readAllInbox {
            ...InboxFields
        }
    }
    ${INBOX_FIELDS}
`;
