import React, { ChangeEvent, useState } from 'react';
import classNames from "classnames";
// import InputMask from "react-input-mask";
import { AlertCirlcleError, CheckGoodYes } from "react-basicons";
import { theme } from "../lib/helpers";

interface PasswordInputProps {
  className?: string;
  domain?: string;
  error?: boolean;
  formatted?: boolean;
  id: string;
  loading?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  scheme?: 'http://' | 'https://';
  success?: boolean;
  inputTypeW_url?: boolean;
  value?: string;
}

const UrlInput: React.FC<PasswordInputProps> = props => {
  const {
    className,
    id,
    value = '',
    onChange,
    scheme = 'https://',
    domain = '',
    loading,
    success,
    error,
    placeholder,
    formatted = true,
    inputTypeW_url
  } = props;

  const [focused, setFocused] = useState(false);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let value: string = scheme;

    if (domain) value += domain + '/';
    if (e.target.value) value += e.target.value;
    else value = e.target.value;
    if (!formatted) value = e.target.value;

    onChange(value);
  }

  let suffixIcon;

  if (success) suffixIcon = <CheckGoodYes size={16} color={(theme.textColor.green as any)['600']} weight={2} />;

  if (error) suffixIcon = <AlertCirlcleError size={16} color={(theme.textColor.red as any)['500']} weight={2} />;

  if (loading) suffixIcon =
    <img src="/images/CircleNotch.svg" className="animate-spin" alt="loading..." />;

  let inputValue = value?.split(domain ? scheme + domain + '/' : scheme).join('');

  if (!formatted) inputValue = value;

  return (
    <div className={classNames("link-input relative w-full")}>
      <div className="absolute pointer-events-none text-body-small regular top-0 left-0 flex items-center">
        <label
          htmlFor={id}
          className="px-4"
        >
          {scheme}
        </label>
        <div className="v-line w-px h-[38px] mt-[1px] bg-[var(--border-input-default)]"></div>
        <label
          className="pl-4"
          htmlFor={id}
        >
          {domain}{domain && '/'}
        </label>
      </div>
      <input
        id={id}
        onChange={onChangeInput}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={placeholder}
        value={inputValue}
        className={classNames(className, 'input-clear input w-full placeholder:!lowercase')}
        style={{ paddingLeft: inputTypeW_url ? '188px' : '90px' }}
      />
      {suffixIcon && (
        <div className="absolute top-3 right-4">
          {suffixIcon}
        </div>
      )}
    </div>
  );
};

export default UrlInput;
