import React, { Fragment, ReactNode } from 'react';
import { Menu, Transition } from "@headlessui/react";
import { formatDistance, parseISO } from "date-fns";
import { IProject } from "src/lib/types";
import SectionWrap from './UI/SectionWrap';
import { Archive, ClockOutline, Copy, Edit, ThreeDots, Trash, UserEdit } from 'src/constants/icons';
import { DropItem } from './UI/DropGroup';
import IconButton from './UI/Button/IconButton';

function EditProjectHeading({ project, handleEdit, handleDelete, handleDArchive, handleCopyLink, userId, children }: {
  userId: string;
  project: IProject;
  children: ReactNode;
  handleEdit: Function;
  handleDArchive: Function;
  handleDelete: Function;
  handleCopyLink: Function;
}) {
  const isProjectOwner = userId === project.owner.id;

  return (
    <SectionWrap>
      <div className='p-6 flex flex-col gap-10 items-start w-full'>
        <div className='flex flex-col gap-4 items-start w-full'>
          {/* project head , description */}
          <div className="flex flex-col gap-3 items-start w-full">
            <div className="flex items-center justify-between w-full">
              <h1 className="text-heading-3 bold text-[var(--text-default)]">
                {project.name}
              </h1>
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className={`${open ? 'bg-[var(--button-neutral-hover-clicked)]' : ''} rounded`}>
                        <IconButton
                          size='micro'
                          type='ghost'
                          icon={<ThreeDots />}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button>
                                <DropItem
                                  item={{
                                    name: 'Copy Link',
                                    icon: <Copy />
                                  }}
                                  onItemClick={() => handleCopyLink()}
                                />
                              </button>
                            )}
                          </Menu.Item>
                          {isProjectOwner && (
                            <Menu.Item>
                              {({ active }) => (
                                <button>
                                  <DropItem
                                    item={{
                                      name: `Edit Project`,
                                      icon: <Edit />
                                    }}
                                    onItemClick={() => handleEdit()}
                                  />
                                </button>
                              )}
                            </Menu.Item>
                          )}
                          {isProjectOwner && (
                            <>
                              <Menu.Item>
                                {({ active }) => (
                                  <button>
                                    <DropItem
                                      item={{
                                        name: `Archive project`,
                                        icon: <Archive />
                                      }}
                                      onItemClick={() => handleDArchive()}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button>
                                    <DropItem
                                      item={{
                                        name: `Delete project`,
                                        icon: <Trash />
                                      }}
                                      onItemClick={() => handleDelete( handleDArchive,)}
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                            </>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
            <article
              dangerouslySetInnerHTML={{ __html: project.description }}
              className="ck ck-content text-[var(--text-neutral)] text-body-small regular"
            />
          </div>
          {/* author and created time */}
          <ul className="flex items-center gap-4 text-body-micro medium text-[var(--text-neutral)]">
            <li className="flex gap-1.5 items-center">
              <UserEdit />
              <span className=''>{project.owner.detail.fullName}</span>
            </li>
            <li className="flex gap-1.5 items-center">
              <ClockOutline />
              <div>
                {formatDistance(parseISO(project.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </div>
            </li>
          </ul>
        </div>
        {children}
      </div>
    </SectionWrap>
  );
}

export default EditProjectHeading;
