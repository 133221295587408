import React, { useState, useEffect, useRef } from 'react';
import IconButton from './Button/IconButton';
import { Check, Copy, ThreeDots } from 'src/constants/icons';

interface LinksProps {
    icon: React.ReactNode;
    value: string;
    onClick: () => void;
    href: string;
    showCopy?: boolean;
    showMore?: boolean;
    active?: boolean;
}

const Links: React.FC<LinksProps> = ({ icon, value, onClick, href, showCopy = true, showMore = false, active }) => {
    const [isCopied, setIsCopied] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const handleIconClick = () => {
        onClick();
        setIsCopied(true);

        // Clear the existing timer if it exists
        if (timerRef.current) {
            setIsCopied(false);
            clearTimeout(timerRef.current);
            setIsCopied(true);
        }

        // Set a new timer
        timerRef.current = setTimeout(() => {
            setIsCopied(false);
        }, 500);
    }

    function handleLinkMoreBtn() {
        onClick()
    }

    // Cleanup the timer on component unmount
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (<div
        className={`group flex items-center gap-5 p-1 h-7 max-w-52 w-full rounded-lg hover:bg-[var(--backgrounds-hover-clicked)] transition-colors duration-200 relative ${active ? 'bg-[var(--backgrounds-hover-clicked)]' : ''
            }`}
    >
        <a href={href} target="_blank" className="flex items-center gap-2 flex-1 pr-6 truncate no-underline">
            <div>
                {icon}
            </div>
            <div className="text-body-micro text-[var(--text-neutral)] medium capitalize truncate" title={value}>
                {value}
            </div>
        </a>
        {showCopy && <div className="absolute right-1 opacity-0 group-hover:opacity-100">
            {/* <div className="link-icon-container" style={{ '--scale-factor': isCopied ? 1.1 : 1 } as React.CSSProperties}> */}
            <IconButton
                type='ghost'
                size='micro'
                icon={
                    <div
                        className="transition-all duration-200"
                        style={{ '--scale-factor': isCopied ? 1.1 : 1 } as React.CSSProperties}
                    >
                        {isCopied ? <Check /> : <Copy />}
                    </div>
                }
                onClick={handleIconClick}
            />
            {/* </div> */}
        </div>}
        {showMore && <div className="absolute right-1 opacity-0 group-hover:opacity-100">
            {/* <div className="link-icon-container" style={{ '--scale-factor': isCopied ? 1.1 : 1 } as React.CSSProperties}> */}
            <div onClick={handleLinkMoreBtn}>
                <IconButton
                    type='ghost'
                    size='micro'
                    icon={
                        <div
                            className="transition-all duration-200"
                            style={{ '--scale-factor': isCopied ? 1.1 : 1 } as React.CSSProperties}
                        >
                            <ThreeDots />
                        </div>
                    }
                    onClick={handleIconClick}
                />
            </div>
            {/* </div> */}
        </div>}
    </div>
    );
};

export default Links;
