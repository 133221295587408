'use client';

import React, { RefObject, useEffect, useRef, useState } from 'react';
// import Avatar from "src/components/Avatar";
import { formatDistance, parseISO } from "date-fns";
import {
  AdAnnouncementMegaphone,
  HashtagTrendingNumber,
} from "react-basicons";
import TextareaAutosize from "react-textarea-autosize";
// import Button from "src/components/Button";
import { IPost } from "src/lib/types";
import { useCreateOnePostComment } from "src/api/postComment/useCreateOnePostComment";
import PostComments from "src/components/PostComments";
import PostCommentMenu from "src/components/PostCommentMenu";
import { useLocalStorage } from "@uidotdev/usehooks";
import { getColorTag, transformToTitleCase } from "src/lib/helpers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Avatar from './UI/Avatar/Avatar';
import ColorTag from './UI/Tags/ColorTags';
import { ChatBubble, ChevronDown, MegaPhone, WorkStream } from 'src/constants/icons';
import StatusTag from './UI/Tags/StatusTags';
import ImageViewer from './UI/ImageViewer';
import Button from './UI/Button/Button';
import IconButton from './UI/Button/IconButton';
import { AnimatePresence, motion } from 'framer-motion';

function PostItem(props: {
  post: IPost;
  handleCopy: Function;
  handleEdit: Function;
  handleDelete: Function;
}) {
  const { post, handleCopy, handleDelete, handleEdit } = props;
  const navigate = useNavigate();
  const { projectId, workspaceSlug } = useParams();
  const [focused, setFocused] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [comment, setComment] = useState('');
  const [createOnePostComment, { loading }] = useCreateOnePostComment();
  const [userId] = useLocalStorage("userId", undefined);

  const [searchParams] = useSearchParams();
  const postId = searchParams.get('post');
  const commentId = searchParams.get('comment');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const isFocusedPost = postId === post.id;

  useEffect(() => {
    if (isFocusedPost && componentRef.current instanceof HTMLElement) {
      const element = componentRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      if (!commentId) {
        element.classList.add('bg-warning-50');
        setTimeout(() => {
          element.classList.remove('bg-warning-50');
        }, 2000);
      }
    }
  }, [isFocusedPost]);

  const handleCancel = () => {
    requestAnimationFrame(() => {
      setFocused(false);
    });
  }

  const handlePostComment = () => {
    if (comment.length) {
      createOnePostComment({
        variables: {
          data: {
            comment,
            post: {
              connect: { id: post.id }
            }
          }
        },
        onCompleted: data => {
          setComment('');
          setFocused(false);
        }
      })
    }
  }

  const isPostOwner = userId === post.author.id;
  function gotoCommentPage(e: string) {
    // navigate(`workspace/${workspaceSlug}/project/${projectId}/c/${e}`);
    navigate(`c/${e}`);
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      // Measure the height of 6 lines
      const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
      // console.log(parseFloat(getComputedStyle(contentRef.current).lineHeight), 'parseFloat(getComputedStyle(contentRef.current).lineHeight)')
      const maxHeight = lineHeight * 6;

      // Check if content height exceeds the height of 6 lines
      // console.log(contentRef.current.scrollHeight, 'contentRef.current.scrollHeight')
      if (contentRef.current.scrollHeight > maxHeight) {
        setIsTruncated(true);
      }
    }
  }, [post.comment]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setIsHovered(!isHovered)
  };

  // console.log(post.comment, 'post.comments')

  return (
    <div ref={componentRef} className="transition-all duration-1000 p-6 space-y-6">
      <div className="flex items-start gap-4 flex-col md:flex-row md:items-center">
        <div className='flex items-center w-full'>
          <div className="flex-1 flex gap-2 items-center">
            <Avatar
              name={post.author.detail.fullName}
              src={post.author.detail.avatar?.url}
              loading={loading}
              alt={`${post.author.detail.fullName} profile picture at overlap workspace`}
              size={28}
            />
            <div className="">
              <div className="text-body-small medium text-[var(--text-default)] capitalize">
                {post.author.detail.fullName}
              </div>
              <div className="text-[var(--text-neutral)] text-body-micro regular">
                Posted {formatDistance(parseISO(post.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </div>
            </div>
          </div>
          <span className='block md:hidden'>
            <PostCommentMenu
              canEdit={isPostOwner}
              canArchive={isPostOwner}
              canDelete={isPostOwner}
              handleCopy={handleCopy}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </span>
        </div>

        <div className="flex gap-4 items-center">
          <ul className="flex gap-2 items-start">
            {post.topic && (
              <li>
                <ColorTag
                  value={post.topic.name}
                  size='medium'
                  type='filled'
                  color={getColorTag(post.topic.name)}
                  leftIcon={<WorkStream />}
                />
              </li>
            )}
            {post.type && (
              <li>
                <StatusTag
                  value={transformToTitleCase(post.type)}
                  size='medium'
                  type='filled'
                  color='default'
                  leftIcon={<MegaPhone className='w-3 h-3' />}
                />
              </li>
            )}
          </ul>

          
          <span className='hidden md:block'>
            <PostCommentMenu
              canEdit={isPostOwner}
              canArchive={isPostOwner}
              canDelete={isPostOwner}
              handleCopy={handleCopy}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </span>
        </div>
      </div>

      <div className="space-y-2 cursor-pointer">
        <h2 className="text-heading-6 medium text-[var(--text-default)]">
          {post.title}
        </h2>

        <div className="w-full relative">
          <article
            ref={contentRef}
            onClick={() => gotoCommentPage(post?.id)}
            dangerouslySetInnerHTML={{ __html: post.comment }}
            className={`ck ck-content text-[var(--text-default)] ${isExpanded ? '' : 'article truncate-lines'}`}
          ></article>
          {isTruncated && (
            <div
              className='absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end'
              style={{ background: (!isExpanded || isHovered) ? 'var(--truncate-bg)' : 'none' }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <AnimatePresence>
                {
                  isExpanded && isHovered &&
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <IconButton
                      icon={<ChevronDown className='rotate-180' />}
                      size={'standard'}
                      type={'neutral'} onClick={toggleExpand}
                    />
                  </motion.div>
                }

                {!isExpanded &&
                  <IconButton
                    icon={<ChevronDown />}
                    size={'standard'}
                    type={'neutral'} onClick={toggleExpand}
                  />
                }
              </AnimatePresence>
            </div>
          )}
        </div>

      </div>

      {post.attachments.length > 0 && (
        <ul className="flex space-x-2">
          {post.attachments.map((media, key) => (
            <li key={key} className='flex-1'>
              <img
                onClick={() => {
                  setShowImageModal(true)
                  setCurrentImage(key)
                }}
                className="aspect-square bg-grey-100 rounded-lg object-cover"
                src={[process.env.REACT_APP_API_URL, media.url].join('/')}
                alt={media.filename}
              />
            </li>
          ))}
        </ul>
      )}

      {showImageModal && (
        <ImageViewer
          images={post.attachments}
          currentImage={currentImage}
          setCurrentImage={(e: number) => setCurrentImage(e)}
          setShowImageModal={() => setShowImageModal(false)}
        />

      )}

      {/* Comments */}
      <div className='flex items-center gap-1 relative group cursor-pointer'
        onClick={() => gotoCommentPage(post?.id)}
      >
        <div className="avatars-group flex items-center -space-x-1">
          {post?.comments.map((comment, key) => (
            <React.Fragment key={key}>
              <div>
                <Avatar
                  name={comment?.author?.detail.fullName}
                  src={comment?.author?.detail?.avatar?.url}
                  alt={`${comment?.author?.detail.fullName} profile picture`}
                  size={16}
                  className='border border-[var(--backgrounds-on-canvas)]'
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="comments">
          <StatusTag
            color={'default'}
            size={'medium'}
            value={post.comments.length}
            leftIcon={<ChatBubble className='w-3 h-3' />}
          />
        </div>
        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <ChevronDown className='-rotate-90' />
        </div>
      </div>


      {/* <div className="space-y-2">
        <div className="space-y-4">
          <TextareaAutosize
            value={comment}
            onChange={e => setComment(e.target.value)}
            onFocus={() => setFocused(true)}
            disabled={loading}
            placeholder="Comment on this post"
            className="resize-none w-full bg-grey-25 focus:bg-white placeholder:text-grey-200 text-sm shadow-none !border-grey-25"
          />

          {focused && (
            <div className="flex items-center justify-end space-x-3">
              <Button
                onClick={handleCancel}
                type="button"
                size="small"
              >
                Cancel
              </Button>
              <Button
                onClick={handlePostComment}
                type="button"
                color="primary"
                size="small"
                loading={loading}
                disabled={loading}
              >
                Post comment
              </Button>
            </div>
          )}
        </div>
        <PostComments
          comments={post.comments}
          defaultShow={Boolean(isFocusedPost && commentId)}
        />
      </div> */}
    </div>
  );
}

export default PostItem;
