import { Outlet } from "react-router-dom"
import SettingSideNav from "src/components/sideNav/SettingSideNav"
import SideNav from "src/components/sideNav/SideNav"


export default function SettingsLayout() {
  return (
    <div className="flex fixed min-h-screen w-full">
      <SideNav>
        <SettingSideNav />
      </SideNav>
      <div className="outlet">
        <div className="outlet_inner relative">
          <Outlet />
        </div>
      </div>
    </div>
  )
}