import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { AddPlus, Search, XCloseDelete } from "react-basicons";
import { Popover, Transition } from "@headlessui/react";
import { useProjectGoals } from "src/api/useProjectGoals";
import { deepEqual, fuzzySearchRegex, stopPropagation, theme } from "src/lib/helpers";
// import Button from "src/components/Button";
import usePrevious from "src/hooks/usePrevious";
import { useDebounce } from "@uidotdev/usehooks";
import { IProjectGoal } from "src/lib/types";
import { useCreateOneProjectGoal } from "src/api/useCreateOneProjectGoal";
import { PROJECT_GOALS } from "src/lib/queries";
import classNames from "classnames";
import StatusTag from './UI/Tags/StatusTags';
import Button from './UI/Button/Button';
import { Check, Plus } from 'src/constants/icons';
import Badge from './UI/Badge';
import HorizontalDivider from './UI/HorizontalDivider';

interface ProjectGoalsSelectProps {
  workspaceSlug: string;
  projectId?: string;
  value: String[];
  onChange: Function;
  readOnly?: boolean;
}

const ProjectGoalsSelect: React.FC<ProjectGoalsSelectProps> = props => {
  const { value, onChange, projectId, readOnly = true, workspaceSlug } = props;
  const queryVariables = {
    where: {
      workspace: {
        slug: {
          equals: workspaceSlug
        }
      }
    }
  };
  const { loading: projectGoalsLoading, data } = useProjectGoals({
    variables: queryVariables
  });
  const [selectedOptions, setSelectedOptions] = useState<IProjectGoal[]>([]);
  const prevSelectedOptions = usePrevious(selectedOptions);

  const [searchTerm, setSearchTerm] = React.useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const regex = fuzzySearchRegex(debouncedSearchTerm);
  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const searchTermFilter = (goal: IProjectGoal) => {
    if (debouncedSearchTerm.length) {
      return regex.test(goal.name);
    }
    return true;
  };

  const [createOneProjectGoal, { loading: createOneProjectGoalLoading }] = useCreateOneProjectGoal();
  const loading = projectGoalsLoading || createOneProjectGoalLoading;

  // create custom project goal option
  const handleAddProjectGoal = () => {
    if (searchTerm.length) {
      createOneProjectGoal({
        variables: {
          data: {
            name: searchTerm,
            workspace: {
              connect: {
                slug: workspaceSlug
              }
            }
          }
        },
        onCompleted: ({ createOneProjectGoal }) => {
          setSearchTerm('');
          setSelectedOptions([...selectedOptions, createOneProjectGoal]);
        },
        update: (cache, { data: { createOneProjectGoal } }) => {
          const { projectGoals }: any = cache.readQuery({ query: PROJECT_GOALS, variables: queryVariables });
          cache.writeQuery({
            query: PROJECT_GOALS,
            variables: queryVariables,
            data: {
              projectGoals: [...projectGoals, createOneProjectGoal]
            }
          });
        }
      });
    }
  }

  // watch selected options
  useEffect(() => {
    if (!loading && prevSelectedOptions && prevSelectedOptions.length !== selectedOptions.length) {
      onChange(selectedOptions.map(({ id }) => id));
    }
  }, [loading, prevSelectedOptions, selectedOptions]);

  const handleCheckboxChange = (option: IProjectGoal) => {
    if (selectedOptions.findIndex(each => deepEqual(each, option)) >= 0) {
      setSelectedOptions(selectedOptions.filter((item) => !deepEqual(item, option)));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  let button = (
    <>
      {readOnly ? <StatusTag
        type='filled'
        size='medium'
        color='default'
        value='No goals yet'
      /> :
        <div className='button ghost small justify-start w-fit transition !pl-1.5'>
          <Plus />
          <span className='text-[var(--text-on-bg)]  text-body-micro font-medium'>Add goals</span>
        </div>
      }
    </>
  );

  if (selectedOptions.length) {
    button = projectId ? (
      <ul className="flex flex-col items-start space-y-2">
        {selectedOptions.map(option => (
          <li key={option.id} className="tag">
            {option.name}
          </li>
        ))}
      </ul>
    ) : (
      <ul className="flex flex-wrap items-center -my-0.5 py-1">
        {selectedOptions.slice(0, 1).map(option => (
          <li key={option.id} className="tag mr-2 my-0.5">
            <span>{option.name}</span>
            <div className="cursor-pointer" onClick={stopPropagation(() => handleCheckboxChange(option))}>
              <XCloseDelete size={10} color={(theme.textColor.grey as any)['200']} weight={2} />
            </div>
          </li>
        ))}
        <li>
          <div className='w-5 h-5 flex items-center justify-center rounded-md bg-[var(--backgrounds-default)] mr-2'>
            {selectedOptions.length}
          </div>
        </li>
        {Boolean(projectId) || (
          <li className="my-0.5">
            <Plus />
          </li>
        )}
      </ul>
    )
  }

  const projectGoals = data?.projectGoals ?? [];
  const prevProjectGoals = usePrevious(projectGoals);
  const filteredProjectGoals = projectGoals.filter(searchTermFilter);

  useEffect(() => {
    if (prevProjectGoals?.length !== projectGoals.length && projectGoals.length) {
      setSelectedOptions(
        projectGoals.filter((each: IProjectGoal) => value?.includes(each.id))
      );
    }
  }, [projectGoals, prevProjectGoals]);

  const noSearchResults = filteredProjectGoals.length === 0 && debouncedSearchTerm.length > 0;

  return (
    <Popover className={`relative ${Boolean(selectedOptions.length) || readOnly ? '' : '-ml-1'}`}>
      {({ open }) => (
        <>
          {readOnly && button}
          {!readOnly && <>
            <Popover.Button
              className={`${open ? '' : ''} justify-start`}
            >
              {button}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-10 mt-3 min-w-60 max-w-full space-y-4">
                <div
                  className="max-h-[470px] overflow-y-auto drop-group p-1">
                  {/* <div className="sticky top-0 z-10 bg-[var(--backgrounds-dropdowns)]">
                    {readOnly || (
                      <div className="relative bg-[var(--backgrounds-dropdowns)]">
                        <label
                          htmlFor="goals-search"
                          className="absolute left-0 inset-y-0 px-3 grid place-content-center"
                        >
                          <Search size={12} />
                        </label>
                        <input
                          value={searchTerm}
                          onChange={handleSearchTermChange}
                          id="goals-search"
                          type="text"
                          className="input-clear !bg-transparent !h-[34px] py-3 !pl-2 !pr-2 w-full text-[var(--text-default)] text-body-small regular placeholder:text-[var(--text-neutral)]"
                          placeholder="Add Goals"
                          disabled={createOneProjectGoalLoading}
                        />
                        <div className="absolute right-0 inset-y-0 px-3 grid place-content-center">
                          <Button
                            type="button"
                            size="small"
                            loading={createOneProjectGoalLoading}
                            disabled={createOneProjectGoalLoading}
                            onClick={handleAddProjectGoal} value={''} btnType={'ghost'}                        >
                            Add
                          </Button>
                        </div>
                      <HorizontalDivider />
                      </div>
                    )}
                    {noSearchResults || (
                      <div className="text-center p-4 text-body-micro regular text-[var(--text-neutral)] whitespace-break-spaces">
                        Goals
                      </div>
                    )}
                  </div> */}
                  <div className="capitalize">
                    {noSearchResults && (
                      <p className="text-center p-4 text-body-micro regular text-[var(--text-neutral)] whitespace-break-spaces">
                        No search found for “<span className="font-semibold">{debouncedSearchTerm}</span>”
                      </p>
                    )}
                    <ul className='gap-1 flex flex-col'>
                      {filteredProjectGoals.map((goal: IProjectGoal) => (
                        <li key={goal.id}>
                          <label className={
                            classNames("flex items-center px-2", { 'cursor-pointer': !readOnly })
                          }>
                            <div className="flex-1 text-body-small medium text-[var(--text-neutral)]">
                              {goal.name}
                            </div>
                            {readOnly || (
                              <>
                                {selectedOptions.some(({ id }: IProjectGoal) => (id === goal.id)) ?
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.793 1.33398H5.20634C2.77967 1.33398 1.33301 2.78065 1.33301 5.20732V10.7873C1.33301 13.2207 2.77967 14.6673 5.20634 14.6673H10.7863C13.213 14.6673 14.6597 13.2207 14.6597 10.794V5.20732C14.6663 2.78065 13.2197 1.33398 10.793 1.33398ZM11.1863 6.46732L7.40634 10.2473C7.31301 10.3407 7.18634 10.394 7.05301 10.394C6.91967 10.394 6.79301 10.3407 6.69967 10.2473L4.81301 8.36065C4.61967 8.16732 4.61967 7.84732 4.81301 7.65398C5.00634 7.46065 5.32634 7.46065 5.51967 7.65398L7.05301 9.18732L10.4797 5.76065C10.673 5.56732 10.993 5.56732 11.1863 5.76065C11.3797 5.95398 11.3797 6.26732 11.1863 6.46732Z" fill="#8178EF" />
                                  </svg>
                                  :
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1.83301" y="1.83398" width="12.3333" height="12.3333" rx="2.16667" fill="none" stroke="var(--border-default)" />
                                  </svg>

                                }
                                <input
                                  type="checkbox"
                                  disabled={readOnly}
                                  className=" sr-only !rounded bg-transparent input-clear"
                                  style={{ border: '1px solid #fff' }}
                                  checked={selectedOptions.some(({ id }: IProjectGoal) => (id === goal.id))}
                                  onChange={() => handleCheckboxChange(goal)}
                                />
                              </>
                            )}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>}
        </>
      )}
    </Popover>
  );
}

export default ProjectGoalsSelect;