import React from 'react';
import '../ui.css'
import { colorStyles } from 'src/lib/ColorPreset';

// Extend the CSSProperties interface to include --fill
declare module 'react' {
    interface CSSProperties {
        '--fill'?: string;
    }
}

interface TagProps {
    color: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H';
    size: 'large' | 'medium' | 'small';
    type: 'filled' | 'stroke';
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    value: string;
}

const sizeStyles = {
    large: { fontSize: '16px', padding: '8px 16px' },
    medium: { fontSize: '12px', padding: '0px 6px', height: '20px', borderRadius: '6px', gap: '4px' },
    small: { fontSize: '12px', padding: '4px 8px' },
};

const ColorTag: React.FC<TagProps> = ({ color, size, type, leftIcon, rightIcon, value }) => {
    const styles: React.CSSProperties = {
        ...colorStyles[color],
        ...sizeStyles[size],
        border: type === 'stroke' ? `1px solid ${colorStyles[color].color}` : 'none',
        display: 'flex',
        alignItems: 'center',
        '--fill': colorStyles[color].color as string,
    };

    return (
        <div className={`color-tag w-fit ${color}`} style={styles}>
            {leftIcon && <div className="icon left-icon">{leftIcon}</div>}
            <span className="medium">{value}</span>
            {rightIcon && <div className="icon right-icon">{rightIcon}</div>}
        </div>
    );
};

export default ColorTag;
