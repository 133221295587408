import { IProjectLink } from "src/lib/types";

function convertCamelToText(name: string): string {
    return name
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

function validateEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};

function validatePassword(password: string) {
    // Customize password validation rules as needed
    // Here's an example with minimum length and character types:
    return password.length >= 4
    //  &&
    //   /[a-z]/.test(password) &&
    //   /[A-Z]/.test(password) &&
    //   /[0-9]/.test(password);
};

function fixUrl(urlObj: IProjectLink) {
    const { label, url } = urlObj;
    const countHttps = (url.match(/https:/g) || []).length;

    if (countHttps > 1) {
        const fixedUrl = url.replace(/https:\/\/https\/\//, "https://");
        return { label, url: fixedUrl };
    } else if (countHttps === 0) {
        const fixedUrl = `https://${url}`;
        return { label, url: fixedUrl };
    } else {
        return { label, url };
    }
}

// function fixUrl(urlObj: IProjectLink) {
//     const { label, url } = urlObj;
//     const urlObject = new URL(url);
//     if (urlObject.hostname.startsWith('https://')) {
//         urlObject.hostname = urlObject.hostname.replace('https://', '');
//     }
//     const fixedUrl = `${urlObject.protocol}//${urlObject.hostname}${urlObject.pathname}${urlObject.search}${urlObject.hash}`;
//     return { label, url: fixedUrl };
// }

export {
    convertCamelToText,
    validateEmail,
    validatePassword,
    fixUrl
}