import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {DELETE_ONE_POST} from "src/lib/mutations";
import {POSTS} from "src/lib/queries";

export function useDeleteOnePost() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }
  } : {}


  return useMutation(DELETE_ONE_POST, {
    ...options,
    refetchQueries: [POSTS],
  });
}
