"use client";

import React, { useState } from "react";
import Modal from 'src/components/UI/Modals/Modal';
import Button from "src/components/Button";
import { TrashDeleteBin } from "react-basicons";
import ToastMessage from "src/components/ToastMessage";
import toast from "react-hot-toast";
import { IPost } from "src/lib/types";
import { useDeleteOnePost } from "src/api/post/useDeleteOnePost";
import { motion, AnimatePresence } from 'framer-motion'
import HeaderText from "./UI/HeaderTexts";
import { Close } from "src/constants/icons";
import IconButton from "./UI/Button/IconButton";

export default function DeletePost(props: {
  show: boolean,
  handleClose: Function,
  post?: IPost,
}) {
  const { show, handleClose, post } = props;
  const [deleteOnePost, { loading: deleteOnePostLoading }] = useDeleteOnePost();

  const handleDelete = () => {
    if (post) {
      deleteOnePost({
        variables: {
          where: { id: post.id }
        },
        onCompleted: data => {
          toast(t => (
            <ToastMessage
              id={t.id}
              icon={<TrashDeleteBin size={16} weight={3} />}
              title="This post has been deleted."
            />
          ));
          handleClose();
        },
        onError: error => {
          toast(t => (
            <ToastMessage
              id={t.id}
              icon={<TrashDeleteBin size={16} weight={3} />}
              title={"There was an error deleting this post."}
            >
              {error.message}
            </ToastMessage>
          ));
        },
      });
    }
  }

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center">
          <Modal open={show} data={{
            subtitle: "You’re about to delete this post",
            snippet: "Are you sure you want to delete this post? You cannot undo this action.",
            btn1: "Cancel",
            btn2: "Delete",
            btn1Type: "neutral",
            btn2Type: "destructive"
          }}
            onBtn1Click={() => handleClose()}
            onBtn2Click={() => handleDelete()}
          />
        </motion.div>
      )}
      {/* <Modal
        as={'form'}
        size="small"
        show={show}
        handleClose={handleClose}
      >
        <div className="space-y-8">
          <div className="space-y-4">
            <p className="text-base text-grey-900 font-medium">
              You’re about to delete this post
            </p>
            <p>
              Are you sure you want to delete this post?
              You cannot undo this action.
            </p>
          </div>

          <div className="flex justify-end">
            <div className="space-x-3">
              <Button
                onClick={() => handleClose()}
                type="button"
                size="medium"
                color="ghost"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                type="button"
                size="medium"
                color="negative"
                loading={deleteOnePostLoading}
                disabled={deleteOnePostLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal> */}
    </AnimatePresence>
  )
}
