import '../ui.css'

interface InputProps {
    autoFocus?: boolean;
    type: string;
    children?: React.ReactNode;
    label?: string;
    placeholder?: string;
    password?: boolean;
    value: string;
    style?: React.CSSProperties;
    feedback: string | string[];
    feedbackType?: 'error' | 'success';
    className?: string;
    readonly?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
    autoFocus,
    type,
    children,
    label,
    placeholder,
    password,
    className,
    style,
    value,
    feedback,
    feedbackType,
    readonly,
    onChange,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    };

    return (
        <div className='input-wrap flex flex-col gap-2 w-full'>
            {label && <label className='text-body-small'>{label}</label>}
            <div className='relative w-full'>
                <input
                    className={`input w-full ${className} ${feedbackType}`}
                    style={style}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    autoFocus={autoFocus}
                    readOnly={readonly}
                    onChange={handleChange}
                />
                {password && <></>}
                {children}
            </div>
            {typeof feedback === 'string' && feedback.length > 1 ? (
                <p className={`text-body-micro feedback ${feedbackType === 'error' ? 'text-[var(--text-negative)]' : 'text-[var(--text-success)]'}`}>{feedback}</p>
            ) : Array.isArray(feedback) ? (
                <div className="text-body-micro feedback flex flex-col gap-1 items-start">
                    {feedback.map((item, index) => (
                        <p key={index} className={`${feedbackType === 'error' ? 'text-[var(--text-negative)]' : 'text-[var(--text-success)]'}`}>{item}</p>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Input;
