export interface IMedia {
  filename: string;
  mimetype: string;
  url: string;
}

export interface IWorkspace {
  id: string;
  logo?: IMedia;
  members: IUserWorkspace[];
  name: string;
  projects: IProject[];
  slug: string;
  website: string;
  invitations: IInvitation[];
  inviteToken: string;
  enableInviteToken: boolean;
  enableVerifiedDomains: boolean;
  domains: IWorkspaceDomain[];
}

export interface IWorkspaceDomain {
  id: string;
  domain: string;
  verificationEmail: string;
  verifiedAt?: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  workspace: IWorkspace;
  workspaceId: string;
}

export interface IInvitation {
  id: string;
  role: string;
  inviteeEmail: string;
}

export interface IUserWorkspace {
  id: string;
  userId: string;
  user: IUser;
  deactivatedAt: string;
  workspaceId: string;
  workspace: IWorkspace;
  isOwner: boolean;
  role: UserWorkspaceRole;
}

export interface IUser {
  id: string;
  role: UserRole;
  status: UserStatus;
  email: string;
  detail: {
    id: string;
    avatar?: IMedia;
    fullName: string;
    position: Position;
  }
}

export interface IProject {
  id: string;
  name: string;
  description: string;
  owner: IUser;
  createdAt: string;
  collaboratorIDs: string[];
  topicIDs: string[];
  goalIDs: string[];
  status: ProjectStatus;
  isPublic: boolean;
  workspace: IWorkspace;
  links: IProjectLink[];
}

export interface IProjectTopic {
  id: string;
  name: string;
}

export interface IProjectGoal {
  id: string;
  name: string;
}

export interface IProjectLink {
  label: string;
  url: string;
}

export interface IPostComment {
  id: string;
  author: IUser;
  comment: string;
  createdAt: string;
  responses: IPostComment[];
  post: IPost;
}

export interface IPost {
  attachments: IMedia[];
  author: IUser;
  comment: string;
  comments: IPostComment[];
  createdAt: string;
  id: string;
  project: IProject;
  title: string;
  topic: IProjectTopic;
  type: PostType;
  mentionedUserIDs: string[];
}

export interface IPosition {
  name: string;
  description?: string;
}

export interface ImageData {
  file: Blob;
  dataURL: string;
}

export interface IInbox {
  id: string;
  category: InboxCategory;
  createdAt: string;
  isRead: boolean;
  sender: IUser;
  type: InboxType;
  context:
    | AddedToProject
    | UpdatedProjectStatus
    | DeletedProject
    | NewProjectPost
    | NewPostCommentReplies
    | DeactivatedWorkspace
    | MentionedInPost
    | MentionedInComment
    | CommentInMentionedPost;
}

// Types

export type AddedToProject = {
  subject: string;
  projectOwnerFullName: string;
  projectName: string;
  projectDescription: string;
  collaboratorFullName: string;
};

export type UpdatedProjectStatus = {
  subject: string;
  collaboratorFullName: string;
  projectName: string;
  projectDescription: string;
  projectStatus: string;
};

export type DeletedProject = {
  subject: string;
  collaboratorFullName: string;
  projectName: string;
  projectDescription: string;
};

export type NewProjectPost = {
  subject: string;
  collaboratorFullName: string;
  projectName: string;
  postTitle: string;
  postBody: string;
  postAuthorFullName: string;
  postCreatedAt: string;
};

export type NewPostCommentReplies = {
  subject: string;
  postAuthorFullName: string;
  commentReply: string;
  commentAuthorFullName: string;
};

export type DeactivatedWorkspace = {
  subject: string;
  workspaceMemberFullName: string;
  workspaceName: string;
};

export type MentionedInPost = {
  subject: string;
  mentionedUserFullName: string;
  postAuthorFullName: string;
  postTitle: string;
  postComment: string;
};

export type MentionedInComment = {
  subject: string;
  mentionedUserFullName: string;
  commentAuthorFullName: string;
  comment: string;
};

export type CommentInMentionedPost = {
  subject: string;
  mentionedUserFullName: string;
  commentAuthorFullName: string;
  comment: string;
  postTitle: string;
};

// Enums

export enum InboxCategory {
  PROJECTS = "PROJECTS",
  MENTIONS = "MENTIONS",
}

export enum InboxType {
  ADDED_TO_PROJECT = "ADDED_TO_PROJECT",
  UPDATED_PROJECT_STATUS = "UPDATED_PROJECT_STATUS",
  DELETED_PROJECT = "DELETED_PROJECT",
  DELETED_PROJECT_PERMANENTLY = "DELETED_PROJECT_PERMANENTLY",
  MENTIONED_IN_COMMENT = "MENTIONED_IN_COMMENT",
  MENTIONED_IN_POST = "MENTIONED_IN_POST",
  COMMENTED_ON_POST = "COMMENTED_ON_POST",
  COMMENTED_ON_MENTIONED_POST = "COMMENTED_ON_MENTIONED_POST",
  REPLIED_TO_COMMENT = "REPLIED_TO_COMMENT",
  POSTED_ON_PROJECT = "POSTED_ON_PROJECT",
}

export enum ProjectStatus {
  PLANNING = "PLANNING",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED"
}

export enum PostType {
  GENERAL = "GENERAL",
  IDEAS = "IDEAS",
  DECISION = "DECISION",
  SUGGESTION = "SUGGESTION",
}

export enum Position {
  PRODUCT_MANAGER = "PRODUCT_MANAGER",
  ENGINEERING_LEAD = "ENGINEERING_LEAD",
  UX_DESIGNER = "UX_DESIGNER",
  FRONTEND_INTERN = "FRONTEND_INTERN",
  FRONTEND_ENGINEER = "FRONTEND_ENGINEER",
  HEAD_OF_COMPLIANCE = "HEAD_OF_COMPLIANCE",
}

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum UserWorkspaceRole {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}
