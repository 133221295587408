import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import Validator from "validatorjs";
import Modal from "src/components/Modal";
// import Button from "src/components/Button";
import ValidationErrorMessages from "src/components/ValidationErrorMessages";
import {useNavigate} from "react-router-dom";
import {useParams} from 'react-router-dom';
import {IProject, IProjectLink, ProjectStatus} from "src/lib/types";
import {useCreateOneProject} from "src/api/project/useCreateOneProject";
import EditProjectDetails from "src/components/EditProjectDetails";
import {useUpdateOneProject} from "src/api/project/useUpdateOneProject";
import usePrevious from "src/hooks/usePrevious";
import {deepEqual, deleteTypeNameKey} from "src/lib/helpers";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build';
import {AnimatePresence, motion} from 'framer-motion';
import HeaderText from "./UI/HeaderTexts";
import IconButton from "./UI/Button/IconButton";
import {Close} from "src/constants/icons";
import HorizontalDivider from "./UI/HorizontalDivider";
import Button from "./UI/Button/Button";

type FormData = {
  name: string;
  description: string;
  collaboratorIDs: string[];
  topicIDs: string[];
  goalIDs: string[];
  status: ProjectStatus;
  isPublic: boolean;
  links: IProjectLink[];
}

export default function EditProject({show, handleClose, project}: {
  show: boolean,
  // projectLead: {
  //   name: string;
  //   src: string;
  //   alt: string;
  //   position: string;
  // };
  handleClose: Function,
  project?: IProject,
}) {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {workspaceSlug} = useParams<{ workspaceSlug: string; }>();
  const [formData, setFormData] = useState<FormData>({
    name: project?.name ?? '',
    description: project?.description ?? '',
    collaboratorIDs: project?.collaboratorIDs ?? [],
    topicIDs: project?.topicIDs ?? [],
    goalIDs: project?.goalIDs ?? [],
    status: project?.status ?? ProjectStatus.PLANNING,
    isPublic: project?.isPublic ?? true,
    links: project?.links.map(deleteTypeNameKey) ?? [],
  });
  const prevProject = usePrevious(project);
  const [formErrors, setFormErrors] = useState<Validator.ValidationErrors | null>(null);
  const [createOneProject, {loading: createOneProjectLoading}] = useCreateOneProject();
  const [updateOneProject, {loading: updateOneProjectLoading}] = useUpdateOneProject();
  const loading = createOneProjectLoading || updateOneProjectLoading;

  useEffect(() => {
    if (prevProject && project && !deepEqual(prevProject, project)) {
      setFormData({
        name: project.name,
        description: project.description,
        collaboratorIDs: project.collaboratorIDs,
        topicIDs: project.topicIDs,
        goalIDs: project.goalIDs,
        status: project.status,
        isPublic: project.isPublic,
        links: project.links.map(deleteTypeNameKey),
      });
    }
  }, [prevProject, project]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 300)
    }
  }, [show]);

  const handleInputChange = (field: string) =>
    (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({...formData, [field]: value});
      setFormErrors(null);
    }

  const handleSubmit = () => {
    const validation = new Validator(formData, {
      name: 'required',
      description: 'required',
    });

    if (validation.passes()) {
      setFormErrors(null);

      if (project) {
        updateOneProject({
          variables: {
            data: {
              collaborators: {
                set: formData.collaboratorIDs.map(id => ({id}))
              },
              // projectLead: {
              //   set: formData.collaboratorIDs.map(id => ({ id }))
              // },
              goals: {
                set: formData.goalIDs.map(id => ({id}))
              },
              topics: {
                set: formData.topicIDs.map(id => ({id}))
              },
              description: {set: formData.description},
              isPublic: {set: formData.isPublic},
              name: {set: formData.name},
              status: {set: formData.status},
              links: {
                upsert: formData.links.map(link => ({
                  create: link,
                  update: {
                    label: {set: link.label},
                    url: {set: link.url},
                  },
                  where: {
                    url_projectId: {
                      projectId: project.id,
                      url: link.url,
                    }
                  }
                })),
              }
            },
            where: {id: project.id}
          },
          onCompleted: () => handleClose(),
        })
      } else {
        createOneProject({
          variables: {
            data: {
              collaborators: {connect: formData.collaboratorIDs.map(id => ({id}))},
              goals: {connect: formData.goalIDs.map(id => ({id}))},
              topics: {connect: formData.topicIDs.map(id => ({id}))},
              description: formData.description,
              isPublic: formData.isPublic,
              name: formData.name,
              status: formData.status,
              workspace: {connect: {slug: workspaceSlug}},
              links: formData.links.length ? {
                createMany: {data: formData.links}
              } : undefined
            }
          },
          onCompleted: ({createOneProject}) => {
            navigate(`/workspace/${workspaceSlug}/project/${createOneProject.id}`);
            handleClose();
          }
        });
      }
    } else {
      setFormErrors(validation.errors.all());
    }
  }

  const title = project ? 'Edit Project' : 'Create a project';
  const button = project ? 'Save' : 'Create project';

  return (
    <>
      <AnimatePresence>
        {show && (<motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className="fixed z-10 inset-0 bg-black/30"
          >
            <div className='fixed inset-0 flex w-screen items-start justify-center p-4 overflow-auto'>
              <motion.div
                initial={{opacity: 0, scale: 0.95}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 0.95}}
                className='group-container border mt-16 rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]'
              >
                <div className="flex items-center justify-between w-full p-5">
                  <HeaderText subTitle={title}/>
                  <IconButton icon={<Close/>} size={'micro'} type={'ghost'} onClick={() => handleClose()}/>
                </div>
                <HorizontalDivider/>
                <div className="p-5 flex flex-col gap-8">
                  <div className="">
                    <input
                      ref={inputRef}
                      className="w-full input-clear text-heading-4 medium placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 text-[var(--text-default)]"
                      placeholder="Project name"
                      disabled={loading}
                      value={formData.name}
                      onChange={handleInputChange('name')}
                    />
                    <ValidationErrorMessages name='name' errors={formErrors}/>

                    <div className="CKEditor">
                      <CKEditor
                        disabled={loading}
                        editor={BalloonEditor as any}
                        data={formData.description}
                        onChange={(event, editor) => handleInputChange('description')(editor.getData())}
                        config={{ placeholder: 'Description' }}
                      />
                      <ValidationErrorMessages name='description' errors={formErrors}/>
                    </div>
                  </div>
                  {
                    workspaceSlug && (
                      <EditProjectDetails
                        // user={projectLead}
                        workspaceSlug={workspaceSlug}
                        value={formData}
                        onChange={handleInputChange}
                        readOnly={false}
                      />
                    )
                  }
                </div>
                <HorizontalDivider/>
                <div className='p-5'>
                  <div className="flex justify-end items-center">
                    <div className="items-center flex gap-3 justify-end">
                      <Button
                        type="button"
                        onClick={() => handleClose()}
                        loading={loading}
                        disabled={loading}
                        value={'Cancel'}
                        size={'medium'}
                        btnType={'neutral'}
                        className='w-auto'
                      />
                      <Button
                        type="button"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={loading}
                        value={button}
                        size={'medium'}
                        btnType={'primary'}
                        className='w-auto'
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
