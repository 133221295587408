import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMe } from 'src/api/auth/useMe';

function HomePage() {
  const navigate = useNavigate();
  const { data } = useMe();
  // console.log(data)

  useEffect(() => {
    if (!data) return; // Exit early if data is not yet available

    const userDetails = data.me?.detail;
    const suggestedWorkspaces = data.me?.suggestedWorkspaces;
    const userWorkspaces = data.me?.userWorkspaces;

    if (!userDetails || !userDetails.fullName || !userDetails.position) {
      navigate('/profile-setup');
    } else if (userDetails.resettingPassword) {
      navigate('/new-password');
    } else if (!suggestedWorkspaces?.length && !userWorkspaces?.length) {
      navigate('/create-workspace');
    } else
      if (userWorkspaces?.length) {
        // Assuming you want to navigate to the first workspace's slug
        const firstWorkspace = userWorkspaces[0]?.workspace;
        if (firstWorkspace) {
          navigate(`/workspace/${firstWorkspace.slug}/project`);
        } else {
          navigate('/join-workspace');
        }
      } else {
        navigate('/join-workspace');
      }
  }, [data, navigate]);

  return null;
}

export default HomePage;
