import clsx from "clsx";
import '../ui.css'

export default function Button({
  children,
  value,
  size,
  type,
  btnType,
  icon,
  className,
  disabled,
  onClick
}: {
  children?: React.ReactNode;
  value: number | string;
  size: 'large' | 'medium' | 'small';
  btnType: 'primary' | 'neutral' | 'ghost' | 'destructive';
  type?: "submit" | "reset" | "button" | undefined;
  icon?: 'right' | 'left';
  loading?: boolean;
  className?: any;
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <button disabled={disabled} type={type} className={clsx(`${size} ${btnType} icon-${icon} ${className}`, {
      'text-body-large bold': size === 'large',
      'text-body-small': size === 'medium',
      'text-body-micro': size === 'small',
      'bg-primary-500': btnType === 'primary'
    })}
    onClick={onClick}>
      <span>{value}</span>
      {children}
    </button>
  )
}