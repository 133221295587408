import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useLogout } from "src/hooks/useLogout";
import { IUser, IUserWorkspace } from "src/lib/types";
import HorizontalDivider from "./HorizontalDivider";
import Media from "../Media";
import { Check } from "src/constants/icons";
import { DropItem } from "./DropGroup";
import { AnimatePresence, motion } from "framer-motion";

interface WorkspaceMenuProps {
  workspaceId?: String;
  userWorkspace: IUserWorkspace;
  userWorkspaces: IUserWorkspace[];
  user: IUser;
}

function MobileWorkSpaceMenu(props: WorkspaceMenuProps) {
  const logout = useLogout();
  const { userWorkspace, userWorkspaces, user } = props;
  const { workspace } = userWorkspace;
  const workspaces = userWorkspaces.map(({ workspace }) => workspace);

  const [active, setActive] = useState(false);

  const { workspaceSlug } = useParams();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setActive(false);
  };

  return (
    <>
      <div onClick={() => setActive(!active)}>
        <Media
          media={workspace.logo}
          width={32}
          height={32}
          alt={workspace.name}
          fontSize={16}
        />
      </div>
      <AnimatePresence>
        {active && (
          <>
            <motion.div
              ref={modalRef}
              initial={{ y: 200 }}
              animate={{ y: 0 }}
              exit={{ y: 200, opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
                duration: 0.2,
              }}
              className="fixed z-50 bottom-4 w-full pr-8"
            >
              <ul className="w-full px-2 py-4 flex flex-col items-start gap-2 rounded-2xl border border-[var(--border-on-bg)] bg-[var(--backgrounds-dropdowns)] shadow-[0px_4px_8px_0px_rgba(89,_89,_89,_0.10)]">
                {/* <div className="p-2 space-y-6"> */}
                <div className="flex flex-col gap-2 items-start w-full">
                  <div
                    className="px-3 text-body-micro bold text-[var(--text-neutral)] truncate"
                    title={user.email}
                  >
                    {user.email}
                  </div>

                  <div className="flex flex-col gap-1 items-start w-full">
                    {workspaces.map((each) => (
                      <li key={each.id} className="w-full">
                        <Link
                          to={`/workspace/${each.slug}/project`}
                          onClick={handleLinkClick}
                          className={`${
                            false ? "bg-[var(--backgrounds-hover-clicked)]" : ""
                          } rounded cursor-pointer flex items-center gap-2.5 px-2 h-10 w-full`}
                        >
                          <Media
                            media={each.logo}
                            width={32}
                            height={32}
                            alt={each.name}
                            fontSize={16}
                          />
                          <div className="flex-1 truncate text-left capitalize">
                            <div className="text-body-small medium text-[var(--text-neutral)]">
                              {each.name}
                            </div>
                          </div>
                          {workspace.id === each.id && <Check />}
                        </Link>
                      </li>
                    ))}
                  </div>
                </div>

                <HorizontalDivider />

                <div className="space-y-0.5 w-full">
                  <li>
                    <Link
                      to={`/workspace/${workspaceSlug}/settings/general`}
                      onClick={handleLinkClick}
                    >
                      <DropItem item={{ name: "Settings" }} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/invite-to-workspace?${new URLSearchParams({
                        workspace: userWorkspace.workspace.slug,
                      }).toString()}`}
                      onClick={handleLinkClick}
                    >
                      <DropItem item={{ name: "Invite new members" }} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/create-workspace" onClick={handleLinkClick}>
                      <DropItem item={{ name: "Create new workspace" }} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/join-workspace" onClick={handleLinkClick}>
                      <DropItem item={{ name: "Add workspace" }} />
                    </Link>
                  </li>
                </div>

                <HorizontalDivider />

                <div className="space-y-0.5 w-full">
                  <li>
                    <Link to="" onClick={handleLinkClick}>
                      <DropItem item={{ name: "Help & support" }} />
                    </Link>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        logout();
                        handleLinkClick();
                      }}
                    >
                      <DropItem item={{ name: "Log out" }} />
                    </div>
                  </li>
                </div>
                {/* </div> */}
              </ul>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="overlay opacity-50 bg-[var(--backgrounds-blanket)]"
            ></motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

export default MobileWorkSpaceMenu;
