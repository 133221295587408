import {useQuery} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {PROJECT_GOALS} from "src/lib/queries";

export function useProjectGoals(args: object) {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': accessToken ? `Bearer ${accessToken}` : undefined,
      },
    },
  } : {};

  return useQuery(PROJECT_GOALS, {...options, ...args});
}
