import 'src/components/sideNav/sidenav.css';
import Badge from '../UI/Badge';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

interface SideNavMenuProps {
  type: 'home' | 'settings';
  link?: string;
  sideAvatar?: string;
  sideAvatarClassName?: string;
  avatarColor?: React.CSSProperties;
  label: string;
  icon?: React.ReactNode;
  showBadge?: boolean;
  badge?: string | number;
  parentClassName?: string;
  setToggleNavChiild?: () => void;
}

const SideNavMenuBtn: React.FC<SideNavMenuProps> = ({
  link,
  type,
  sideAvatar,
  sideAvatarClassName,
  avatarColor,
  parentClassName,
  label,
  icon,
  badge,
  showBadge,
  setToggleNavChiild
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <div className="flex items-center justify-between w-full">
        <div className={clsx('side__menu__btn justify-between', parentClassName,
          {
            'gap-2': type === 'home',
            'active': pathname.includes(link || ''),
            'exact-active': pathname === link,
          })}
          onClick={setToggleNavChiild}
        >
          <div className="icon_label truncate">
            {icon}
            {sideAvatar && sideAvatar.length > 0 ? (
              <div>
                <div
                  className={clsx(
                    'flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold',
                    sideAvatarClassName // Merge custom className with default classes
                  )}
                  style={avatarColor}
                >
                  {sideAvatar[0]}
                </div>
              </div>
            ) : null}
            <div className='flex items-center truncate gap-2'>
              <span className='label text-body-small medium truncate'>{label}</span>
            </div>
          </div>
          <div>
            {showBadge && <span className="notification">
              <Badge value={badge} />
            </span>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNavMenuBtn;
