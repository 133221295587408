import clsx from "clsx";
import React from "react";

export default function IconButton({
    open,
    icon,
    size,
    type,
    className,
    disabled = false,
    onClick,
}: {
    open?: boolean;
    icon: React.ReactNode;
    size: 'micro' | 'small' | 'standard' | 'large';
    type: 'ghost' | 'neutral' | 'primary';
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
}) {
    return (
        <div
            className={clsx(
                "icon_button rounded-lg flex items-center justify-center aspect-square transition-all duration-200",
                size,
                type,
                className,
                {
                    'open': open,
                    'disabled cursor-not-allowed': disabled,
                    'cursor-pointer': !disabled,
                }
            )}
            onClick={!disabled ? onClick : undefined}
        >
            <div className="transition-all duration-200">
                {icon}
            </div>
        </div>
    );
}
