import React, { useState } from 'react';

interface Props {
    id: string;
    isChecked: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
    className?: string;
    stateless?: boolean;
}

const SwitchToggle: React.FC<Props> = ({ id, disabled, isChecked, onChange, label = 'Toggle', className = '', stateless = false }) => {
    const [checked, setChecked] = useState(isChecked);

    const handleChange = () => {
        if (stateless) {
            onChange(!isChecked);
        } else {
            const newChecked = !checked;
            setChecked(newChecked);
            onChange(newChecked);
        }
    };

    return (
        <div className={className}>
            <input
                className='toggleswitch flex items-center'
                type="checkbox"
                id={id}
                checked={stateless ? isChecked : checked}
                disabled={disabled}
                onChange={handleChange}
            />
            {label && <label className='toggleLabel' htmlFor={id}>{label}</label>}
        </div>
    );
};

export default SwitchToggle;
