import React, { FormEvent, useState } from 'react';
import { SIGN_IN } from "src/lib/mutations";
import { useMutation } from "@apollo/client";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
import SocialAuthButtonGroup from "../../components/SocialAuthButtonGroup";
import { Eye, EyeSlash, LogoNeutral } from 'src/constants/icons';
import Input from 'src/components/UI/Inputs/Default';
import Button from 'src/components/UI/Button/Button';
import { useToast } from 'src/context/ToastContext';
import { validateEmail, validatePassword } from 'src/utils/Index';
import { useNavigate } from 'react-router-dom';
import LogoPurple from 'src/components/Logo/LogoPurple';
import PageLoader from 'src/components/UI/Loader/PageLoader';
import toast from 'react-hot-toast';
import ToastMessage from 'src/components/ToastMessage';

type FormData = {
  email: string;
  password: string;
}

function LoginPage() {
  // Local storage definition
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", undefined);
  const [userId, setUserId] = useLocalStorage("userId", undefined);

  // Usestate definitions
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [passwordType, setPasswordType] = useState(true)
  // const [formData, setFormData] = useState({
  //   email: '',
  //   password: '',
  // });

  const navigate = useNavigate();
  const { showToast, showSuccessToast, showErrorToast } = useToast();

  // graphQL definitions
  const [signIn, { data, loading, error, client }] = useMutation(SIGN_IN);

  // function definitions

  function validateErrors(message: string) {
    if (message.includes('Invalid') && message.includes('password')) {
      return message + '. Please double check and try again.';
    }
    else if (message.includes('approved') || message.includes('access') || message.includes('early') || message.includes('domain')) {
      setTimeout(() => {
        window.location.href = 'https://overlap.work/join-waitlist'
      }, 3000);
      return message + '. You will be redirected to the waitlist page in 3 seconds';
    }
    return message;
  }

  function handleValidation() {
    // Reset error messages for a clean slate
    setEmailError('');
    setPasswordError('');

    // Validation checks
    let isValid = validateEmail(email);

    if (!isValid) {
      if (!validateEmail(email)) {
        setEmailError('Invalid email format.');
      }
      // if (!validatePassword(password)) {
      //   // setPasswordError('Weak password. Please use a combination of uppercase, lowercase letters, numbers, and be at least 8 characters long.'); // Customizable message
      //   setPasswordError('Password must be at least 4 characters.');
      // }
    }

    return isValid;
  }

  function handleContinue(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (handleValidation()) {
      // setFormData({
      //   email: email,
      //   password: password
      // });
      signIn({
        variables: { data: { email, password } },
        onCompleted: ({ signIn }) => {
          if (signIn.userId && signIn.accessToken) {
            setUserId(signIn.userId);
            setAccessToken(signIn.accessToken);
            navigate('/');
          }
        },
        onError: (error) => {
          toast(t => (
            <ToastMessage
              id={t.id}
              title={validateErrors(error.message)}
            >
            </ToastMessage>
          ));
        }
      });
    }
  }

  if (error) {
    showToast(`Error: ${error.message}`);
    // return null; // Or return an error state
  }

  return (
    <div className='w-full flex flex-col gap-6 items-center justify-center'>
      <div>
        <div className='flex items-center gap-1'>
          <LogoPurple className='w-[18.655px] h-auto' />
          <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
            overlap
          </h1>
        </div>
      </div>
      <div className='w-full flex flex-col px-4 py-8 items-center justify-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)] md:px-8'>
        <form
          onSubmit={handleContinue}
          className="w-full flex flex-col gap-10 justify-center">
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-center text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
              Welcome back to Overlap
            </h1>
            <p className="text-body-small block text-center medium text-[var(--text-neutral)] md:text-body-large">
              Please log in to continue
            </p>
          </div>

          <div className='flex flex-col gap-6'>
            <Input
              autoFocus
              type="email"
              value={email}
              feedback={emailError}
              feedbackType={emailError.length ? 'error' : 'success'}
              label="Email address"
              placeholder="Enter your email address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className='flex flex-col gap-3'>
              <div className='relative'>
                <Input
                  type={passwordType ? 'password' : 'text'}
                  password
                  value={password}
                  feedback={passwordError}
                  feedbackType={passwordError.length ? 'error' : 'success'}
                  label="Password"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  className='!pr-12'
                />
                <div className='absolute top-[41px] right-4' onClick={() => setPasswordType(!passwordType)}>
                  {passwordType ? <Eye className='w-4 h-4' /> : <EyeSlash className='w-4 h-4' />}
                </div>
              </div>
              <p className="text-body-small medium text-[var(--text-neutral)] text-right">
                Forgot password? <Link to="/auth/reset-password" className="!text-[var(--text-neutral)] underline">Reset it</Link>
              </p>
            </div>

            <Button
              type="submit"
              btnType="primary"
              size="large"
              value={'Log in'}
              loading={loading}
              disabled={loading}
              className="w-full"
            />
          </div>
        </form>
        <div className='flex flex-col gap-8 w-full'>
          <p className='text-body-small medium text-[var(--text-neutral)] text-center'>or</p>
          <SocialAuthButtonGroup login />
        </div>
        <p className="text-body-micro regular text-center text-[var(--text-neutral)] max-w-[336px]">
          By using Overlap, you agree to our <span className='text-[var(--text-neutral)] medium'>Terms of Service</span> and <span className='text-[var(--text-neutral)] medium'>Privacy Policy.</span>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
