import React from 'react';
import { AvatarColorPreset, colorStyles } from 'src/lib/ColorPreset';
import { getColorTag, getInitials } from 'src/lib/helpers';

interface MediaProps {
  media?: {
    filename: string;
    mimetype: string;
    url: string;
  };
  alt: string;
  width: number;
  height: number;
  fontSize: number;
}

const Media: React.FC<MediaProps> = ({ media, alt, width, height, fontSize }) => {
  const getColorPreset = (alt: string) => {
    if (alt.length < AvatarColorPreset.length - 1) {
      return AvatarColorPreset[alt.length];
    } else {
      const presetIndex = alt.length % AvatarColorPreset.length;
      return AvatarColorPreset[presetIndex];
    }
  };

  if (media) {
    return (
      <img
        src={`${process.env.REACT_APP_API_URL}/${media.url}`}
        alt={alt}
        style={{ height: `${height}px`, width: `${width}px` }}
        className="aspect-square border border-[var(--border-on-bg)] rounded object-cover"
      />
    );
  }

  const colorPreset = getColorPreset(alt);

  return (
    <div
      className={`rounded grid uppercase place-content-center font-bold ${colorPreset}`}
      style={{ width: `${width}px`, height: `${height}px`, fontSize: `${fontSize}px`,  ...colorStyles[getColorTag(alt)] }}
    >
      {getInitials(alt)}
    </div>
  );
};

export default Media;
