import React from 'react';
import { Routes, Route } from 'react-router';
import HomePage from "./HomePage";
import ProtectedRoute from "src/components/ProtectedRoute";
import AuthRoute from "src/components/AuthRoute";
import LoginPage from "src/pages/auth/LoginPage";
import WorkspaceLayout from "src/layouts/WorkspaceLayout";
import SettingsLayout from 'src/layouts/SettingsLayout';
import WorkspacePage from "./WorkspacePage";
import JoinWorkspacePage from "./JoinWorkspacePage";
import ProjectPage from "./ProjectPage";
import RegisterPage from "./auth/RegisterPage";
import ResetPassword from "./auth/ResetPassword";
import AcceptInvitePage from "./auth/AcceptInvitePage";
import AuthCallbackPage from "./auth/AuthCallbackPage";
import VerifyOtpPage from "./auth/VerifyOtpPage";
import CreateWorkspacePage from "./CreateWorkspacePage";
import InviteToWorkspacePage from "./InviteToWorkspacePage";
import NewPasswordPage from "./NewPasswordPage";
import ProfileSetupPage from "./ProfileSetupPage";
import General from './settings/workspace/General';
import Security from './settings/workspace/Security';
import Notification from './settings/workspace/Notification';
import Members from './settings/workspace/Members';
import ProfilePage from './settings/workspace/Profile';
import AuthLayout from 'src/layouts/AuthLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import CommentPage from './CommentPage';
import Preference from './settings/workspace/Preference';
import Mention from './Mention';
import Test from './Test';
import ChatHomePage from './teams/chats/ChatHomePage';
import InboxPage from './InboxPage';
import ComingSoon from './ComingSoon';
import MobileHome from './MobileHome';

function PageRoutes() {
  const suggestions = ['john_doe', 'jane_smith', 'alex_jones'];
  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute />}>
        <Route index element={<HomePage />} />
        <Route element={<AuthLayout />}>
          <Route path='join-workspace' element={<JoinWorkspacePage />} />
          <Route path='create-workspace' element={<CreateWorkspacePage />} />
          <Route path='invite-to-workspace' element={<InviteToWorkspacePage />} />
          <Route path='new-password' element={<NewPasswordPage />} />
          <Route path='profile-setup' element={<ProfileSetupPage />} />
        </Route>
        <Route path='workspace/:workspaceSlug/project' element={<DashboardLayout />}>
          <Route index element={<WorkspacePage />} />
          <Route path='all' element={<MobileHome />} />
          <Route path=':projectId' element={<ProjectPage />} />
          <Route path=':projectId/c/:postId' element={<CommentPage />} />
          <Route path=':userId/chats' element={<ChatHomePage />} />
          <Route path=':projectId/test' element={<Test />} />
        </Route>
        <Route path='workspace/:workspaceSlug' element={<DashboardLayout />}>
          <Route path='inbox' element={<InboxPage />} />
          <Route path=':userId/chats' element={<ChatHomePage />} />
        </Route>
        <Route path='workspace/:workspaceSlug/settings' element={<SettingsLayout />}>
          <Route path='general' element={<General />} />
          <Route path='members' element={<Members />} />
          <Route path='profile' element={<ProfilePage />} />
          <Route path='security' element={<Security />} />
          <Route path='billing' element={<ComingSoon />} />
          <Route path='projects' element={<ComingSoon />} />
          <Route path='notifications' element={<Notification />} />
          <Route path='preference' element={<Preference />} />
          <Route path="what's-new" element={<ComingSoon />} />
          <Route path='mentions' element={<Mention suggestions={suggestions} />} />
        </Route>
      </Route>
      <Route path='/auth' element={<AuthLayout />}>
        <Route path='login' element={<LoginPage />} />
        <Route path='register' element={<RegisterPage />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='accept-invite' element={<AcceptInvitePage />} />
        <Route path='callback' element={<AuthCallbackPage />} />
        <Route path=':id/callback' element={<AuthCallbackPage />} />
        <Route path='verify-otp' element={<VerifyOtpPage />} />
      </Route>
    </Routes>
  );
}

export default PageRoutes;
