import { gql } from "@apollo/client";
import {INBOX_FIELDS, USER_DETAIL_FIELDS, WORKSPACE_FIELDS} from "./fragments";

export const ME = gql`
    query Me {
        me {
            id
            email
            detail {
                ...UserDetailFields
                resettingPassword
            }
            notificationSettings {
                addedToProject
                deactivatedWorkspace
                deletedProject
                deletedProjectPermanently
                enableEmailNotification
                enablePushNotification
                newCommentInMentionedPost
                newPostCommentReplies
                newPostOrCommentMention
                newProjectPost
                updatedProjectStatus
            }
            suggestedWorkspaces {
                id
                name
                logo {
                    url
                }
                members {
                    id
                    userId
                }
                slug
                invitations {
                    id
                    inviteeEmail
                }
            }
            userWorkspaces {
                id
                isOwner
                workspace {
                    id
                    logo {
                        url
                    }
                    name
                    projects {
                        id
                        name
                    }
                    members {
                        id
                        userId
                    }
                    slug
                }
            }
            invitations {
                workspaceId
                accepted
            }
        }
    }
    ${USER_DETAIL_FIELDS}
`;

export const POSITION_ENUM = gql`
    query PositionEnum {
        __type(name: "Position") {
            name
            kind
            enumValues {
                name
                description
            }
        }
    }
`;

export const WORKSPACES = gql`
    query Workspaces {
        workspaces {
            id
            name
            slug
            projects {
                id
                name
            }
#            members {
#                id
#                userId
#            }
        }
    }
`;

export const WORKSPACE = gql`
    query Workspace($where: WorkspaceWhereUniqueInput!) {
        workspace(where: $where) {
            ...WorkspaceFields
        }
    }
    ${WORKSPACE_FIELDS}
`;

export const USER_WORKSPACE = gql`
    query UserWorkspace($where: UserWorkspaceWhereUniqueInput!) {
        userWorkspace(where: $where) {
            id
            user {
                detail {
                    ...UserDetailFields
                }
            }
            workspace {
                id
                logo {
                    filename
                    mimetype
                    url
                }
                name
            }
        }
    }
    ${USER_DETAIL_FIELDS}
`;

export const WORKSPACE_INVITATION = gql`
    query WorkspaceInvitation($where: WorkspaceInvitationWhereUniqueInput!) {
        workspaceInvitation(where: $where) {
            accepted
            inviteeEmail
            inviter {
                detail {
                    ...UserDetailFields
                }
            }
            workspace {
                name
                logo {
                    filename
                    mimetype
                    url
                }
            }
        }
    }
    ${USER_DETAIL_FIELDS}
`;

export const PROJECT_TOPICS = gql`
    query ProjectTopics($where: ProjectTopicWhereInput) {
        projectTopics(where: $where) {
            id
            name
        }
    }
`;

export const PROJECT_GOALS = gql`
    query ProjectGoals($where: ProjectGoalWhereInput) {
        projectGoals(where: $where) {
            id
            name
        }
    }
`;

export const PROJECT = gql`
    query Project($where: ProjectWhereUniqueInput!) {
        project(where: $where) {
            id
            name
            description
            owner {
                id
                detail {
                    ...UserDetailFields
                }
            }
            createdAt
            collaboratorIDs
            collaborators {
                id
                detail {
                    ...UserDetailFields
                }
            }
            topicIDs
            goalIDs
            status
            isPublic
            workspace {
                id
                slug
            }
            links {
                label
                url
            }
        }
    }
    ${USER_DETAIL_FIELDS}
`;

export const POSTS = gql`
    query Posts($orderBy: [PostOrderByWithRelationInput!], $where: PostWhereInput) {
        posts(orderBy: $orderBy, where: $where) {
            id
            title
            comment
            type
            topic {
                id
                name
            }
            createdAt
            attachments {
                filename
                mimetype
                url
            }
            author {
                id
                detail {
                    ...UserDetailFields
                }
            }
            comments {
                id
                author {
                    id
                    detail {
                        ...UserDetailFields
                    }
                }
                comment
                createdAt
                responses {
                    id
                    author {
                        id
                        detail {
                            ...UserDetailFields
                        }
                    }
                    comment
                    createdAt
                    post {
                        id
                        title
                        author {
                            detail {
                                ...UserDetailFields
                            }
                        }
                        project {
                            id
                            workspace {
                                slug
                            }
                        }
                    }
                }
                post {
                    id
                    title
                    author {
                        detail {
                            ...UserDetailFields
                        }
                    }
                    project {
                        id
                        workspace {
                            slug
                        }
                    }
                }
            }
            mentionedUserIDs
            project {
                id
                workspace {
                    slug
                }
            }
        }
    }
    ${USER_DETAIL_FIELDS}
`;

export const INBOX = gql`
    query Inbox($orderBy: [InboxOrderByWithRelationInput!]) {
        inbox(orderBy: $orderBy) {
            ...InboxFields
        }
    }
    ${INBOX_FIELDS}
`;
