import {useMutation} from "@apollo/client";
import {useLocalStorage} from "@uidotdev/usehooks";
import {READ_ONE_INBOX} from "src/lib/mutations";

export function useReadOneInbox() {
  const [accessToken] = useLocalStorage("accessToken", undefined);

  const options = accessToken ? {
    context: {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Apollo-Require-Preflight': 'true',
      },
    }
  } : {}

  return useMutation(READ_ONE_INBOX, options);
}
