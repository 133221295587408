import React from 'react';
import { formatDate, getColorTag } from 'src/lib/helpers';
import { colorStyles } from 'src/lib/ColorPreset';
import {
  AddedToProject, CommentInMentionedPost,
  DeletedProject,
  IInbox,
  InboxType, MentionedInComment, MentionedInPost, NewPostCommentReplies,
  NewProjectPost,
  UpdatedProjectStatus
} from "../../../lib/types";
import Avatar from "../../Avatar";
import { useReadOneInbox } from "../../../api/inbox/useReadOneInbox";

export default function InboxComment({ inbox }: { inbox: IInbox }) {
  const [readOneInbox, { loading }] = useReadOneInbox();

  const renderItem = () => {
    switch (inbox.type) {
      case InboxType.ADDED_TO_PROJECT:
      case InboxType.UPDATED_PROJECT_STATUS:
      case InboxType.DELETED_PROJECT:
      case InboxType.DELETED_PROJECT_PERMANENTLY: {
        const context = inbox.context as AddedToProject | UpdatedProjectStatus | DeletedProject;
        return (
          <div
            className="p-2 flex items-start gap-2 bg-[var(--backgrounds-default)] rounded-lg border border-[var(--border-divider)]">
            <div className="">
              <div
                className={`w-5 h-5 aspect-square flex flex-col items-center justify-center rounded text-body-micro bold`}
                style={colorStyles[getColorTag(context.projectName)]}
              >
                {context.projectName[0]}
              </div>
            </div>
            <div>
              <p className="text-body-small medium text-[var(--text-neutral)] group-hover:text-[var(--text-neutral)]">
                {context.projectName}
              </p>
              <p className="ck ck-content text-body-micro regular text-[var(--text-neutral)]"
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                }}
                dangerouslySetInnerHTML={{ __html: context.projectDescription }}
              />
            </div>
          </div>
        );
      }

      case InboxType.POSTED_ON_PROJECT: {
        const context = inbox.context as NewProjectPost;
        return (
          <>
            <p className="text-body-micro medium text-[--text-on-bg] truncate">
              {context.postTitle}
            </p>
            <p
              className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
              }}
              dangerouslySetInnerHTML={{ __html: context.postBody }}
            />
          </>
        )
      }

      case InboxType.REPLIED_TO_COMMENT:
      case InboxType.COMMENTED_ON_POST: {
        const context = inbox.context as NewPostCommentReplies;
        return (
          <p
            className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
            }}
            dangerouslySetInnerHTML={{ __html: context.commentReply }}
          />
        )
      }

      case InboxType.MENTIONED_IN_POST: {
        const context = inbox.context as MentionedInPost;
        return (
          <>
            <p className="text-body-micro medium text-[--text-on-bg] truncate">
              {context.postTitle}
            </p>
            <p
              className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
              }}
              dangerouslySetInnerHTML={{ __html: context.postComment }}
            />
          </>
        )
      }

      case InboxType.COMMENTED_ON_MENTIONED_POST: {
        const context = inbox.context as CommentInMentionedPost;
        return (
          <>
            <p className="text-body-micro medium text-[--text-on-bg] truncate">
              {context.postTitle}
            </p>
            <p
              className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
              }}
              dangerouslySetInnerHTML={{ __html: context.comment }}
            />
          </>
        )
      }

      case InboxType.MENTIONED_IN_COMMENT: {
        const context = inbox.context as MentionedInComment;
        return (
          <p
            className="ck ck-content text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
            }}
            dangerouslySetInnerHTML={{ __html: context.comment }}
          />
        )
      }

      default:
        return null;
    }

    return (
      <>
        <p className="text-body-micro medium text-[--text-on-bg] truncate">
          Initial exploration
        </p>
        <p className="text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-on-bg)]"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
          }}
        >
          We’re crafting a website to showcase projects we’ve done in the past year as well as client feedback our
          approach and information on how to get started working with us.
        </p>
        <div
          className="p-2 flex items-start gap-2 bg-[var(--backgrounds-default)] rounded-lg border border-[var(--border-divider)]">
          <div className="">
            <div
              className={`w-5 h-5 aspect-square flex flex-col items-center justify-center rounded text-body-micro bold`}
              style={colorStyles[getColorTag('bbb')]}>A
            </div>
          </div>
          <div>
            <p
              className="text-body-small medium text-[var(--text-neutral)] group-hover:text-[var(--text-neutral)]">
              Studio Website
            </p>
            <p className="text-body-micro regular text-[var(--text-neutral)]"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
              }}
            >
              We’re crafting a website to showcase projects we’ve done in the past year as well as client feedback our
              approach and information on how to get started working with us.
            </p>
          </div>
        </div>
      </>
    )
  }

  const handleClick = () => {
    if (loading || inbox.isRead) return;
    readOneInbox({
      variables: {
        where: { id: inbox.id }
      }
    });
  }

  return (
    <div
      onClick={handleClick}
      className="py-4 px-5 flex gap-2 items-start w-full group hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer"
    >
      <div>
        <Avatar
          avatar={inbox.sender.detail.avatar}
          alt={inbox.sender.detail.fullName}
          size={20}
        />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="flex items-center gap-1">
          <div className="flex-1">
            <p className="truncate max-w-xs text-body-small medium text-[--text-neutral] group-hover:text-[var(--text-default)]">
              {inbox.sender.detail.fullName} {inboxTypeForHumans(inbox.type)}
            </p>
          </div>
          <p className="text-body-micro regular text-[--text-neutral] group-hover:text-[var(--text-default)]">
            {formatDate(inbox.createdAt)}
          </p>
          {inbox.isRead || <PurpleDot loading={loading} />}
        </div>
        <div className="whitespace-break-spaces">
          {renderItem()}
        </div>
      </div>
    </div>
  )
}

function PurpleDot({ loading }: { loading: boolean }) {
  return (
    <div className={loading ? 'animate-pulse' : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="11" viewBox="0 0 19 11" fill="none">
        <path
          d="M7 5.5C7 3.84315 8.34315 2.5 10 2.5C11.6569 2.5 13 3.84315 13 5.5C13 7.15685 11.6569 8.5 10 8.5C8.34315 8.5 7 7.15685 7 5.5Z"
          fill="#8178EF" />
      </svg>
    </div>
  )
}

/**
 *
 * @param inboxType
 */
function inboxTypeForHumans(inboxType: InboxType): string {
  switch (inboxType) {
    case InboxType.ADDED_TO_PROJECT:
      return "added you to a project";

    case InboxType.UPDATED_PROJECT_STATUS:
      return "updated a project status";

    case InboxType.DELETED_PROJECT:
      return "deleted a project";

    case InboxType.DELETED_PROJECT_PERMANENTLY:
      return "deleted a project permanently";

    case InboxType.MENTIONED_IN_COMMENT:
      return "mentioned you in a comment";

    case InboxType.MENTIONED_IN_POST:
      return "mentioned you in a post";

    case InboxType.COMMENTED_ON_POST:
      return "commented on your post";

    case InboxType.COMMENTED_ON_MENTIONED_POST:
      return "commented on a post you're mentioned in";

    case InboxType.REPLIED_TO_COMMENT:
      return "replied to your comment";

    case InboxType.POSTED_ON_PROJECT:
      return "posted on your project";

    default:
      return inboxType;
  }
}
