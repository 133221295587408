// Spinner.tsx
import React from 'react';
import './spinner.css'

interface SpinnerProps {
    spinnnerSize?: 'medium' | 'large';
}

const Spinner: React.FC<SpinnerProps> = ({ spinnnerSize = 'medium' }) => {
    const containerClass = spinnnerSize === 'large' ? 'spin_container-large' : 'spin_container-medium';
    return (
        <div className={`${containerClass}`}>
            <div className={`ispinner ${spinnnerSize === 'large' ? 'ispinner-large' : ''}`}>
                {Array.from({ length: 8 }).map((_, i) => (
                    <div key={i} className={'ispinner_blade'}></div>
                ))}
            </div>
        </div>
    );
};

export default Spinner;
