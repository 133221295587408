import './ui.css'

interface HorizontalDividerProps {
    size?: 'large';
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
    size
}) => {
    return (
        <div className={`hr ${size === 'large' ? 'large' : 'small'}`}></div>
    );
};

export default HorizontalDivider;