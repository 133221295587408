// Mention.tsx
import React, { useState } from 'react';

type MentionProps = {
    suggestions: string[];
};

const Mention: React.FC<MentionProps> = ({ suggestions }) => {
    const [inputValue, setInputValue] = useState('');
    const [mentionSuggestions, setMentionSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value, selectionStart } = e.target;
        setInputValue(value);

        const lastChar = value.charAt(selectionStart! - 1);
        if (lastChar === '@') {
            setShowSuggestions(true);
            setMentionSuggestions(suggestions);
        } else if (lastChar === ' ' || lastChar === '\n') {
            setShowSuggestions(false);
        }
        setCursorPosition(selectionStart!);
    };

    const handleSuggestionClick = (suggestion: string) => {
        const textBeforeCursor = inputValue.substring(0, cursorPosition - 1);
        const textAfterCursor = inputValue.substring(cursorPosition);
        const newText = `${textBeforeCursor}@${suggestion} ${textAfterCursor}`;
        setInputValue(newText);
        setShowSuggestions(false);
    };

    return (
        <div style={{ position: 'relative', width: '300px' }}>
            <textarea
                value={inputValue}
                onChange={handleChange}
                rows={5}
                style={{ width: '100%' }}
            />
            {showSuggestions && (
                <ul style={{
                    position: 'absolute',
                    top: '50px',
                    left: '0',
                    width: '100%',
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    listStyle: 'none',
                    padding: '0',
                    margin: '0',
                    zIndex: 1000,
                }}>
                    {mentionSuggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{ padding: '5px', cursor: 'pointer' }}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Mention;
