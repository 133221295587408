// Textarea.tsx
import React, { ChangeEvent, KeyboardEvent, ForwardedRef, forwardRef } from 'react';

interface TextareaProps {
    onInput: (ev: ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown: (ev: KeyboardEvent<HTMLTextAreaElement>) => void;
}

const Textarea = forwardRef(
    ({ onInput, onKeyDown }: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => (
        <textarea
            ref={ref}
            className="w-full h-28 p-4"
            placeholder="@mention someone"
            onInput={onInput}
            onKeyDown={onKeyDown}
        />
    )
);

export default Textarea;
