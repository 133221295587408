import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMe } from 'src/api/auth/useMe';
import { IProject, IUserWorkspace } from 'src/lib/types';

interface ProjectContextProps {
    userData: any,
    projects: IProject[];
    setProjects: React.Dispatch<React.SetStateAction<IProject[]>>;
    removeProject: (projectId: string) => void;
    restoreProject: (projectId: string) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export function useProjectContext() {
    const context = useContext(ProjectContext);

    if (!context) {
        throw new Error('useProjectContext must be used within a ProjectContextProvider');
    }
    return context;
}

interface ProjectContextProviderProps {
    children: ReactNode;
}

export function ProjectContextProvider({ children }: ProjectContextProviderProps) {
    const { workspaceSlug } = useParams();
    const navigate = useNavigate();
    const { data } = useMe();
    const userWorkspaces = data?.me?.userWorkspaces;
    const userWorkspace = userWorkspaces?.find(
        ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
    );

    const [userData, setUserData] = useState()
    const [projects, setProjects] = useState<IProject[]>([]);
    const [removedProjects, setRemovedProjects] = useState<{ project: IProject, index: number }[]>([]);

    useEffect(() => {
        if(data) {
            setUserData(data)
        }
    }, [data])

    useEffect(() => {
        if (userWorkspace) {
            setProjects(userWorkspace.workspace.projects || []);
        }
    }, [userWorkspace]);

    const removeProject = (projectId: string) => {
        setProjects(prevProjects => {
            const index = prevProjects.findIndex(project => project.id === projectId);
            if (index === -1) return prevProjects;

            const newRemovedProjects = [...removedProjects, { project: prevProjects[index], index }];
            setRemovedProjects(newRemovedProjects);

            const newProjects = prevProjects.filter(project => project.id !== projectId);

            if (newProjects.length > 0) {
                console.log(newProjects[0].id, 'newProjects')
                navigate(`/workspace/${workspaceSlug}/project/${newProjects[0].id}`, { replace: true });
            } else {
                navigate(`/workspace/${workspaceSlug}/project`, { replace: true });
            }

            return newProjects;
        });
    };

    const restoreProject = (projectId: string) => {
        setRemovedProjects(prevRemovedProjects => {
            const removedProjectData = prevRemovedProjects.find(({ project }) => project.id === projectId);
            if (!removedProjectData) return prevRemovedProjects;

            setProjects(prevProjects => {
                const newProjects = [...prevProjects];
                newProjects.splice(removedProjectData.index, 0, removedProjectData.project);
                return newProjects;
            });

            return prevRemovedProjects.filter(({ project }) => project.id !== projectId);
        });
    };

    return (
        <ProjectContext.Provider value={{ projects, setProjects, removeProject, restoreProject, userData }}>
            {children}
        </ProjectContext.Provider>
    );
}
