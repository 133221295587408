// Test.tsx
import React from 'react';
import Mentionify from 'src/components/Features/Inbox/Mentionify';

const users = [
    { username: 'john_doe' },
    { username: 'jane_doe' },
];

const resolveFn = async (prefix: string) => prefix === ''
    ? users
    : users.filter(user => user.username.startsWith(prefix));

const replaceFn = (user: { username: string }, trigger: string) => `${trigger}${user.username} `;

const Test: React.FC = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl mb-4">Mentionify Example</h1>
            <Mentionify
                users={users}
                resolveFn={resolveFn}
                replaceFn={replaceFn}
            />
        </div>
    );
};

export default Test;
