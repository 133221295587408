import React from 'react';
import Avatar from './Avatar';
import Badge from '../Badge';

interface NameAvatarProps {
    src?: string;
    alt?: string;
    name: string;
    position?: string;
    size?: number;
    fontSize?: number;
    loading?: boolean,
    role?: string,
}

const NameAvatar: React.FC<NameAvatarProps> = ({ src, loading=false, alt, name, position, role, size=28, fontSize=16 }) => {
    return (
        <div className='flex items-center gap-2'>
            <Avatar loading={loading} name={name} src={src} alt={alt} size={size} />
            <div className="flex flex-col items-start truncate">
                <span className="text-body-small medium text-[var(--text-default)] truncate max-w-60">{name}</span>
                <span className="text-body-micro regular text-[var(--text-neutral)] truncate max-w-72 capitalize">{position}</span>
            </div>
            {!!role && <Badge value={role} />}
        </div>
    );
};

export default NameAvatar;
