import '../settings.css'
import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import HeaderText from "src/components/UI/HeaderTexts";
import HorizontalDivider from "src/components/UI/HorizontalDivider";
import { useMe } from "src/api/auth/useMe";
import { useSingleUpload } from 'src/api/useSingleUpload';
import ProfilePicture from 'src/components/Features/Profile/ProfilePicture';
import { useUpdateOneUserDetail } from "src/api/user/useUpdateOneUserDetail";
import { useToast } from 'src/context/ToastContext';
import ProfileDetail from 'src/components/Features/Profile/ProfileDetail';
import ProfileSecurity from 'src/components/Features/Profile/ProfileSecurity';
import PageLoader from 'src/components/UI/Loader/PageLoader';



interface RoleData {
    name: string;
}



export default function ProfilePage() {
    // toast definition
    const { showSuccessToast, showErrorToast } = useToast();

    // GraphQL definitions
    const { loading, data: userData } = useMe();
    const [singleUpload] = useSingleUpload();
    const [updateOneUserDetail] = useUpdateOneUserDetail();

    // inputs states
    const [userName, setUserName] = useState<string>('');
    const [email, setEmail] = useState('');

    // const [userRole, setUserRole] = useState<RoleData>();
    const [userId, setUserId] = useState('');
    const [userRole, setUserRole] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [userDetails, setUserDetails] = useState<any>();



    // profile image states
    const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>();


    useEffect(() => {
        if (userData) {
            const { fullName, position, avatar } = userData.me.detail;
            setUserDetails(userData.me)

            setUserName(fullName);
            setUserId(userData.me.id)
            setUserRole(position)
            setEmail(userData.me?.email);
            setProfileImageUrl(avatar?.url);
        }
    }, [userData]);



    async function handleUpdateUserNameAndRole() {
        try {
            await updateOneUserDetail({
                variables: {
                    "data": {
                        "fullName": {
                            "set": userName
                        },
                        "position": {
                            "set": userRole
                        }
                    },
                    "where": {
                        "id": userId
                    }
                }
            })
            // console.log(userName, userRole?.value)
            showSuccessToast('Details updated successfully');
        } catch (error) {
            showErrorToast('Error updating details');
        }
    }


    if(loading) return <PageLoader snippet='Loading your profile...' />;
    // if (!userData) return <p>No data available</p>;


    return (
        <div className="workspace-page flex flex-col gap-10">
            <div className="header_div">
                <HeaderText title="My profile" />
                <HorizontalDivider />
            </div>
            <div className="flex flex-col gap-4 items-start">
                <HeaderText subTitle="Profile picture" snippet="Update your profile picture." />
                <ProfilePicture
                    name={userName}
                    userId={userDetails?.id}
                    profileImageUrl={profileImageUrl}
                    setProfileImageUrl={setProfileImageUrl}
                />
            </div>
            <HorizontalDivider />
            <div className="profile_details flex flex-col gap-6 items-start w-full">
                <HeaderText subTitle="Profile details" snippet="Update your profile name and role." />
                <ProfileDetail
                    name={userName}
                    setUserName={(arg) => setUserName(arg)}
                    userRole={userRole}
                    setUserRole={(arg) => { setUserRole(arg.value) }}
                    handleUpdateUserNameAndRole={handleUpdateUserNameAndRole}
                />
                {/* <SelectInput
                    value={name}
                    style={{ maxHeight: '210px' }}
                    feedback=''
                    data={roleData}
                    label="What’s your role?"
                    placeholder="Enter your role"
                    onChange={handleNameChange}
                    onClick={DropdownChange}
                    closeBg={handleProfileImageChange} /> */}

            </div>
            <ProfileSecurity
                userEmail={email}
                userId={userDetails?.id}
                userDetails={userDetails?.userWorkspaces}
            />
        </div>
    )
}


