interface LogoNeutralProps {
    className?: string;
}

export default function LogoNeutral({ className }: LogoNeutralProps) {
    return (
        <svg className={className} width="300" height="316" viewBox="0 0 300 316" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M179.282 298.289C195.333 288.528 195.23 256.69 179.052 227.176C162.875 197.663 136.749 181.65 120.698 191.41C104.648 201.171 104.75 233.009 120.928 262.523C137.105 292.036 163.231 308.049 179.282 298.289Z" fill="var(--icon-primary)" />
            <path d="M84.2931 188.605C112.313 171.565 127.516 144.046 118.249 127.14C108.982 110.234 78.7552 110.343 50.735 127.382C22.7148 144.422 7.51224 171.94 16.7791 188.846C26.0459 205.753 56.273 205.644 84.2931 188.605Z" fill="var(--icon-primary)" />
            <path d="M179.292 124.552C195.343 114.791 195.24 82.9531 179.063 53.4395C162.885 23.9259 136.759 7.91311 120.709 17.6738C104.658 27.4345 104.761 59.2726 120.938 88.7862C137.116 118.3 163.242 134.313 179.292 124.552Z" fill="var(--icon-primary)" />
            <path d="M249.265 188.605C277.285 171.565 292.488 144.046 283.221 127.14C273.954 110.234 243.727 110.343 215.707 127.382C187.687 144.422 172.484 171.94 181.751 188.846C191.018 205.753 221.245 205.644 249.265 188.605Z" fill="var(--icon-primary)" />
        </svg>
    )
}